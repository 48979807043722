interface EditorialLink {
    link:string;
}
  
export interface StoreOption {
    title:string;
    overviewColumns:OverviewColumn[];
    editorialLinks:EditorialLink[];
}

interface OverviewSubColumn {
    title:string;
    list:{des:string, status?:string}[];
}

interface OverviewColumn {
    column:number;
    subColumns:OverviewSubColumn[];
}

const pixelOverviewColumns:OverviewColumn[] = [
    {column:1, subColumns:[{title:'Phones', list:[{des:'Pixel 5'}, {des:'Pixel 4a (5G)'}, {des:'Pixel 4a Phones'}, {des:'Compare phones'}]}]},
    {column:2, subColumns:[{title:'Wireless Earbuds', list:[{des:'Pixel Buds-A-Series', status:'new'}, {des:'Pixel Buds'}, {des:'Compare Pixel Buds'}]}, 
                        {title:'Wireless Charger', list:[{des:'Pixel Stand'}]},
                        {title:'Cases', list:[{des:'Pixel 5 Case'}, {des:'Pixel 4a (5G) Case'}, {des:'Pixel 4a Case'}]}]},
    {column:3, subColumns:[{title:'Accessories Categories', list:[{des:'Cases & protection'}, {des:'Headphones'}, {des:'Power cables & adapters'}, {des:'Browse all'}]}]}
];
const nestOverviewColumns:OverviewColumn[] = [
{column:1, subColumns:[{title:'Solutions At Home', list:[{des:'Audio Entertainment'}, {des:'Video Entertainment'}, {des:'Home Security'}, {des:'Compare phones'}]},
                        {title:'Speakers', list:[{des:'Nest Audio'}, {des:'Nest Mini (2nd gen)'}, {des:'Google Home Mini'}, {des:'Compare speakers'}]},
                        {title:'Displays', list:[{des:'Nest Hub Max'}, {des:'Nest Hub (2nd gen)'}, {des:'Compare displays'}]},
                        {title:'Streaming', list:[{des:'Chromecast'}, {des:'Chromecast with Google TV'}, {des:'Compare Streaming Devices'}]},
                        {title:'Connectivity', list:[{des:'Nest Wifi'}, {des:'Google Wifi'}, {des:'Wi-Fi Systems'}, {des:'Compare Wi-Fi Systems'}]}]},
{column:2, subColumns:[{title:'Safety and Security', list:[{des:'Nest Hello video doorbell'}, {des:'Nest Protect smoke & CO alarm'}, {des:'Nest Secure alarm system'},{des:'Nest Yale Lock'}]}, 
                        {title:'Cameras', list:[{des:'Nest Cam IQ Indoor'}, {des:'Nest Cam Indoor'}, {des:'Nest Cam IQ Outdoor'}, {des:'Nest Cam Outdoo'}, {des:'Compare cameras'}]},
                        {title:'Services', list:[{des:'Nest Aware video history & alerts'}, {des:'Installation'}, {des:'Consultation'}]}]},
{column:3, subColumns:[{title:'Thermostats', list:[{des:'Nest Thermostat'}, {des:'Nest Learning Thermostat'}, {des:'Nest Temperature Sensor'}, {des:'Compare thermostats'}]},
                        {title:'Accessories', list:[{des:'Home assistance accessories'}, {des:'Camera accessories'}, {des:'Safety & security accessories'}, {des:'Thermostat accessories'},{des:'Streaming accessories'},{des:'Browse all'}]}]}
];
const stadiaOverviewColumns:OverviewColumn[] = [
{column:1, subColumns:[{title:'Stadia', list:[{des:'Premiere Edition'}, {des:'Stadia Controller'}]},
                        ]},
{column:2, subColumns:[{title:'Featured Acessories', list:[{des:'Power support CLAW'}, {des:'Browse all'}]}]}
                                            ];
const fitbitOverviewColumns:OverviewColumn[] = [
{column:1, subColumns:[{title:'Smartwatches', list:[{des:'Versa 3'}, {des:'Sense'}]},
                        ]},
{column:2, subColumns:[{title:'Trackers', list:[{des:'Luxe', status:'new'}, {des:'Inspire 2'},{des:'Charge 4'}]}]}
                                            ];
const pixelbookOverviewColumns:OverviewColumn[] = [
{column:1, subColumns:[{title:'Laptops', list:[{des:'Pixelbook Go'}]},
                        ]},
{column:2, subColumns:[{title:'Accessories Categories', list:[{des:'Case & Protection'}, {des:'Keyboards'},{des:'Headphones'},{des:'Adapters & cables'},{des:'Browse all'}]}]}
                                            ];                                                  
export const googleStoreOptions = ['Pixel', 'Nest', 'Stadia', 'Fitbit', 'Pixelbook', 'Subscriptions', 'Special Offers'];
export const storeOptions:StoreOption[] = [{title:'Pixel', overviewColumns:pixelOverviewColumns, 
                                    editorialLinks:[{link:'Phones Special Offers'}, {link:'Need product advice? Ask an expert'}, {link:'Switch to Pixel'}, {link:'Trade In Your Phone'}, {link:'Finance your purchase'}, {link:'Preferred Care'}, {link:'Google Fi'}, {link:'Help Center'}]},
                                {title:'Nest', overviewColumns:nestOverviewColumns, 
                                    editorialLinks:[{link:'Nest Special Offers'}, {link:'Explore products that work with the Google Assistant'}, {link:'Already have a device? Book installation with OnTech.'}, {link:'Need product advice? Ask an expert'}, {link:'Help Center'}]},
                                {title:'Stadia', overviewColumns:stadiaOverviewColumns,
                                    editorialLinks:[{link:'Games library'}, {link:'Help Center'}]},
                                {title:'Fitbit', overviewColumns:fitbitOverviewColumns,
                                    editorialLinks:[{link:'Fitbit Premium'}, {link:'Shop more Fitbit products'}]},
                                {title:'Pixelbook', overviewColumns:pixelbookOverviewColumns,
                                    editorialLinks:[{link:'Need product advice? Ask an expert.'}, {link:'Preferred Care'}, {link:'Finance your purchase'}, {link:'Student savings'}, {link:'Help Center'}]},
                                {title:'Subscriptions', overviewColumns:[], editorialLinks:[]},
                                {title:'Special Offers', overviewColumns:[], editorialLinks:[]}
                                ];
import React, {useRef, useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import getMobileStatus from './get-mobile-status';
import throttle from 'lodash.throttle';

import './VideoDevicesMovingComponent.css';

interface Props {}
type Ref = {
    vdmcwRef:React.RefObject<HTMLDivElement>,
    vdmRef:React.RefObject<HTMLVideoElement>,
    vdmmcwRef:React.RefObject<HTMLDivElement>,
    vdmmcRef:React.RefObject<HTMLDivElement>,
    vdmbcwRef:React.RefObject<HTMLDivElement>,
    svgBtnRef:React.RefObject<SVGSVGElement>,
    svgPathRef:React.RefObject<SVGPathElement>
};

const VideoDevicesMovingComponent = forwardRef<Ref, Props>((props, ref)=>{

    const vdmcwRef = useRef<HTMLDivElement>(null);
    //desktop
    const vdmRef = useRef<HTMLVideoElement>(null);
    //mobile
    const vdmmRef = useRef<HTMLVideoElement>(null);
    const vdmmcwRef = useRef<HTMLDivElement>(null);
    const vdmmcRef = useRef<HTMLDivElement>(null);
    const vdmbcwRef = useRef<HTMLDivElement>(null);
    const svgBtnRef = useRef<SVGSVGElement>(null);
    const svgPathRef = useRef<SVGPathElement>(null);
    const wh = useRef<number>(0);
    const ww = useRef<number>(0);
    const dw = useRef<number>(0);
    const videoDevicesMovingInitallyPlayed = useRef<boolean>(false);
    const svgBtnRefScrollPos = useRef<number>(0);
    const svgBtnTargetReached = useRef<boolean>(false);
    const svgBtnTargetPassed = useRef<boolean>(false);
    const videoDevicesMultiplier = useRef<number>(0);
    const videoDevicesTop = useRef<number>(0);
    const videoDevicesScrollHeight = useRef<number>(0);
    const videoDevicesTargetReached = useRef<boolean>(false);
    const videoDevicesTargetDistance = useRef<number>(0);
    const videoDevicesTotalScrollHeight = useRef<number>(0);
    const videoDevicesTargetHeight = useRef<number>(0);
    const videoDevicesContainerPassed = useRef<boolean>(false);
    const darken = useRef<number>(100);
    const sp = useRef<number>(0);
    const psp = useRef<number>(0);
    const ticked = useRef<boolean>(false);
    const isMobile = useRef<boolean>(false);

    const videoDeviceBottom = useRef<number>(0);
    const videoDeviceBottomReached = useRef<boolean>(false);
    const darkenDistance = useRef<number>(0);
    const videoBtnContainerTargetPosition = useRef<number>(0);
    const videoBtnTargetDistance = useRef<number>(0);
    const mmRef = useRef<HTMLSpanElement>(null);

    const portrait = useRef<MediaQueryList[]>([]);

    const pw = useRef<number>(0);


    useImperativeHandle(ref, ()=>{
        return ({
            get vdmcwRef(){
                return vdmcwRef;
            },
            get vdmRef(){
                return vdmRef;
            },
            get vdmmcwRef(){
                return vdmmcwRef;
            },
            get vdmmcRef(){
                return vdmmcRef;
            },
            get vdmbcwRef(){
                return vdmbcwRef;
            },
            get svgBtnRef(){
                return svgBtnRef;
            },
            get svgPathRef(){
                return svgPathRef;
            }
        })
    });

    useEffect(()=>{

      //isMobile.current = getMobileStatus(window.innerWidth, window.innerHeight) || window.matchMedia('(max-width:280px)').matches;
      if(Math.min(window.screen.width, window.screen.height) < 768){
        isMobile.current = true;
      }
      //console.log('moving #2 isMobile:', isMobile.current);

      setScreenDimensions();
      setupDarknessAnimation2();
      setupMovingMsgAnimation2();
      setupWifiCirclesVideoCSSStyles();

      //previous version
      //window.addEventListener('scroll', throttle(videoDevicesMovingComponentScrollHandler,100));
      window.addEventListener('scroll', videoDevicesMovingComponentScrollHandler);
      window.addEventListener('resize', videoDevicesMovingResizeHandler);

      portrait.current[0] = window.matchMedia("(orientation: portrait)");
      portrait.current[0].addEventListener("change", videoDevicesMovingResizeHandler);

      return cleanUpScrollHandler;

    },[]);

    function cleanUpScrollHandler():void {
      //previous version
      //window.removeEventListener('scroll', throttle(videoDevicesMovingComponentScrollHandler,100));
      window.removeEventListener('scroll', videoDevicesMovingComponentScrollHandler);
      window.removeEventListener('resize', videoDevicesMovingResizeHandler);

      portrait.current[0].removeEventListener("change", videoDevicesMovingResizeHandler);
    }

    function videoDevicesMovingResizeHandler(e:Event):void {
      if((e.type === 'resize' && pw.current === document.documentElement.clientWidth)){
        //console.log('r12:', 'pw:', pw.current, 'ww:', document.documentElement.clientWidth);
        ww.current = document.documentElement.clientWidth;
        pw.current = document.documentElement.clientWidth;
        return;
      }

      pw.current = document.documentElement.clientWidth;

      setScreenDimensions();
      setupDarknessAnimation2();
      setupMovingMsgAnimation2();
      setupWifiCirclesVideoCSSStyles();
    }

    function setScreenDimensions():void {
      if(!isMobile.current){
        ww.current = window.innerWidth;
        wh.current = window.innerHeight;
        dw.current = document.documentElement.clientWidth;
      
      } else {
        ww.current = window.screen.width;
        wh.current = window.screen.height;
        dw.current = window.screen.width;
      }

      ww.current = document.documentElement.clientWidth;
      wh.current = document.documentElement.clientHeight;

    }

    function setupDarknessAnimation2():void {
      if(isMobile.current && window.matchMedia('(max-width:767px)').matches
        || !isMobile.current && window.matchMedia('(max-width:767px)').matches){
        //mobile functionality
        videoDevicesTop.current = vdmmRef.current!.getBoundingClientRect().top + window.pageYOffset;
        //console.log('mobile height test:', videoDevicesTop.current);
        videoDevicesScrollHeight.current = vdmmRef.current!.scrollHeight;
        //console.log('mobile scoll height:', videoDevicesScrollHeight.current);
        videoDeviceBottom.current = vdmmRef.current!.getBoundingClientRect().top + vdmmRef.current!.getBoundingClientRect().height + window.pageYOffset;
        //console.log('vdb:', videoDeviceBottom.current, wh1.current);
        darkenDistance.current = videoDeviceBottom.current - wh.current;
        //console.log('dd:', darkenDistance.current);
        videoDevicesMultiplier.current = 40 / darkenDistance.current;
        //console.log('vddm:', videoDevicesMultiplier.current);

      } else {
        videoDevicesTop.current = vdmRef.current!.getBoundingClientRect().top + window.pageYOffset;//sp.current;
        //console.log('desktop height test:', videoDevicesTop.current);
        //console.log('desktop sp test:', sp.current);
        videoDevicesScrollHeight.current = vdmRef.current!.scrollHeight;
        videoDeviceBottom.current = vdmRef.current!.getBoundingClientRect().top + vdmRef.current!.getBoundingClientRect().height + window.pageYOffset;
        //console.log('vdb:', videoDeviceBottom.current);
        darkenDistance.current = videoDeviceBottom.current - wh.current;
        //console.log('dd:', darkenDistance.current);
        videoDevicesMultiplier.current = 40 / darkenDistance.current;
        //console.log('vddm:', videoDevicesMultiplier.current);
        darken.current = 100 - (40 - (videoDeviceBottom.current - wh.current) * videoDevicesMultiplier.current);
        //console.log('d:', darken.current);

      }

    }

    function setupMovingMsgAnimation2():void {
      //add animation to video devices moving msg
      const videoBtnContainerTopPosition = vdmbcwRef.current!.getBoundingClientRect().top + window.pageYOffset;
      //console.log('vbctop:', videoBtnContainerTopPosition);
      videoBtnContainerTargetPosition.current = videoBtnContainerTopPosition + (vdmbcwRef.current!.offsetHeight / 2);
      videoBtnTargetDistance.current = videoBtnContainerTargetPosition.current > wh.current ? videoBtnContainerTargetPosition.current - wh.current : 0;
      if(videoBtnTargetDistance.current === 0){
        vdmmcRef.current!.classList.add('slide-up-video-devices-moving-msg-container');
        vdmbcwRef.current!.classList.add('slide-up-video-devices-moving-btn-container');
      }
      //console.log('vbctp:', videoBtnContainerTargetPosition);
      if(ww.current >= 768){
        vdmmcwRef.current!.style.maxWidth = '750px';
        vdmmcwRef.current!.style.width = '60vw';
      } else {
        vdmmcwRef.current!.style.maxWidth = '';
        vdmmcwRef.current!.style.width = '';
      }
      
      ww.current <= 767 ? mmRef.current!.style.marginBottom = '15px' : mmRef.current!.style.marginBottom = '';
    }

    function playVideo(video:HTMLVideoElement):void {
      //set this up for the three conditions
      //1. desktop
      //2. desktop with mobile element
      //3. mobile
      video.currentTime = 0;
      const playPromise = video.play();
      if(playPromise !== undefined){
        playPromise.then((result)=>{
          //console.log('video is playing');
        })
        .catch((err)=>{
          console.error('video did not start', err);
        });
      }
    }

    function stopVideo(video:HTMLVideoElement):void {
      if(video.paused){
        video.currentTime = 0;
      } else {
        video.pause();
        video.currentTime = 0;
      }
    }
    
    function videoDevicesMovingComponentScrollHandler(e:Event):void {
      sp.current = window.pageYOffset;
      //console.log('vdm pos:', sp.current);

      if(!ticked.current){
        window.requestAnimationFrame(()=>{
          if(!videoDevicesMovingInitallyPlayed.current && sp.current <= videoDeviceBottom.current){
            //console.log('hello');
            (isMobile.current && window.matchMedia('(max-width:767px)').matches
            || !isMobile.current && window.matchMedia('(max-width:767px)').matches) ? playVideo(vdmmRef.current!)/*vdmmRef.current!.play()*/ : playVideo(vdmRef.current!)/*vdmRef.current!.play()*/;
            videoDevicesMovingInitallyPlayed.current = true;
          }

          if(sp.current <= darkenDistance.current){
            //console.log('darken:', darken.current = 100 - (40 - (darkenDistance.current - sp.current) * videoDevicesMultiplier.current), videoDeviceBottom.current - wh.current, sp.current);
            vdmcwRef.current!.style.setProperty('--darken', `${darken.current}%`);
          }

          if(sp.current > darkenDistance.current){
            darken.current = 60;
            vdmcwRef.current!.style.setProperty('--darken', `${darken.current}%`);
          }

          if(videoBtnTargetDistance.current > 0 && sp.current > videoBtnTargetDistance.current){
            vdmmcRef.current!.classList.add('slide-up-video-devices-moving-msg-container');
            vdmbcwRef.current!.classList.add('slide-up-video-devices-moving-btn-container');
          }

          if(videoBtnTargetDistance.current > 0 && sp.current < videoBtnTargetDistance.current){
            vdmmcRef.current!.classList.remove('slide-up-video-devices-moving-msg-container');
            vdmbcwRef.current!.classList.remove('slide-up-video-devices-moving-btn-container');
          }

          if(sp.current <= videoDeviceBottom.current && videoDeviceBottomReached.current){
            videoDeviceBottomReached.current = false;
            (isMobile.current && window.matchMedia('(max-width:767px)').matches
            || !isMobile.current && window.matchMedia('(max-width:767px)').matches)
            ? playVideo(vdmmRef.current!)
            : playVideo(vdmRef.current!);

          }

          if(sp.current > videoDeviceBottom.current){
            videoDeviceBottomReached.current = true;
            (isMobile.current && window.matchMedia('(max-width:767px)').matches
            || !isMobile.current && window.matchMedia('(max-width:767px)').matches)
            ? stopVideo(vdmmRef.current!)
            : stopVideo(vdmRef.current!);
            
          }
          
          
          ticked.current = false;
        });

        ticked.current = true;
        
      }

      //ticked.current = true;
    }

    function setupWifiCirclesVideoCSSStyles():void {
      const vdmcw =  vdmcwRef.current as HTMLDivElement;
      vdmcw.style.setProperty('--darken', `${darken.current}%`);
    }

    function changeMovingBtnBackgroundColor(e:React.MouseEvent):void {
        //console.log('pe:', svgPathRef.current);
        if(e.type === 'mouseenter'){
          svgBtnRef.current!.style.backgroundColor = '#fff';
          svgPathRef.current!.setAttribute('fill', 'black');
        } else {
          svgBtnRef.current!.style.backgroundColor = '';
          svgPathRef.current!.setAttribute('fill', '#fff');
        }
        
    }

    
    return (
        <div ref={vdmcwRef} className="video-devices-moving-container-wrapper">
          <div className="video-devices-moving-desktop-container">
            <video ref={vdmRef} className="video-devices-moving" muted={true} playsInline>
              <source src="https://server.kando-proto-2.com/wifi-devices-moving-desktop.mp4" type="video/mp4"/>
            </video>
            <svg viewBox="0 0 2880 1440" className="video-devices-moving-svg"></svg>
          </div>
          <div className="video-devices-moving-mobile-container">
            <video ref={vdmmRef} className="video-devices-moving" muted={true} playsInline>
              <source src="https://server.kando-proto-2.com/wifi-devices-moving-mobile.mp4" type="video/mp4"/>
            </video>
            <svg viewBox="0 0 638 1136" className="video-devices-moving-svg"></svg>
          </div>
          <div ref={vdmmcwRef} className="video-devices-moving-msg-container-wrapper">
            <div ref={vdmmcRef} className="video-devices-moving-msg-container">
              <span ref={mmRef} className="video-devices-moving-msg">
                Nest Wifi blankets your whole home in fast, reliable Wi‑Fi and keeps buffering at bay in every room.
              </span>
            </div>
            <div ref={vdmbcwRef} className="video-devices-moving-btn-container-wrapper">
              <div onMouseEnter={changeMovingBtnBackgroundColor} onMouseLeave={changeMovingBtnBackgroundColor} className="video-devices-moving-btn-container">
                <svg ref={svgBtnRef} width="42" height="42" viewBox="0 0 42 42" className="video-devices-moving-btn-svg" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <circle stroke="#FFF" strokeWidth="2" opacity=".348" cx="21" cy="21" r="20"></circle>
                    <path ref={svgPathRef} fill="#FFF" d="M17.2 15.4v10.96l8.92-5.48z"></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
    );
});

export default VideoDevicesMovingComponent;
import React, {useRef, useEffect, useState, ChangeEvent} from 'react';
import {Link} from '@reach/router';

import throttle from 'lodash.throttle';
import './App.css';
//import './SS2.css';
//import './SlideShow.css';
import NestWifiNav from './NestWifiNav';
import GoogleStoreNav from './GoogleStoreNav';
import VideoDevicesColorsComponent from './VideoDevicesColorsComponent';
import VideoDevicesMovingComponent from './VideoDevicesMovingComponent';
import RoomWifiVideosComponent from './RoomWifiVideosComponent';
import StreamWithSpeedComponent from './StreamWithSpeedComponent';
import WifiSlideShowComponent from './WifiSlideShowComponent';
import NavComponent from './NavComponent';


/*
interface ScrollPosition {
  zone:string;
  pos:number;
  scale:number;
  origin:number;
  singleVideoY:number;
}

interface EditorialLink {
  link:string;
}

interface StoreOption {
  title:string;
  overviewColumns:OverviewColumn[];
  editorialLinks:EditorialLink[];
}

interface OverviewSubColumn {
  title:string;
  list:{des:string, status?:string}[];
}

interface OverviewColumn {
  column:number;
  subColumns:OverviewSubColumn[];
}
*/


interface Props{}

const App:React.FunctionComponent<Props> = (props:Props)=>{
  //generic variables
  const wh = useRef<number>(0);
  const ww = useRef<number>(0);
  const dw = useRef<number>(0);
  //const vdRef = useRef<HTMLVideoElement>(null);
  const videoDevicesColorsInitallyPlayed = useRef<boolean>(false);
  //const vdmRef = useRef<HTMLVideoElement>(null);
  const videoDevicesMovingInitallyPlayed = useRef<boolean>(false);
  //const vdcRef = useRef<HTMLDivElement>(null);
  //moving video wifi circles variables
  //const svgPathRef = useRef<SVGPathElement>(null);
  //const svgBtnRef = useRef<SVGSVGElement>(null);
  const svgBtnRefScrollPos = useRef<number>(0);
  const svgBtnTargetReached = useRef<boolean>(false);
  const svgBtnTargetPassed = useRef<boolean>(false);
  //const vdmcwRef = useRef<HTMLDivElement>(null);
  const videoDevicesMultiplier = useRef<number>(0);
  const videoDevicesTop = useRef<number>(0);
  const videoDevicesScrollHeight = useRef<number>(0);
  const videoDevicesTargetReached = useRef<boolean>(false);
  const videoDevicesTargetDistance = useRef<number>(0);
  const videoDevicesTotalScrollHeight = useRef<number>(0);
  const videoDevicesTargetHeight = useRef<number>(0);
  const videoDevicesContainerPassed = useRef<boolean>(false);
  const darken = useRef<number>(100);
  //const vdmmcwRef = useRef<HTMLDivElement>(null);
  //const vdmmcRef = useRef<HTMLDivElement>(null);
  //const vdmbcwRef = useRef<HTMLDivElement>(null);
  //const vdcbRef = useRef<HTMLSpanElement>(null);
  //const vdcmcRef = useRef<HTMLDivElement>(null);
  //const vdcbcRef = useRef<HTMLDivElement>(null);
  //const vdccRef = useRef<HTMLDivElement>(null);
  const videoDevicesColorsTargetHeight = useRef<number>(0);
  //const navBtnOptions = ['Overview', 'Tech Specs', 'How it works', 'Privacy', 'Compare'];
  //const navBtnOptionRefs = useRef<HTMLButtonElement[]>([]);
  //const googleStoreOptions = ['Pixel', 'Nest', 'Stadia', 'Fitbit', 'Pixelbook', 'Subscriptions', 'Special Offers'];
  //const googleStoreSubMenuOtherOptions = ['Phones Special Offers', 'Need product advice? Ask an expert.', 'Switch to Pixel',
  //                                       'Trade In Your Phone', 'Finance your purchase', 'Preferred Care', 'Google Fi', 'Help Center'];
  /*
  const pixelOverviewColumns:OverviewColumn[] = [
                            {column:1, subColumns:[{title:'PHONE', list:[{des:'Pixel 5'}, {des:'Pixel 4a (5G)'}, {des:'Pixel 4a Phones'}, {des:'Compare phones'}]}]},
                            {column:2, subColumns:[{title:'WIRELESS EARBUDS', list:[{des:'Pixel Buds-A-Series', status:'new'}, {des:'Pixel Buds'}, {des:'Compare Pixel Buds'}]}, 
                                                   {title:'WIRELESS CHARGER', list:[{des:'Pixel Stand'}]},
                                                   {title:'CASES', list:[{des:'Pixel 5 Case'}, {des:'Pixel 4a (5G) Case'}, {des:'Pixel 4a Case'}]}]},
                            {column:3, subColumns:[{title:'ACCESSORIES CATEGORIES', list:[{des:'Cases & protection'}, {des:'Headphones'}, {des:'Power cables & adapters'}, {des:'Browse all'}]}]}
                        ];
  const nestOverviewColumns:OverviewColumn[] = [
    {column:1, subColumns:[{title:'SOLUTIONS AT HOME', list:[{des:'Audio Entertainment'}, {des:'Video Entertainment'}, {des:'Home Security'}, {des:'Compare phones'}]},
                            {title:'SPEAKERS', list:[{des:'Nest Audio'}, {des:'Nest Mini (2nd gen)'}, {des:'Google Home Mini'}, {des:'Compare speakers'}]},
                            {title:'DISPLAYS', list:[{des:'Nest Hub Max'}, {des:'Nest Hub (2nd gen)'}, {des:'Compare displays'}]},
                            {title:'STREAMING', list:[{des:'Chromecast'}, {des:'Chromecast with Google TV'}, {des:'Compare Streaming Devices'}]},
                            {title:'CONNECTIVITY', list:[{des:'Nest Wifi'}, {des:'Google Wifi'}, {des:'Wi-Fi Systems'}, {des:'Compare Wi-Fi Systems'}]}]},
    {column:2, subColumns:[{title:'SAFETY AND SECURITY', list:[{des:'Nest Hello video doorbell'}, {des:'Nest Protect smoke & CO alarm'}, {des:'Nest Secure alarm system'},{des:'Nest Yale Lock'}]}, 
                            {title:'CAMERAS', list:[{des:'Nest Cam IQ Indoor'}, {des:'Nest Cam Indoor'}, {des:'Nest Cam IQ Outdoor'}, {des:'Nest Cam Outdoo'}, {des:'Compare cameras'}]},
                            {title:'SERVICES', list:[{des:'Nest Aware video history & alerts'}, {des:'Installation'}, {des:'Consultation'}]}]},
    {column:3, subColumns:[{title:'THERMOSTATS', list:[{des:'Nest Thermostat'}, {des:'Nest Learning Thermostat'}, {des:'Nest Temperature Sensor'}, {des:'Compare thermostats'}]},
                            {title:'ACCESSORIES', list:[{des:'Home assistance accessories'}, {des:'Camera accessories'}, {des:'Safety & security accessories'}, {des:'Thermostat accessories'},{des:'Streaming accessories'},{des:'Browse all'}]}]}
  ];
  const stadiaOverviewColumns:OverviewColumn[] = [
    {column:1, subColumns:[{title:'STADIA', list:[{des:'Premiere Edition'}, {des:'Stadia Controller'}]},
                          ]},
    {column:2, subColumns:[{title:'FEATURED ACCESSORIES', list:[{des:'Power support CLAW'}, {des:'Browse all'}]}]}
                                                ];
  const fitbitOverviewColumns:OverviewColumn[] = [
    {column:1, subColumns:[{title:'SMARTWATCHES', list:[{des:'Versa 3'}, {des:'Sense'}]},
                          ]},
    {column:2, subColumns:[{title:'TRACKERS', list:[{des:'Luxe', status:'new'}, {des:'Inspire 2'},{des:'Charge 4'}]}]}
                                                ];
  const pixelbookOverviewColumns:OverviewColumn[] = [
    {column:1, subColumns:[{title:'LAPTOPS', list:[{des:'Pixelbook Go'}]},
                          ]},
    {column:2, subColumns:[{title:'ACCESSORIES CATEGORIES', list:[{des:'Case & Protection'}, {des:'Keyboards'},{des:'Headphones'},{des:'Adapters & cables'},{des:'Browse all'}]}]}
                                                ];                                                                                             
  const storeOptions:StoreOption[] = [{title:'Pixel', overviewColumns:pixelOverviewColumns, 
                                        editorialLinks:[{link:'Phones Special Offers'}, {link:'Need product advice? Ask an expert'}, {link:'Switch to Pixel'}, {link:'Trade In Your Phone'}, {link:'Finance your purchase'}, {link:'Preferred Care'}, {link:'Google Fi'}, {link:'Help Center'}]},
                                      {title:'Nest', overviewColumns:nestOverviewColumns, 
                                        editorialLinks:[{link:'Nest Special Offers'}, {link:'Explore products that work with the Google Assistant'}, {link:'Already have a device? Book installation with OnTech.'}, {link:'Need product advice? Ask an expert'}, {link:'Help Center'}]},
                                      {title:'Stadia', overviewColumns:stadiaOverviewColumns,
                                        editorialLinks:[{link:'Games library'}, {link:'Help Center'}]},
                                      {title:'Fitbit', overviewColumns:fitbitOverviewColumns,
                                        editorialLinks:[{link:'Fitbit Premium'}, {link:'Shop more Fitbit products'}]},
                                      {title:'Pixelbook', overviewColumns:pixelbookOverviewColumns,
                                        editorialLinks:[{link:'Need product advice? Ask an expert.'}, {link:'Preferred Care'}, {link:'Finance your purchase'}, {link:'Student savings'}, {link:'Help Center'}]}
                                      ];
                                      */
  
  //const googleStoreOptionSubMenuRefs = useRef<HTMLDivElement[]>([]);
  //const googleStoreOptionsRefs = useRef<HTMLButtonElement[]>([]);
  const overlayRef = useRef<HTMLDivElement>(null);
  const nestWifiNavRef = useRef<HTMLDivElement>(null);
  const nestWifiNavTop = useRef<number>(0);
  //const nwRef = useRef<HTMLVideoElement>(null);
  //const wcRef = useRef<HTMLVideoElement>(null);
  //const wsrRef = useRef<HTMLVideoElement>(null);
  //const warRef = useRef<HTMLVideoElement>(null);
  //const vsrcRef = useRef<HTMLDivElement>(null);
  //const varcRef = useRef<HTMLDivElement>(null);
  const vsRef = useRef<HTMLDivElement>(null);
  //const stickyRef = useRef<HTMLDivElement>(null);
  //const vrhcRef = useRef<HTMLDivElement>(null);
  //const vsrmcRef = useRef<HTMLDivElement>(null);
  //const varmcRef = useRef<HTMLDivElement>(null);
  //window offset props
  const sp = useRef<number>(0);
  const psp = useRef<number>(0);
  //parent props
  //const pRef = useRef<HTMLDivElement>(null);
  //sticky props
  const stickyElementTop = useRef<number>(0);
  //const counter = useRef<number>(0);
  //room layout props
  const ticked = useRef<boolean>(false);
  //const [videoRoomIntroShiftY, setVideoRoomIntroShiftY] = useState(0);
  const videoRoomIntroShiftYRef = useRef<number>(0);
  //const [singleVideoScale, setSingleVideoScale] = useState(1.5);
  const singleVideoScale = useRef<number>(2);
  const singleVideoOrigin = useRef<number>(70);
  const singleVideoY = useRef<number>(0);
  const singleVideoClipX = useRef<number>(125);
  const singleVideoClipTop = useRef<number>(240);
  const singleVideoClipBottom = useRef<number>(68.75);
  const videoRoomHeaderY = useRef<number>(0);
  const singleRoomY = useRef<number>(30);
  //const singleRoomOpacity = useRef<number>(0);
  //const singleVideoOpacity = useRef<number>(1);
  const allRoomY = useRef<number>(30);
  //const allRoomOpacity = useRef<number>(0);
  const allVideoOpacity = useRef<number>(0);
  const [showLayout, setShowLayout] = useState(false);
  const showStickyElement = useRef<boolean>(false);

  //test variables
  const sdv = useRef<number>(0);
  const sdv2 = useRef<number>(0);
  const scrollDirection = useRef<string>('down');
  const scrollZone = useRef<string>('none');
  const scaleDecimal = useRef<number>(1000);
  const scaleMultiplier = useRef<number>(0);
  //const scaleAdj = useRef<number>(1000);
  const originMultiplier = useRef<number>(0);
  const originAdj = useRef<number>(20);
  const clipXMultiplier = useRef<number>(0);
  const clipTopMultiplier = useRef<number>(0);
  const clipBottomMultiplier = useRef<number>(0);
  const videoRoomHeaderYMultiplier = useRef<number>(0);
  const singleVideoYMultiplier = useRef<number>(0);
  //const singleVideoYDecimal = useRef<number>(68.75);
  const singleVideoYDecimal = useRef<number>(68.75);
  const singleRoomYMultiplier = useRef<number>(0);
  const singleRoomOpacityMultiplier = useRef<number>(0);
  //const singleVideoOpacityMultiplier = useRef<number>(0);
  const allRoomYMultiplier = useRef<number>(0);
  const allRoomOpacityMultiplier = useRef<number>(0);
  const allVideoOpacityMultiplier = useRef<number>(0);
  const sro = useRef<number>(0);
  const aro = useRef<number>(0);
  //const svo = useRef<number>(1000);
  const avo = useRef<number>(0);
  //const prevScrollPositions = useRef<ScrollPosition[]>([]);
  const stRef = useRef<number>(0);

  //stream with sppeed variables
  //const sswRef = useRef<HTMLDivElement>(null);
  //const sshcRef = useRef<HTMLDivElement>(null);
  const stickyHeaderContainer = useRef<number>(0);
  //const ssfiRef = useRef<HTMLDivElement>(null);
  //const ssbiRef = useRef<HTMLDivElement>(null);
  //const sslicwRef = useRef<HTMLDivElement>(null);
  //const ssliRef = useRef<HTMLDivElement>(null);
  //const sStickyRef = useRef<HTMLDivElement>(null);
  const imageSwipe = useRef<number>(100);
  const imageScale = useRef<number>(1.2);
  const imageScaleTopBoundary = useRef<number>(0);
  const imageScaleBottomBoundary = useRef<number>(0);
  const stickyOffsetY = useRef<number>(227);
  const tout1Y = useRef<number>(0);
  const tout3Y = useRef<number>(0);
  const tout1X = useRef<number>(0);
  const tout3X = useRef<number>(0);
  //original version
  //const expanderClipX = useRef<number>(14.7);
  const expanderClipXCurrent = useRef<number>(0);
  const expanderClipXStart = useRef<number>(0);
  const expanderClipXEnd = useRef<number>(0);
  const expanderClipY = useRef<number>(0);
  //original version
  //const expanderScale = useRef<number>(.42);
  const expanderScaleCurrent = useRef<number>(0);
  const expanderScaleStart = useRef<number>(0);
  const expanderScaleEnd = useRef<number>(1);
  const expanderY = useRef<number>(227);
  const staggerFactorExpand = useRef<number>(0);
  const header2Opacity = useRef<number>(0);
  const imageBlurOpacity = useRef<number>(0);
  const biHeight = useRef<number>(0);
  const biTop = useRef<number>(0);
  //const ssbiecRef = useRef<HTMLDivElement>(null);
  const imgContainerWidth = useRef<number>(0);
  const imgContainerHeight = useRef<number>(0);

  //adjustment values
  const expanderClipXAdjustmentMultiplier = useRef<number>(0);
  const toutXAdjustmentMultiplier = useRef<number>(0);
  const toutXAdjustment = useRef<number>(0);
  const toutYAdjustmentMultiplier = useRef<number>(0);
  const expanderScaleMultiplier = useRef<number>(0);
  const expanderScaleAdjustment = useRef<number>(0);
  const imageSwipeAdjustmentMultiplier = useRef<number>(0);

  //wifi slide show variables
  //const canvasRef = useRef<HTMLCanvasElement>(null);
  //const canvasWrapperRef = useRef<HTMLDivElement>(null);
  //const slideShowWrapperRef = useRef<HTMLDivElement>(null);
  //const wssihtc1 = useRef<HTMLDivElement>(null);
  //const wssihtc2 = useRef<HTMLDivElement>(null);
  //const wssihcc1 = useRef<HTMLDivElement>(null);
  //const wssihcc2 = useRef<HTMLDivElement>(null);
  //const gailc = useRef<HTMLDivElement>(null);
  const canvasY = useRef<number>(0);
  //const wssic = useRef<HTMLDivElement>(null);
  const slideShowImgContainerTopPosition = useRef<number>(0);
  const slideShowImgContainerHeight = useRef<number>(0);
  //const wssihc = useRef<HTMLDivElement>(null);
  const slideShowTopBoundary = useRef<number>(0);
  const slideShowMsgAdjust = useRef<number>(0);
  const slideShowImgs = useRef<HTMLImageElement[]>([]);
  const imgCounter = useRef<number>(0);
  const totalImgs = useRef<number>(0);

  //adjustment variables
  const imgCounterMultiplier = useRef<number>(0);
  const slideShowImgContainerMultiplier = useRef<number>(0);

  //VideoDevicesColorsComponent Ref
  const videoDevicesColorsRef = useRef<{
    vdccRef:React.RefObject<HTMLDivElement>,
    vdcmcRef:React.RefObject<HTMLDivElement>,
    vdcbcRef:React.RefObject<HTMLDivElement>,
    vdcRef:React.RefObject<HTMLDivElement>,
    vdRef:React.RefObject<HTMLVideoElement>
            }>(null);

  //videoDevicesMovingComponent Ref
  const vidoeDevicesMovingRef = useRef<{
    vdmcwRef:React.RefObject<HTMLDivElement>,
    vdmRef:React.RefObject<HTMLVideoElement>,
    vdmmcwRef:React.RefObject<HTMLDivElement>,
    vdmmcRef:React.RefObject<HTMLDivElement>,
    vdmbcwRef:React.RefObject<HTMLDivElement>,
    svgBtnRef:React.RefObject<SVGSVGElement>,
    svgPathRef:React.RefObject<SVGPathElement>
  }>(null);

  //RoomWifiVideosComponent Ref
  const roomWifiVideosRef = useRef<{
      vsRef:React.RefObject<HTMLDivElement>,
      stickyRef:React.RefObject<HTMLDivElement>,
      vsrcRef:React.RefObject<HTMLDivElement>,
      wsrRef:React.RefObject<HTMLVideoElement>,
      varcRef:React.RefObject<HTMLDivElement>,
      warRef:React.RefObject<HTMLVideoElement>,
      vrhcRef:React.RefObject<HTMLDivElement>,
      vsrmcRef:React.RefObject<HTMLDivElement>,
      varmcRef:React.RefObject<HTMLDivElement>
  }>(null);

  //StreamWithSpeedComponent Ref
  const streamWithSpeedRef = useRef<{
    sswRef:React.RefObject<HTMLDivElement>,
    sshcRef:React.RefObject<HTMLDivElement>,
    sStickyRef:React.RefObject<HTMLDivElement>,
    ssbiecRef:React.RefObject<HTMLDivElement>,
    sslicwRef:React.RefObject<HTMLDivElement>,
    ssliRef:React.RefObject<HTMLDivElement>
}>(null);

  //WifiSlideShowComponent Ref
  const wifiSlideShowRef = useRef<{
    slideShowWrapperRef:React.RefObject<HTMLDivElement>,
    wssic:React.RefObject<HTMLDivElement>,
    canvasWrapperRef:React.RefObject<HTMLDivElement>,
    canvasRef:React.RefObject<HTMLCanvasElement>,
    wssihc:React.RefObject<HTMLDivElement>,
    wssihtc1:React.RefObject<HTMLDivElement>,
    wssihcc1:React.RefObject<HTMLDivElement>,
    wssihtc2:React.RefObject<HTMLDivElement>,
    wssihcc2:React.RefObject<HTMLDivElement>,
    gailc:React.RefObject<HTMLDivElement>,
    slideShowImgs:React.RefObject<HTMLImageElement[]>
  }>(null);

  const additionalPaddingRef = useRef<HTMLDivElement>(null);
  const portrait = useRef<MediaQueryList[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{

    window.scrollTo(0,0);

    window.setTimeout(()=>{
      setIsLoading(false);
    },1000);


    portrait.current[0] = window.matchMedia("(orientation: portrait)");
    portrait.current[0].addEventListener("change", mainAppResizeHandler);

    return cleanupMainAppResizeHandler;

  },[]);

  function cleanupMainAppResizeHandler():void {
    portrait.current[0].removeEventListener("change", mainAppResizeHandler);

    window.removeEventListener('beforeunload', beforeunloadHandler);

  }

  function mainAppResizeHandler(e:MediaQueryListEvent):void {
    
    if(e.matches){
        additionalPaddingRef.current!.style.height = '66px';
    } else {
        additionalPaddingRef.current!.style.height = '0px';
    }
  }


  function updateShowLayout(showLayout:boolean):void {
    setShowLayout(showLayout);
    //console.log('layout loaded');
  }



  function beforeunloadHandler(e:Event):void {
    //console.log('beforeUnload');
    window.scrollTo(0,0);
  }


  function cleanUpScrollHandler():void {
    //window.removeEventListener('resize', resizeHandler);
    //window.removeEventListener('scroll', throttle(appScrollHandler,100));
    window.removeEventListener('beforeunload', beforeunloadHandler);
  }


  if(!isLoading){
    return (
      <div ref={vsRef} className="video-scope">
        <section className="nest-wifi-location-info-container">
          <div className="nest-wifi-promo-text-wrapper">
            <div className="nest-wifi-promo-text">
              You can now find us in NYC. Explore the new Google Store.&nbsp;
              <Link className="nest-wifi-promo-text-link" to="/">Learn more</Link>
            </div>
          </div>
        </section>
        {/*original version*/}
        {<NavComponent/>}
        {/*original version*/}
        {<VideoDevicesColorsComponent ref={videoDevicesColorsRef}/>}
        {/*original version*/}
        {<VideoDevicesMovingComponent ref={vidoeDevicesMovingRef}/>}
        {<RoomWifiVideosComponent ref={roomWifiVideosRef}/>}
        {/*original version*/}
        {<StreamWithSpeedComponent ref={streamWithSpeedRef}/>}
          {/*original version*/}
          {<WifiSlideShowComponent ref={wifiSlideShowRef} updateShowLayout={updateShowLayout}/>}
          <div ref={additionalPaddingRef} className="additional-height"></div>
          <div ref={overlayRef} className="overlay hide"></div>
        </div>
    );


  } else {
    return null;
  }

}

export default App;

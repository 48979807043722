import React, {useRef, useEffect, useState, forwardRef, useImperativeHandle, SyntheticEvent} from 'react';
import getMobileStatus from './get-mobile-status';
import throttle from 'lodash.throttle';

import './RoomWifiVideosComponent.css';


interface Props {}
type Ref = {
    vsRef:React.RefObject<HTMLDivElement>,
    stickyRef:React.RefObject<HTMLDivElement>,
    vsrcRef:React.RefObject<HTMLDivElement>,
    wsrRef:React.RefObject<HTMLVideoElement>,
    varcRef:React.RefObject<HTMLDivElement>,
    warRef:React.RefObject<HTMLVideoElement>,
    vrhcRef:React.RefObject<HTMLDivElement>,
    vsrmcRef:React.RefObject<HTMLDivElement>,
    varmcRef:React.RefObject<HTMLDivElement>
};

interface ObjectFitSize {
  width: number,
  height: number,
  x: number,
  y: number
}

const RoomWifiVideosComponent = forwardRef<Ref, Props>((props, ref)=>{
    const t1 = useRef<number>(0);
    const t2 = useRef<number>(0);
    const t3 = useRef<number>(0);
    const vsRef = useRef<HTMLDivElement>(null);
    const stickyRef = useRef<HTMLDivElement>(null);
    const vrcRef = useRef<HTMLDivElement>(null);
    const vsrcRef = useRef<HTMLDivElement>(null);
    const wsrdRef = useRef<HTMLVideoElement>(null);
    const wsrmRef = useRef<HTMLVideoElement>(null);
    const varcRef = useRef<HTMLDivElement>(null);
    const wardRef = useRef<HTMLVideoElement>(null);
    const warmRef = useRef<HTMLVideoElement>(null);
    const vrhcRef = useRef<HTMLDivElement>(null);
    const vsrmcRef = useRef<HTMLDivElement>(null);
    const varmcRef = useRef<HTMLDivElement>(null);
    const sp = useRef<number>(0);
    const psp = useRef<number>(0);
    const ticked = useRef<boolean>(false);
    const imageScaleTopBoundary = useRef<number>(0);
    const videoRoomIntroShiftYRef = useRef<number>(0);
    const videoRoomIntroShiftYStart = useRef<number>(0);
    const videoRoomIntroShiftYEnd = useRef<number>(0);
    const stickyElementTop = useRef<number>(0);
    const singleVideoScale = useRef<number>(2);
    const singleVideoOrigin = useRef<number>(70);
    const singleVideoY = useRef<number>(0);
    const singleVideoYEnd = useRef<number>(0);
    const singleVideoYStart = useRef<number>(0);
    const singleVideoClipX = useRef<number>(0);
    const singleVideoClipXStart = useRef<number>(0);
    const singleVideoClipXEnd = useRef<number>(0);
    const singleVideoClipTop = useRef<number>(240);
    const singleVideoClipTopStart = useRef<number>(0);
    const singleVideoClipTopEnd = useRef<number>(0);
    const singleVideoClipBottom = useRef<number>(0);
    const singleVideoClipBottomStart = useRef<number>(0);
    const singleVideoClipBottomEnd = useRef<number>(0);
    const videoRoomHeaderY = useRef<number>(0);
    const videoRoomHeaderYStart = useRef<number>(0);
    const videoRoomHeaderYEnd = useRef<number>(0);
    const singleRoomY = useRef<number>(30);
    const singleRoomOpacity = useRef<number>(0);
    const singleVideoOpacity = useRef<number>(1);
    const allRoomY = useRef<number>(30);
    const allRoomOpacity = useRef<number>(0);
    const allVideoOpacity = useRef<number>(0);
    const [showLayout, setShowLayout] = useState(false);
    const showStickyElement = useRef<boolean>(false);
  
    //test variables
    const sdv = useRef<number>(0);
    const sdv2 = useRef<number>(0);
    const scrollDirection = useRef<string>('down');
    const scrollZone = useRef<string>('none');
    const scaleDecimal = useRef<number>(1000);
    const scaleMultiplier = useRef<number>(0);
    //const scaleAdj = useRef<number>(1000);
    const originMultiplier = useRef<number>(0);
    const originAdj = useRef<number>(20);
    const originMobileAdj = useRef<number>(25);
    const videoRoomIntroShiftYMultiplier = useRef<number>(0);
    const clipXMultiplier = useRef<number>(0);
    const clipTopMultiplier = useRef<number>(0);
    const clipBottomMultiplier = useRef<number>(0);
    const videoRoomHeaderYMultiplier = useRef<number>(0);
    const singleVideoYMultiplier = useRef<number>(0);
    //const singleVideoYDecimal = useRef<number>(68.75);
    const singleVideoYDecimal = useRef<number>(0);
    const singleRoomYMultiplier = useRef<number>(0);
    const singleRoomOpacityMultiplier = useRef<number>(0);
    //const singleVideoOpacityMultiplier = useRef<number>(0);
    const allRoomYMultiplier = useRef<number>(0);
    const allRoomOpacityMultiplier = useRef<number>(0);
    const allVideoOpacityMultiplier = useRef<number>(0);
    const sro = useRef<number>(0);
    const aro = useRef<number>(0);
    //const svo = useRef<number>(1000);
    const avo = useRef<number>(0);
    //const prevScrollPositions = useRef<ScrollPosition[]>([]);
    const stRef = useRef<number>(0);
    const [mql, setMql] = useState<MediaQueryList>(window.matchMedia('(max-width:767px)'));

    //adjustment variables for --video-room-intro-shift-y
    const raf0 = useRef<number>(0);
    const raf1 = useRef<number>(0);
    const raf2 = useRef<number>(0);
    const raf3 = useRef<number>(0);
    const raf4 = useRef<number>(0);
    const raf5 = useRef<number>(0);
    const states = useRef<Function[]>([]);
    const startPoint = useRef<number[]>([0,0]);
    const endPoint = useRef<number[]>([0,0]);
    const amount = useRef<number>(100);
    const scrollSpeed = useRef<string>('normal');
    const [speedLimit, setSppedLimit] = useState(579);
    const [speedMinimum, setSppedMinimum] = useState(50);
    const sectionName = useRef<string>('t1');
    const runSectionT1 = useRef<boolean>(false);

    const [screenWidth, setScreenWidth] = useState<number>(0);
    const cet = useRef<number>(0);
    const scrollParam = useRef<boolean>(true);
    const runAnimateState = useRef<boolean>(false);
    const timer = useRef<number>(0);
    const startTime = useRef<number>(0);
    const showMsg = useRef<boolean>(false);

    const cancelAnimation = useRef<boolean>(false);
    const prevScrollDirection = useRef<string>('down');

    const breakpoint = useRef<boolean>(false);
    const prevScrollSpeed = useRef<string>('normal');
    const speedDistance = useRef<number>(0);

    const prevInit = useRef<number>(0);
    const currentInit = useRef<number>(0);

    const t1Adj = useRef<number>(0);
    const t2Adj = useRef<number>(0);
    const t3Adj = useRef<number>(0);
    const sectionDiff = useRef<number>(0);
    const arhStart = useRef<number>(0);
    const msgsAvailScrollDistance = useRef<number>(0);

    const isMobile = useRef<boolean>(false);
    const ww = useRef<number>(0);
    const dw = useRef<number>(0);
    const wh = useRef<number>(0);


    const vp1 = useRef<boolean>(false);
    const vp2 = useRef<boolean>(false);
    const vp3 = useRef<boolean>(false);
    const vp4 = useRef<boolean>(false);

    const portrait = useRef<MediaQueryList[]>([]);

    const pw = useRef<number>(0);

    useImperativeHandle(ref, ()=>{
        return ({
            get vsRef(){
                return vsRef;
            },
            get stickyRef(){
                return stickyRef;
            },
            get vsrcRef(){
                return vsrcRef;
            },
            get wsrRef(){
                return wsrdRef;
            },
            get varcRef(){
                return varcRef;
            },
            get warRef(){
                return wardRef;
            },
            get vrhcRef(){
                return vrhcRef;
            },
            get vsrmcRef(){
                return vsrmcRef;
            },
            get varmcRef(){
                return varmcRef;
            }
        });
    });

    useEffect(()=>{

      //isMobile.current = getMobileStatus(window.innerWidth, window.innerHeight) || window.matchMedia('(max-width:280px)').matches;
      if(Math.min(window.screen.width, window.screen.height) < 768){
        isMobile.current = true;
      }
      //console.log('im:', isMobile.current, window.screen);

      if(!isMobile.current){
        ww.current = window.innerWidth;
        wh.current = window.innerHeight;
        //dw.current = document.documentElement.clientWidth;
      
      } else {
        ww.current = window.screen.width;
        wh.current = window.screen.height;
        //dw.current = window.screen.width;
      }


      ww.current = document.documentElement.clientWidth;
      wh.current = document.documentElement.clientHeight;
      
      setScreenWidth(ww.current);
      stickyElementTop.current = vsRef.current!.getBoundingClientRect().top + window.pageYOffset;// (stickyRef.current!.getBoundingClientRect().top as number) + window.pageYOffset;
      //console.log('set:', stickyElementTop.current);
      //console.log('parent:', vsRef.current!.getBoundingClientRect().top + window.pageYOffset);

      imageScaleTopBoundary.current = stickyRef.current!.getBoundingClientRect().top;
    
      const heightAdj = varcRef.current!.getBoundingClientRect().height;
      window.requestAnimationFrame(setupSingleVideoYValues);
      
      //varcRef.current!.style.height = `${heightAdj}px`;
      //vsrcRef.current!.style.height = `${heightAdj}px`;
      //vrcRef.current!.style.height = `${heightAdj}px`;
      //stickyRef.current!.style.height = `${heightAdj}px`;

      /*
      const clipX = window.innerWidth * .10;
      console.log('clipX:', clipX);
      singleVideoClipXEnd.current = singleVideoClipX.current = clipX;
      const clipTop = vrhcRef.current!.offsetHeight + vrhcRef.current!.offsetTop;
      console.log('clipTop:', clipTop);
      singleVideoClipTopEnd.current = singleVideoClipTop.current = clipTop;
      videoRoomIntroShiftYEnd.current = clipTop * .5;
      console.log('shift-y:', videoRoomIntroShiftYEnd.current);
      videoRoomIntroShiftYMultiplier.current = videoRoomIntroShiftYEnd.current / 200;
      videoRoomHeaderYEnd.current = videoRoomIntroShiftYEnd.current + 70;
      

      if(mql.matches){
        singleVideoOrigin.current = 75;

        (wsrmRef.current as HTMLVideoElement).play();
        (warmRef.current as HTMLVideoElement).play();
      } else {
        (wsrdRef.current as HTMLVideoElement).play();
        (wardRef.current as HTMLVideoElement).play();
      }
      */

      //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
      //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `linear`);
      

      //original version and position
      //setupWifiCSSStyles();
      window.addEventListener('scroll', throttle(roomWifiVideosComponentScrollHandler,50));
      window.addEventListener('resize', resizeHandler);

      portrait.current[0] = window.matchMedia("(orientation: portrait)");
      portrait.current[0].addEventListener("change", resizeHandler);

      return cleanUpScrollHandler;

    },[]);

    function cleanUpScrollHandler():void {
      window.removeEventListener('scroll', throttle(roomWifiVideosComponentScrollHandler,50));
      window.removeEventListener('resize', resizeHandler);
      portrait.current[0].removeEventListener("change", resizeHandler);

    }

    function resizeHandler(e:Event):void {
      if((e.type === 'resize' && pw.current === document.documentElement.clientWidth)){
        //console.log('r12:', 'pw:', pw.current, 'ww:', document.documentElement.clientWidth);
        ww.current = document.documentElement.clientWidth;
        pw.current = document.documentElement.clientWidth;
        return;
      }

      pw.current = document.documentElement.clientWidth;

      //console.log('resize room wifi videos:');

      if(!isMobile.current){
        ww.current = window.innerWidth;
        wh.current = window.innerHeight;
        //dw.current = document.documentElement.clientWidth;
      
      } else {
        ww.current = window.screen.width;
        wh.current = window.screen.height;
        //dw.current = window.screen.width;
      }    
      
      ww.current = document.documentElement.clientWidth;
      wh.current = document.documentElement.clientHeight;

      stickyElementTop.current = vsRef.current!.getBoundingClientRect().top + window.pageYOffset;

      setScreenWidth(ww.current);
      //console.log('ww:', window.innerWidth);
      window.requestAnimationFrame(setupSingleVideoYValues);
      window.requestAnimationFrame(animateVideos);
    }


    function setupSingleVideoYValues():void {
        const varmch = varmcRef.current!.getBoundingClientRect().height + 70;
        const cw = ww.current;//window.innerWidth;
        //console.log('cw:', cw);
        //calculations for video clip X css variable
        //const clipX = window.innerWidth * .10;
        //console.log('clipX:', clipX);
        //singleVideoClipXEnd.current = singleVideoClipX.current = clipX;

        //calculations for video clip top css variable
        const clipTop = vrhcRef.current!.offsetHeight + vrhcRef.current!.offsetTop;
        //console.log('clipTop:', clipTop);
        singleVideoClipTopEnd.current = singleVideoClipTop.current = clipTop;
        //console.log('bp:', window.innerHeight - clipTop);

        //calculations for intro shift y css variable
        videoRoomIntroShiftYEnd.current = clipTop * .5;
        //console.log('shift-y:', videoRoomIntroShiftYEnd.current);

        //set t1, t2, t3 height adjustments
        sectionDiff.current = (wh.current * .415) / 2;
        //console.log('secDiff:', sectionDiff.current);
        t1Adj.current = Math.ceil(videoRoomIntroShiftYEnd.current / .67);
        t2Adj.current = t1Adj.current + sectionDiff.current/*200*/;
        t3Adj.current = t2Adj.current + sectionDiff.current/*200*/;

        //console.log('t1Adj:', t1Adj.current);
        //console.log('t2Adj:', t2Adj.current);
        //console.log('t3Adj:', t3Adj.current);

        //all room header start point for scrolling
        arhStart.current = /*133 + */(wh.current * .925);
        //console.log('arhStart:', arhStart.current);

        //original version
        //videoRoomIntroShiftYMultiplier.current = videoRoomIntroShiftYEnd.current / 200;
        videoRoomIntroShiftYMultiplier.current = videoRoomIntroShiftYEnd.current / t1Adj.current/*180*/;

        //calculations for header y css variable
        videoRoomHeaderYEnd.current = videoRoomIntroShiftYEnd.current + 70;
        

        //calculations for single video origin and video play for single and all room videos
        if(mql.matches){
          //set when less than 768px
          singleVideoOrigin.current = 75;
          //play desktop size videos for single and all room

          /*
            //if(wsrmRef.current!.readyState === 4 && !vp1.current){
              console.log('passed:', wsrmRef.current!.readyState);
              vp1.current = true;
              
                const pp1 = wsrmRef.current!.play();
                if(pp1 !== undefined){
                  pp1.then((result)=>{
                    console.log('video 1 is playing:', wsrmRef.current!.readyState);
                  })
                  .catch((err)=>{
                    console.error('video 1 mobile did not start', err);
                  });
                }


            //}

            
            //if(warmRef.current!.readyState === 4 && !vp2.current){
              console.log('passed:', warmRef.current!.readyState);
              vp2.current = true;
              
              
                const pp2 = warmRef.current!.play();
                if(pp2 !== undefined){
                  pp2.then((result)=>{
                    console.log('video 2 is playing');
                  })
                  .catch((err)=>{
                    console.error('video 2 mobile did not start', err);
                  });
                }
              


            //}
            */
            
          

        } else {
          //set when greater than or equal to 768px
          singleVideoOrigin.current = 70;
          /*
          //play mobile size videos for single and all room
          const pp1 = wsrdRef.current!.play();
          if(pp1 !== undefined){
            pp1.then((result)=>{
              //console.log('video 1 is playing');
            })
            .catch((err)=>{
              console.error('video 1 desktop did not start', err);
            });
          }

          const pp2 = wardRef.current!.play();
          if(pp2 !== undefined){
            pp2.then((result)=>{
              //console.log('video 2 is playing');
            })
            .catch((err)=>{
              console.error('video 2 desktop did not start', err);
            });
          }

          */



        }
        


        if(cw >= 768){
          //desktop version
          //adjusted height calculations for video child element and select child elements
          //original version
          //const ch = wsrdRef.current!.offsetHeight;
          const ch = wh.current;// window.innerHeight;
          //console.log('ch:', ch);
          //original location for adjusted height calculations
          const rw = ch * 1.5;//aspect ratio for video
          //console.log('rw:', rw);
          let tp = ch * .2655;//distance percentage between top of room and window
          let svy = 0;
          //console.log('tp:', tp);
          //current width must be greater than or equal to 768px
          if(cw > rw){
            const diff = cw - rw;
            //console.log('diff:', diff);
            tp += diff * .1818;//top difference divided by (cw - rw)
            //console.log('adj tp:', tp);
            getSingleYValues(tp, varmch, svy);
            
          } else {
            getSingleYValues(tp, varmch, svy);
          }

          //calculations for clipX css variable
          singleVideoClipXEnd.current = singleVideoClipX.current = cw * .1;

          //calculations for clip bottom css variable
          let abh = 0;
          const arh = Math.round(cw / 1.5);
          if(cw >= 768 && cw <= 1000){
            const sh = 1150;
            let hd = sh - ch;
            hd = hd < 0 ? 0 : hd;
            abh = ch > arh ? hd * .25 : ((sh - arh) * .25) + ((arh - ch) * 1);
          } else if(cw > 1000 && cw <= 1764){
            const sh = 1176;
            let hd = sh - ch;
            hd = hd < 0 ? 0 : hd;
            abh = ch > arh ? hd * .25 : ((sh - arh) * .25) + ((arh - ch) * 1);
          } else if(cw > 1764){
            const sh = arh;
            abh = ch > arh ? 0 : (sh - ch) * 1;
          }

          singleVideoClipBottomEnd.current = singleVideoClipBottom.current = abh;
          //console.log('abh:', abh);

          //adjusted height calculations for child wrapper and elements
          const ah = cw / 1.5;
          const nh = ah > ch ? ah : ch;
          //console.log('nh:', nh);
          //stickyRef.current!.style.height = `${ah > ch ? ah : ch}px`;
          if(cw >= 1764){
            stickyRef.current!.style.height = `${nh}px`;
            vrcRef.current!.style.height = `${nh}px`;
            vsrcRef.current!.style.height = `${nh}px`;
            varcRef.current!.style.height = `${nh}px`;

            const pb = vsRef.current!.getBoundingClientRect().top + window.pageYOffset + vsRef.current!.offsetHeight;
            const cb = vsRef.current!.getBoundingClientRect().top + window.pageYOffset + nh /*+ (window.innerHeight - 699.25)*/ /*stickyRef.current!.offsetHeight*/;
      
            //console.log('pb:', pb, 'cb:', cb, 'diff:', pb - cb, 'td:', pb - cb - t3Adj.current);
            msgsAvailScrollDistance.current = Math.floor(pb - cb - t3Adj.current);
          }

          if(cw < 1764){
            const anh = window.innerHeight + abh;
            //console.log('anh:', anh);
            stickyRef.current!.style.height = `${anh}px`;
            vrcRef.current!.style.height = `${anh}px`;
            vsrcRef.current!.style.height = `${nh}px`;
            varcRef.current!.style.height = `${nh}px`;    
            
            const pb = vsRef.current!.getBoundingClientRect().top + window.pageYOffset + vsRef.current!.offsetHeight;
            const cb = vsRef.current!.getBoundingClientRect().top + window.pageYOffset + anh /*+ (window.innerHeight - 699.25)*/ /*stickyRef.current!.offsetHeight*/;
      
            //console.log('pb:', pb, 'cb:', cb, 'diff:', pb - cb, 'td:', Math.floor(pb - cb - t3Adj.current));
            msgsAvailScrollDistance.current = Math.floor(pb - cb - t3Adj.current);
          }


        } else {
          //mobile version
          //original version
          //const ch = wsrmRef.current!.offsetHeight;
          const ch = wh.current;//window.innerHeight;
          //console.log('ch:', ch);
          const arw = window.parseInt(`${ch * .7667}`);
          //console.log('arw:', arw);
          const sw = (arw + 1);
          let ah = 0;
          //adjusted height calculations for video child element and select child elements
          if(cw > arw && cw < ch && cw > 599){
            ah = ch + 168;
          } else if(cw > arw && cw < ch && cw <= 599 && cw > 516){
            ah = ch + 168 + 24;
          } else if(cw > arw && cw < ch && cw <= 516){
            ah = ch + 168 + 24 + 24;
          } else if(cw <= arw && cw < ch && cw > 599){
            const sh = ch + 168;
            ah = sh - ((sw - cw) * 1.3);
          } else if(cw <= arw && cw < ch && cw <= 599 && cw > 516){
            const sh = ch + 168 + 24;
            ah = sh - ((sw - cw) * 1.3);
          } else if(cw <= arw && cw < ch && cw <= 516 && cw > 375){
            const sh = ch + 168 + 24 + 24;
            ah = sh - ((sw - cw) * 1.3);
          } else if(cw <= arw && cw < ch && cw <= 375){
            const sh = ch + 168 + 24 + 24 + 24;
            ah = sh - ((sw - cw) * 1.3);
          } else if(cw >= ch){
            ah = ch;
          }

          ah = ah > ch ? ah : ch;

          //console.log('ah:', ah, 'ch:', ch);

          //original version
          //const nh = ah > ch ? ah : ch;
          //console.log('ah:', ah);
          stickyRef.current!.style.height = `${ah}px`;
          vrcRef.current!.style.height = `${ah}px`;
          vsrcRef.current!.style.height = `${ah}px`;
          varcRef.current!.style.height = `${ah}px`;

          const pb = vsRef.current!.getBoundingClientRect().top + window.pageYOffset + vsRef.current!.offsetHeight;
          const cb = vsRef.current!.getBoundingClientRect().top + window.pageYOffset + ah /*+ (window.innerHeight - 699.25)*/ /*stickyRef.current!.offsetHeight*/;
    
          //console.log('pb:', pb, 'cb:', cb, 'diff:', pb - cb, 'td:', pb - cb - t3Adj.current);
          msgsAvailScrollDistance.current = Math.floor(pb - cb - t3Adj.current);

          //calculations form --single-video-y css variable
          let svy = 0;
          if(cw < ch){
            svy = -(ch * .08);
          } else if(cw < 730 && cw > 599 && cw > ch){
            svy = ((729 - cw) / 3);
          } else if(cw > 730 && cw > ch){
            svy = 0;
          } else if(cw < 730 && cw <= 516 && cw > ch){
            svy = ((727 - cw) / 3) + 24 + 24;
          } else if(cw < 730 && cw <= 599 && cw > ch){
            svy = ((728 - cw) / 3) + 24;
          }
          //console.log('svy:', svy);
          singleVideoYDecimal.current = svy;
          singleVideoYEnd.current = svy;
          
          //calculations for clipX css variable
          if(cw > 500){
            singleVideoClipXEnd.current = singleVideoClipX.current = cw * .1;
          } else {
            singleVideoClipXEnd.current = singleVideoClipX.current = ((cw / 500) / 10) * cw;
          }
          
          //calculations for clip bottom css variable
          //finish this
          let abh = 0;
          if(cw > arw && cw < ch && cw > 599){
            abh = 168;
          } else if(cw > arw && cw < ch && cw <= 599 && cw > 516){
            abh = 168 + 24;
          } else if(cw > arw && cw < ch && cw <= 516){
            abh = 168 + 24 + 24;
          } else if(cw <= arw && cw < ch && cw > 599){
            const sh = 168;
            abh = sh - ((sw - cw) * 1.3);
          } else if(cw <= arw && cw < ch && cw <= 599 && cw > 516){
            const sh = 168 + 24;
            abh = sh - ((sw - cw) * 1.3);
          } else if(cw <= arw && cw < ch && cw <= 516 && cw > 375){
            const sh = 168 + 24 + 24;
            abh = sh - ((sw - cw) * 1.3);
          } else if(cw <= arw && cw < ch && cw <= 375){
            const sh = 168 + 24 + 24 + 24;
            abh = sh - ((sw - cw) * 1.3);
          } else if(cw >= ch){
            abh = 0;
          }

          abh = abh >= 0 ? abh : 0;

          singleVideoClipBottomEnd.current = singleVideoClipBottom.current = abh;
          //console.log('abh:', abh);

        }

        setupWifiCSSStyles();


        
    }

    function getSingleYValues(tp:number, varmch:number, svy:number):void {
      if(tp < varmch){
        svy = varmch - tp;
        //console.log('svy:', svy);
        singleVideoYDecimal.current = svy;
        singleVideoYEnd.current = svy;
        //(vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${svy}px`);
      } else {
        //console.log('svy:', svy);
        singleVideoYDecimal.current = svy;
        singleVideoYEnd.current = svy;
        //(vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${0}px`);
      }
    }


    function loadMsgs():void {
      //console.log('loadMsgs');
      //update this with code from lines 1361 on down
      //t3-713
      //single room header section
      if(scrollDirection.current === 'down' && sp.current > t3.current && sp.current <= (t3.current + (msgsAvailScrollDistance.current * .062) /*133*/) /*712*/){
        //console.log('sp.current:', sp.current);
        sro.current = (sp.current - (t3.current + 1)) * singleRoomOpacityMultiplier.current;
        //console.log('sro:', sro.current);
        singleRoomY.current = 30 - ((sp.current - (t3.current + 1)) * singleRoomYMultiplier.current);
        //console.log('sry:', singleRoomY.current);
        singleRoomOpacity.current = sro.current / 1000;
        //vsrmcRef.current!.style.opacity = (sro.current / 1000).toString();
        //console.log('opacity:', vsrmcRef.current!.style.opacity);
      }

      //1513-1646
      if(scrollDirection.current === 'down' && sp.current > (t3.current + (msgsAvailScrollDistance.current * .439) /*933*/) /*1512*/ && sp.current <= (t3.current + (msgsAvailScrollDistance.current * .501)/*1066*/) /*1645*/){
        sro.current = 1000 - ((sp.current - (t3.current + (msgsAvailScrollDistance.current * .439)/*934*/)/*1513*/) * singleRoomOpacityMultiplier.current);
        singleRoomY.current = 0;
        singleRoomOpacity.current = sro.current / 1000;
        //vsrmcRef.current!.style.opacity = (sro.current / 1000).toString();
      }
    
      //>1646
      if(scrollDirection.current === 'up' || scrollDirection.current === 'down' && sp.current > (t3.current + (msgsAvailScrollDistance.current * .501)/*1066*/) /*1645*/){
        sro.current = 0;
        singleRoomY.current = 0;
        singleRoomOpacity.current = sro.current / 1000;
        //vsrmcRef.current!.style.opacity = (sro.current / 1000).toString();
      }

      //1513-1646
      if(scrollDirection.current === 'up' && sp.current > (t3.current + (msgsAvailScrollDistance.current * .439)/*933*/) /*1512*/ && sp.current <= (t3.current + (msgsAvailScrollDistance.current * .501)/*1066*/) /*1645*/){
        sro.current = ((msgsAvailScrollDistance.current * .774)/*1645*/ - sp.current) * singleRoomOpacityMultiplier.current;
        singleRoomY.current = 0;
        avo.current = 0;
        aro.current = 0;
        allRoomY.current = 30;
        singleRoomOpacity.current = sro.current / 1000;
        //vsrmcRef.current!.style.opacity = (sro.current / 1000).toString();
        allRoomOpacity.current = aro.current / 1000;
        //varmcRef.current!.style.opacity = (aro.current / 1000).toString();
        allVideoOpacity.current = avo.current / 1000;
      }

      //<1646
      if(scrollDirection.current === 'up' || scrollDirection.current === 'down' && sp.current < (t3.current + (msgsAvailScrollDistance.current * .502)/*1067*/) /*1646*/){
        avo.current = 0;
        aro.current = 0;
        allRoomY.current = 30;
        allRoomOpacity.current = aro.current / 1000;
        //varmcRef.current!.style.opacity = (aro.current / 1000).toString();
        allVideoOpacity.current = avo.current / 1000;
      }
      
      
      //1646-1911
      //all room header section
      if(scrollDirection.current === 'down' && sp.current > (t3.current + (msgsAvailScrollDistance.current * .501)/*1066*/)/*1645*/ && sp.current <= (t3.current + (msgsAvailScrollDistance.current * .626)/*1332*/) /*1911*/){
        //singleRoomOpacity.current = 0;
        sro.current = 0;
        singleRoomY.current = 0;
        singleRoomOpacity.current = sro.current / 1000;
        //vsrmcRef.current!.style.opacity = '0';
        aro.current = (sp.current - (t3.current + 1067) /*1646*/) * allRoomOpacityMultiplier.current;
        allRoomY.current = 30 - ((sp.current - (t3.current + (msgsAvailScrollDistance.current * .502)/*1067*/) /*1646*/) * allRoomYMultiplier.current);
        avo.current = (sp.current - (t3.current + 1067) /*1646*/) * allVideoOpacityMultiplier.current;
        //allRoomY.current = 30 - ((sp.current - 1646) * allRoomYMultiplier.current);
        //aro.current = (sp.current - 1646) * allRoomOpacityMultiplier.current;
        //avo.current = (sp.current - 1646) * allVideoOpacityMultiplier.current;
        allRoomOpacity.current = aro.current / 1000;
        //varmcRef.current!.style.opacity = (aro.current / 1000).toString();
        allVideoOpacity.current = avo.current / 1000;
      }

      //>1911
      if((scrollDirection.current === 'down' || scrollDirection.current === 'up') && sp.current > (t3.current + (msgsAvailScrollDistance.current * .626)/*1332*/) /*1911*/){
        aro.current = 1;
        avo.current = 1;
        allRoomOpacity.current = aro.current;
        //varmcRef.current!.style.opacity = `${aro.current}`;
        allRoomY.current = 0;
        allVideoOpacity.current = avo.current;
        //console.log('aro:', aro.current);
        //console.log('avo:', avo.current);
      }
      

      //1646-1911
      if(scrollDirection.current === 'up' && sp.current > (t3.current + (msgsAvailScrollDistance.current * .501)/*1066*/) /*1645*/ && sp.current <= (t3.current + (msgsAvailScrollDistance.current * .626)/*1332*/) /*1911*/){
          allRoomY.current = (/*1911*/ (t3.current + (msgsAvailScrollDistance.current * .626)/*1332*/) - sp.current) * allRoomYMultiplier.current;
          aro.current = 1000 - ((/*1911*/ (t3.current + (msgsAvailScrollDistance.current * .626)/*1332*/) - sp.current) * allRoomOpacityMultiplier.current);
          avo.current = 1000 - ((/*1911*/ (t3.current + (msgsAvailScrollDistance.current * .626)/*1332*/) - sp.current) * allVideoOpacityMultiplier.current);
          allRoomOpacity.current = aro.current / 1000;
          //varmcRef.current!.style.opacity = (aro.current / 1000).toString();
          allVideoOpacity.current = avo.current / 1000;
          //console.log('ary:', allRoomY.current);
          //console.log('aro:', aro.current);
          //console.log('avo:', avo.current);
      }

      //713-1513
      if((scrollDirection.current === 'down' || scrollDirection.current === 'up') 
        && sp.current > (t3.current + (msgsAvailScrollDistance.current * .062)/*133*/) /*712*/ && sp.current <= (t3.current + (msgsAvailScrollDistance.current * .439)/*933*/) /*1512*/){
        sro.current = 1;
        singleRoomOpacity.current = sro.current;
        //vsrmcRef.current!.style.opacity = `${sro.current}`;
        singleRoomY.current = 0;
        //console.log('yes:', vsrmcRef.current!.style.opacity);
      }

      //<713
      if(scrollDirection.current === 'up' && sp.current > t3.current && sp.current <= (t3.current + (msgsAvailScrollDistance.current * .062)/*133*/) /*712*/){
        //console.log('sp.current:', sp.current);
        sro.current = 1000 - (((t3.current + (msgsAvailScrollDistance.current * .062)/*133*/) /*712*/ - sp.current) * singleRoomOpacityMultiplier.current);
        //console.log('sro:', sro.current);
        singleRoomY.current = (((t3.current + (msgsAvailScrollDistance.current * .062)/*133*/) /*712*/ - sp.current) * singleRoomYMultiplier.current);
        //console.log('sry:', singleRoomY.current);
        singleRoomOpacity.current = sro.current / 1000;
        //vsrmcRef.current!.style.opacity = (sro.current / 1000).toString();
        //console.log('opacity:', vsrmcRef.current!.style.opacity);
      }

      updateCssVariables(true);
    }
    

    function updateCssVariables(showAVO:boolean):void {
      
      (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${singleVideoOrigin.current}%`);
      (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipX.current}px`);
      (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTop.current}px`);
      (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-bottom', `${singleVideoClipBottom.current}px`);
      (vsRef.current as HTMLDivElement).style.setProperty('--video-room-header-y', `-${videoRoomHeaderY.current}px`);
      (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoY.current}px`);
      (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${singleVideoScale.current}`);
      (vsRef.current as HTMLDivElement).style.setProperty('--single-room-y', `${singleRoomY.current}px`);
      (vsRef.current as HTMLDivElement).style.setProperty('--single-room-opacity', `${singleRoomOpacity.current}`);
      (vsRef.current as HTMLDivElement).style.setProperty('--all-room-y', `${allRoomY.current}px`);
      (vsRef.current as HTMLDivElement).style.setProperty('--all-room-opacity', `${allRoomOpacity.current}`);

      if(showAVO) {
        (vsRef.current as HTMLDivElement).style.setProperty('--all-video-opacity', `${allVideoOpacity.current}`);
      }
    }

    function childTransitionEndHandler(e:React.TransitionEvent):void {
      e.nativeEvent.stopPropagation();
      //console.log('child transitionend:', e.target, e.propertyName);
    }

    function transitionEndHandler(e:React.TransitionEvent):void {
      if(e.propertyName === 'transform' && e.target === stickyRef.current && runAnimateState.current){
        //console.log('yes:', 'ras:', runAnimateState.current);
        //if(cancelAnimation.current){return;}
        //console.log('transition:', scrollSpeed.current);
        window.cancelAnimationFrame(raf4.current);
        //original version
        //raf4.current = window.requestAnimationFrame(animateNextState);
        //raf4.current = window.requestAnimationFrame(animateNextStateTest);
        raf4.current = window.requestAnimationFrame(animateVideos);
        
        /*
        if(cancelAnimation.current){
          console.log('raf4');
          window.cancelAnimationFrame(raf4.current);
          ticked.current = false;
        }*/ /*else {
          ticked.current = false;
        }
        */
        
        
        //window.cancelAnimationFrame(raf4.current);
        //original version
        //raf4.current = window.requestAnimationFrame(animateNextState);
      } 

      //ticked.current = false;

      
       
      
    }

    function animateNextStateTest():void {
      if(!runAnimateState.current){
        //console.log('cet finished:', cet.current);
        scrollParam.current = true;
        showMsg.current = true;
        loadMsgs();
        return;
      }

      animateVideos();
      runAnimateState.current = false;
      ticked.current = false;

    }

    function animateNextState():void{
      //console.log('raf4');
      //console.log('switched raf4:', sp.current);
      //console.log('scrollParam:', scrollParam.current);
      //console.log('sp.current:', sp.current);
      //console.log('ans run');
      //console.log('amount before:', amount.current);
      //console.log('runAnimateState:', runAnimateState.current);
      //console.log('pos animate:', sp.current, videoRoomIntroShiftYStart.current);
      
      if(!runAnimateState.current){
        //console.log('cet finished:', cet.current);
        scrollParam.current = true;
        showMsg.current = true;
        loadMsgs();
        return;
      }
      //console.log('section name:', sectionName.current);
      /*if(runSectionT1.current && scrollDirection.current === 'up' && sectionName.current === 't1' && scrollSpeed.current === 'fast'){
        console.log('ans else if up');
        runSectionT1.current = false;
        //window.requestAnimationFrame(()=>{
          (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - (videoRoomIntroShiftYEnd.current * .99)}px`);
          (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current - (singleVideoYEnd.current * .99)}px`);
          (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70 - (20 * .99)}%`);
          (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2 - (1 * .99)}`);
          (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current - (singleVideoClipXEnd.current * .99)}px`);
          (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current - (singleVideoClipTopEnd.current * .99)}px`);
        //});

      } else*/
      //switch this back to less than instead of greater than
      if(amount.current > 10000/*states.current.length*/){
          //console.log('amount after:', amount.current);
          const nextState = states.current[amount.current++];
          //nextState();
          /*
            if(scrollSpeed.current === 'fast'){
              (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current}px`);
              (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70}%`);
              (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2}`);
              (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current}px`);
              (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current}px`);
            }
            */

        
      } else if(amount.current > 10000 && scrollDirection.current === 'down' && (sectionName.current === 'last > 600' || sectionName.current === 't3 > 403') /*&& scrollSpeed.current === 'fast'*/){
        //console.log('ans else if down');
        //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `500ms`);
        amount.current++;
        (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - (videoRoomIntroShiftYEnd.current * .99)}px`);
        (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current - (singleVideoYEnd.current * .01)}px`);
        (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70 - (20 * .99)}%`);
        (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2 - (1 * .99)}`);
        (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current - (singleVideoClipXEnd.current * .99)}px`);
        (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current - (singleVideoClipTopEnd.current * .99)}px`);
        return;
        
          /*
          (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - (videoRoomIntroShiftYEnd.current * .5)}px`);
          (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current - (singleVideoYEnd.current * .5)}px`);
          (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70 - (20 * .5)}%`);
          (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2 - (1 * .5)}`);
          (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current - (singleVideoClipXEnd.current * .5)}px`);
          (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current - (singleVideoClipTopEnd.current * .5)}px`);
          */

        
        switch(amount.current){
          case 0:
            amount.current++;
            (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - (videoRoomIntroShiftYEnd.current * .1)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current - (singleVideoYEnd.current * .9)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70 - (20 * .1)}%`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2 - (1 * .1)}`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current - (singleVideoClipXEnd.current * .1)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current - (singleVideoClipTopEnd.current * .1)}px`);
            console.log('case1');
            break;

          case 1:
            amount.current++;
            (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - (videoRoomIntroShiftYEnd.current * .2)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current - (singleVideoYEnd.current * .8)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70 - (20 * .2)}%`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2 - (1 * .2)}`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current - (singleVideoClipXEnd.current * .2)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current - (singleVideoClipTopEnd.current * .2)}px`);
            console.log('case2');
            break;
            
          case 2:
            amount.current++;
            (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - (videoRoomIntroShiftYEnd.current * .3)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current - (singleVideoYEnd.current * .7)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70 - (20 * .3)}%`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2 - (1 * .3)}`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current - (singleVideoClipXEnd.current * .3)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current - (singleVideoClipTopEnd.current * .3)}px`);
            console.log('case3');
            break;

          case 3:
            amount.current++;
            (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - (videoRoomIntroShiftYEnd.current * .4)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current - (singleVideoYEnd.current * .6)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70 - (20 * .4)}%`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2 - (1 * .4)}`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current - (singleVideoClipXEnd.current * .4)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current - (singleVideoClipTopEnd.current * .4)}px`);
            console.log('case4');
            break;            
            
          case 4:
            amount.current++;
            (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - (videoRoomIntroShiftYEnd.current * .5)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current - (singleVideoYEnd.current * .5)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70 - (20 * .5)}%`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2 - (1 * .5)}`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current - (singleVideoClipXEnd.current * .5)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current - (singleVideoClipTopEnd.current * .5)}px`);
            console.log('case5');
            break;
          case 5:
            amount.current++;
            (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - (videoRoomIntroShiftYEnd.current * .6)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current - (singleVideoYEnd.current * .4)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70 - (20 * .6)}%`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2 - (1 * .6)}`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current - (singleVideoClipXEnd.current * .6)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current - (singleVideoClipTopEnd.current * .6)}px`);
            console.log('case6');
            break;
          case 6:
            amount.current++;
            (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - (videoRoomIntroShiftYEnd.current * .7)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current - (singleVideoYEnd.current * .3)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70 - (20 * .7)}%`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2 - (1 * .7)}`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current - (singleVideoClipXEnd.current * .7)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current - (singleVideoClipTopEnd.current * .7)}px`);
            console.log('case7');
            break;

          case 7:
            amount.current++;
            (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - (videoRoomIntroShiftYEnd.current * .8)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current - (singleVideoYEnd.current * .2)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70 - (20 * .8)}%`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2 - (1 * .8)}`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current - (singleVideoClipXEnd.current * .8)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current - (singleVideoClipTopEnd.current * .8)}px`);
            console.log('case8');
            break;
              
          case 8:
            amount.current++;
            (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - (videoRoomIntroShiftYEnd.current * .9)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-y', `${singleVideoYEnd.current - (singleVideoYEnd.current * .1)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-origin', `${70 - (20 * .9)}%`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-scale', `${2 - (1 * .9)}`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-x', `${singleVideoClipXEnd.current - (singleVideoClipXEnd.current * .9)}px`);
            (vsRef.current as HTMLDivElement).style.setProperty('--single-video-clip-top', `${singleVideoClipTopEnd.current - (singleVideoClipTopEnd.current * .9)}px`);
            console.log('case9');
            break;              
          default:
            throw new Error('no case found');
          
        }
        
        
      } else {
          //console.log('ans else');
          //amount.current = 100;
          //window.cancelAnimationFrame(raf1.current);
          //window.cancelAnimationFrame(raf2.current);
          //window.cancelAnimationFrame(raf3.current);
          //window.cancelAnimationFrame(raf4.current);
          //window.cancelAnimationFrame(raf5.current);

          //console.log('sst:', scrollSpeed.current);
          //original version
          //setScrollSpeedParams(scrollSpeed.current);
          //console.log('ss:', scrollSpeed.current, 'sp:', sp.current, singleVideoScale.current);
          //console.log('animate next', scrollSpeed.current, 'sp:', sp.current, endPoint.current[1]);

          if(scrollSpeed.current === 'fast' && sp.current >= 579 /*&& singleVideoScale.current > 1*/  && scrollDirection.current === 'down'){
            //console.log('passed');
            //breakpoint.current = true;
            //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
            //console.log('speed worked:', scrollSpeed);
            //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `100ms`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(.9,.08,1,.41)`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `linear`);
          } else if(scrollSpeed.current === 'normal' && sp.current >= 579 /*&& singleVideoScale.current > 1*/ && scrollDirection.current === 'down') {
            //breakpoint.current = true;
            //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `linear`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `linear`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(.45,.04,.5,.2)`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(.9,.08,1,.41)`);
          }  else if(scrollSpeed.current === 'normal' && sp.current < 579 /*&& singleVideoScale.current > 1*/ && scrollDirection.current === 'down') {
            //breakpoint.current = true;
            //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `linear`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `linear`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(.45,.04,.5,.2)`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(.9,.08,1,.41)`);
          }   else if(scrollSpeed.current === 'fast' && sp.current < 579 /*&& singleVideoScale.current > 1*/ && scrollDirection.current === 'down') {
            //breakpoint.current = true;
            //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `100ms`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `linear`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `linear`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(.45,.04,.5,.2)`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(.9,.08,1,.41)`);
          }

     
          
          if(sp.current >= stickyElementTop.current && sp.current <= (t1.current)){
            //console.log('fa1:', -videoRoomIntroShiftYRef.current);
            //videoRoomIntroShiftYRef.current = (sp.current - stickyElementTop.current) * .6;
            //console.log('fa2:', -videoRoomIntroShiftYRef.current);
            updateCssVariables(false);
            //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
            sp.current === 0 ? (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `${videoRoomIntroShiftYStart.current}px`)
            : (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYRef.current}px`);
          } else if(sp.current > 179 && sp.current <= 379 && (scrollDirection.current === 'down' || scrollDirection.current === 'up')){
            //runAnimateState.current = false;
            //ticked.current = false;
            //return;
              videoRoomHeaderY.current = ((sp.current - (t1.current)) * videoRoomHeaderYMultiplier.current);
              updateCssVariables(false);
              //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
              //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);
              //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
              //original version
              //(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-120px`);
              (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current}px`);
          } else if(sp.current > 379 && sp.current <= 579 && (scrollDirection.current === 'down' || scrollDirection.current === 'up')){
            //runAnimateState.current = false;
            //ticked.current = false;
            //return;
            //stickyRef.current!.style.transform = `translateY(-${600 - sp.current}px)`;
            //console.log('origin test 403-602:', 70 - (((sp.current) - (t1.current + 2)) * originMultiplier.current));
            //original version
            //videoRoomHeaderY.current = 190;
            //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `100ms`);
            videoRoomHeaderY.current = videoRoomHeaderYEnd.current;
            updateCssVariables(false);
            //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
            (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYRef.current}px`);
          } else if(sp.current > 579){
            //stickyRef.current!.style.transform = `translateY(${sp.current - 601}px)`;
            //console.log('last condition:', sp.current);;
            //console.log('origin test > 602:', 70 - (((sp.current) - (t1.current + 2)) * originMultiplier.current));
            //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `100ms`);
            //console.log('set css var:', (sp.current - (t1.current)) * videoRoomHeaderYMultiplier.current);
            
            //singleVideoY.current = 68.75;
            singleVideoY.current = singleVideoYEnd.current;
            //console.log('svy:', singleVideoY.current);
            singleVideoOrigin.current = 50;
            //original version
            //singleVideoClipX.current = 0;
            singleVideoClipX.current = singleVideoClipXStart.current;
            //original version
            //singleVideoClipTop.current = 0;
            singleVideoClipTop.current = singleVideoClipTopStart.current;
            //original version
            //singleVideoClipBottom.current = 0;
            singleVideoClipBottom.current = singleVideoClipBottomStart.current;
            singleVideoScale.current = 1;
            //original version
            //videoRoomHeaderY.current = 190;
            videoRoomHeaderY.current = videoRoomHeaderYEnd.current;
            
            updateCssVariables(true);
            //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);
            (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `${videoRoomIntroShiftYStart.current}px`);

          }
          
          //scrollParam.current = true;
          runAnimateState.current = false;
          ticked.current = false;
          
      }
  
    }

    function setVideoRoomHeaderText(sw:number):JSX.Element {
      //console.log('sw:', sw);
        if(sw >= 422){
          return <div className="video-room-header-text">
                  Wi‑Fi the way it should be.
                 </div> 
        } else if(sw < 422 && sw >= 297){
          return  <div className="video-room-header-text">
                    Wi‑Fi the way it<br/>should be.
                  </div>
        } else {
          return <div className="video-room-header-text">
                  Wi‑Fi the way<br/>it should be.
                </div>
        }

    }

    function getTotalScrollSpeed(startPoint:number, ePoint:number):void {
      if(startTime.current === 0){
        //startTime.current = window.performance.now();
        startTime.current = startPoint;
      }
      
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout(()=>{
        //console.log('scrolling over');
        const endTime = window.performance.now();
        //console.log('elapsed time:', endTime - startTime.current);
        const end = Math.abs(ePoint);
        const start = Math.abs(startPoint);
        const diff = end > start ? end - start : start - end;
        //console.log('elapsed time:', startTime.current, 'end:', endPoint.current[1]);
        //console.log('sss:', setScrollSpeed(diff));
        //setScrollSpeedParams(setScrollSpeed(diff));
        startTime.current = 0;
      },150);
    }

    function getScrollSpeedDiff(startPoint:number, endPoint:number):number {
      const end = Math.abs(endPoint);
      const start = Math.abs(startPoint);
      const diff = end > start ? end - start : start - end;
      return diff;
    }

    function setScrollSpeed(speedDiff:number):string {
      /*
      if(speedDiff < speedMinimum){
        return 'slow';
      } else if(speedDiff > speedMinimum && speedDiff < speedLimit){
        return 'normal';
      } else {
        return 'fast';
      }
      */
      //console.log('speedDiff:', speedDiff);
      //console.log('distance:', speedDiff, scrollDirection.current);
      speedDistance.current = speedDiff;
      return speedDiff < speedLimit ? 'normal' : 'fast';
    }

    function setScrollSpeedParams(cType:string, cSpeed:string, pType:string, pSpeed:string):void {     
      if(!scrollParam.current){
        //console.log('no run');
        //return;
      }
      
     //console.log('cs:', cSpeed, 'sd:', speedDistance.current);

      if(showMsg.current){
        vsrmcRef.current!.style.transition = 'transform .3s ease-in-out, opacity .1s ease-in-out';
        varmcRef.current!.style.transition = 'transform .3s ease-in-out, opacity .1s ease-in-out';
      }


      if((cSpeed === 'normal' || cSpeed === 'fast') && scrollDirection.current === 'up' ){
        (vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
        (vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `linear`);
        
        stickyRef.current!.style.transition = 'transform .3s linear';
        vrcRef.current!.style.transition = 'clip-path .3s linear';
        vsrcRef.current!.style.transition = 'transform .3s linear, transform-origin .3s linear, opacity .3s linear';
        varcRef.current!.style.transition = 'transform .3s linear, transform-origin .3s linear, opacity .3s linear';
        vrhcRef.current!.style.transition = 'transform .3s ease-in';

        //css clip-path speed
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease-in');
      
        return;
      }


      if(speedDistance.current > t3Adj.current /*579*/ && scrollDirection.current === 'down'){
        stickyRef.current!.style.transition = 'transform .05s linear';
        vrcRef.current!.style.transition = 'clip-path .05s linear';
        vsrcRef.current!.style.transition = 'transform .05s linear, transform-origin .05s linear, opacity .05s linear';
        varcRef.current!.style.transition = 'transform .05s linear, transform-origin .05s linear, opacity .05s linear';
        vrhcRef.current!.style.transition = 'transform .05s ease-in';

        //css clip-path speed
        (vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `50ms`);
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
        return;
      }

      
      if(speedDistance.current < 10 && scrollDirection.current === 'down'){
        stickyRef.current!.style.transition = 'transform .3s linear';
        vrcRef.current!.style.transition = 'clip-path .3s linear';
        vsrcRef.current!.style.transition = 'transform .3s linear, transform-origin .3s linear, opacity .3s linear';
        varcRef.current!.style.transition = 'transform .3s linear, transform-origin .3s linear, opacity .3s linear';
        vrhcRef.current!.style.transition = 'transform .3s ease-in';

        //css clip-path speed
        (vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
        return;
      }

      if((speedDistance.current >= 10 && speedDistance.current < 50) && scrollDirection.current === 'down'){
        stickyRef.current!.style.transition = 'transform .25s linear';
        vrcRef.current!.style.transition = 'clip-path .25s linear';
        vsrcRef.current!.style.transition = 'transform .25s linear, transform-origin .25s linear, opacity .25s linear';
        varcRef.current!.style.transition = 'transform .25s linear, transform-origin .25s linear, opacity .25s linear';
        vrhcRef.current!.style.transition = 'transform .25s ease-in';

        //css clip-path speed
        (vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `250ms`);
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
        return;
      }

      if((speedDistance.current >= 50 && speedDistance.current < 200) && scrollDirection.current === 'down'){
        stickyRef.current!.style.transition = 'transform .2s linear';
        vrcRef.current!.style.transition = 'clip-path .2s linear';
        vsrcRef.current!.style.transition = 'transform .2s linear, transform-origin .2s linear, opacity .2s linear';
        varcRef.current!.style.transition = 'transform .2s linear, transform-origin .2s linear, opacity .2s linear';
        vrhcRef.current!.style.transition = 'transform .2s ease-in';

        //css clip-path speed
        (vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `200ms`);
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
        return;
      }

      /*
      if((speedDistance.current >= 100 && speedDistance.current < 150) && scrollDirection.current === 'down'){
        stickyRef.current!.style.transition = 'transform .15 linear';
        vrcRef.current!.style.transition = 'clip-path .15s linear';
        vsrcRef.current!.style.transition = 'transform .15s linear, transform-origin .15s linear, opacity .15s linear';
        varcRef.current!.style.transition = 'transform .15s linear, transform-origin .15s linear, opacity .15s linear';
        vrhcRef.current!.style.transition = 'transform .15s ease-in';

        //css clip-path speed
        (vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `150ms`);
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
        return;
      }
      */

      if(scrollDirection.current === 'down'){
        stickyRef.current!.style.transition = 'transform .1s linear';
        vrcRef.current!.style.transition = 'clip-path .1s linear';
        vsrcRef.current!.style.transition = 'transform .1s linear, transform-origin .1s linear, opacity .1s linear';
        varcRef.current!.style.transition = 'transform .1s linear, transform-origin .1s linear, opacity .1s linear';
        vrhcRef.current!.style.transition = 'transform .1s ease-in';

        //css clip-path speed
        (vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `100ms`);
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
        return;
      }
      
           
      
      //css clip-path speed
      if(scrollDirection.current ===  'down'){
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
      } else {
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease-in');
      }
    }

    function roomWifiVideosComponentScrollHandler(e:Event):void {
      //console.log('room wifi videos component scroll event');
      sp.current = window.pageYOffset;
      scrollDirection.current = sp.current > psp.current ? 'down' : 'up';
      //console.log('pos:', sp.current, ticked.current, 'set:', stickyElementTop.current);

      if(!ticked.current){
        //console.log('dfff:', sp.current - psp.current);
        window.cancelAnimationFrame(raf0.current);
        raf0.current = window.requestAnimationFrame(animateVideos);
      //}

        //original version
        //ticked.current = true;

        /*
        if(prevScrollDirection.current !== scrollDirection.current){
          //prevScrollDirection.current = scrollDirection.current;
          //console.log('switched direction:', sp.current);
          window.cancelAnimationFrame(raf0.current);
          raf0.current = window.requestAnimationFrame(animateVideos);
        }
        */

        ticked.current = true;
          
        
               
        

      }

      /*
      if(cancelAnimation.current){
        console.log('ca:', cancelAnimation.current);
        window.cancelAnimationFrame(raf0.current);
        cancelAnimation.current = false;
        ticked.current = false;
      }
      */

      prevScrollDirection.current = scrollDirection.current;
      psp.current = sp.current;


    }

    function animateVideos(){

      //calculations for single video origin and video play for single and all room videos
      if(mql.matches){
        //set when less than 768px
        //singleVideoOrigin.current = 75;
        //play desktop size videos for single and all room

          if(wsrmRef.current!.readyState === 4 && !vp1.current){
            //console.log('passed:', wsrmRef.current!.readyState);
            vp1.current = true;
            
              const pp1 = wsrmRef.current!.play();
              if(pp1 !== undefined){
                pp1.then((result)=>{
                  //console.log('video 1 is playing:', wsrmRef.current!.readyState);
                })
                .catch((err)=>{
                  console.error('video 1 mobile did not start', err);
                });
              }


          }

          
          if(warmRef.current!.readyState === 4 && !vp2.current){
            //console.log('passed:', warmRef.current!.readyState);
            vp2.current = true;
            
            
              const pp2 = warmRef.current!.play();
              if(pp2 !== undefined){
                pp2.then((result)=>{
                  //console.log('video 2 is playing');
                })
                .catch((err)=>{
                  console.error('video 2 mobile did not start', err);
                });
              }
            


          }
          
        

      } else {
        //set when greater than or equal to 768px
        //singleVideoOrigin.current = 70;
        //play mobile size videos for single and all room
        if(wsrdRef.current!.readyState === 4 && !vp3.current){
          vp3.current = true;
          const pp1 = wsrdRef.current!.play();
          if(pp1 !== undefined){
            pp1.then((result)=>{
              //console.log('video 1 is playing');
            })
            .catch((err)=>{
              console.error('video 1 desktop did not start', err);
            });
          }
        }

        if(wardRef.current!.readyState === 4 && !vp4.current){
          vp4.current = true;
          const pp2 = wardRef.current!.play();
          if(pp2 !== undefined){
            pp2.then((result)=>{
              //console.log('video 2 is playing');
            })
            .catch((err)=>{
              console.error('video 2 desktop did not start', err);
            });
          }
        }

      }
            
      
      //console.log('avs');
      t1.current = stickyElementTop.current + t1Adj.current /*179*/;//200
      t2.current = stickyElementTop.current + t2Adj.current /*379*/;//400
      t3.current = stickyElementTop.current + t3Adj.current /*579*/;//600
      scaleMultiplier.current = scaleDecimal.current / (((t3.current) - (t1.current)));
      mql.matches ? originMultiplier.current = originMobileAdj.current / (((t3.current) - (t1.current))) : originMultiplier.current = originAdj.current / (((t3.current) - (t1.current)));
      //console.log('om:', originMultiplier);
      //original version
      //clipXMultiplier.current = 123 / (((t3.current + 2) - (t1.current + 2)));
      clipXMultiplier.current = singleVideoClipXEnd.current / (((t3.current) - (t1.current)));
      //console.log('cxm:', clipXMultiplier.current);
      //original version
      //clipTopMultiplier.current = 240 / (((t3.current + 2) - (t1.current + 2)));
      clipTopMultiplier.current = singleVideoClipTopEnd.current / (((t3.current) - (t1.current)));
      //original version
      //clipBottomMultiplier.current = 68.75 / (((t3.current + 2) - (t1.current + 2)));
      clipBottomMultiplier.current = singleVideoClipBottomEnd.current / (((t3.current) - (t1.current)));
      //original version
      //videoRoomHeaderYMultiplier.current = 190 / 200//(((t3 + 2) - (t1 + 2)));
      //original version
      videoRoomHeaderYMultiplier.current = videoRoomHeaderYEnd.current / sectionDiff.current /*200*///(((t3 + 2) - (t1 + 2)));
      singleVideoYMultiplier.current = singleVideoYDecimal.current / (((t3.current) - (t1.current)));
      singleRoomYMultiplier.current = 30 / 132;//((te + 200) - te);
      singleRoomOpacityMultiplier.current = 1000 / 132;//((te + 200) - te);
      //singleVideoOpacityMultiplier.current = 1000 / 200;
      allRoomYMultiplier.current = 30 / 266;//((te + 200) - te);
      allRoomOpacityMultiplier.current = 1000 / 266;//((te + 200) - te);
      allVideoOpacityMultiplier.current = 1000 / 266;
      //console.log('set:', stickyElementTop.current, 't1:', t1.current);
      //console.log('st:', stickyElementTop.current);
      if(sp.current >= stickyElementTop.current && sp.current <= (t1.current) || sp.current < stickyElementTop.current){
        //console.log('t1:', sp.current);
        clearVideoConditions();

        //original version
        //videoRoomIntroShiftYRef.current = (sp.current - stickyElementTop.current) * .6;
        
        //original version
        //videoRoomIntroShiftYRef.current = (sp.current - stickyElementTop.current) * .6;
        //videoRoomIntroShiftYRef.current = ((sp.current - .25) * 3796) * .6;
        //console.log('vris:', videoRoomIntroShiftYRef.current);

        //updateCssVariables(false);

        mql.matches ? singleVideoOrigin.current = 75 : singleVideoOrigin.current = 70;
        
        //original version
        //singleVideoClipX.current = 125;
        singleVideoClipX.current = singleVideoClipXEnd.current;
        
        //original version
        //singleVideoClipTop.current = 240;
        singleVideoClipTop.current = singleVideoClipTopEnd.current;
        
        //original version
        //singleVideoClipBottom.current = 68.75;
        singleVideoClipBottom.current = singleVideoClipBottomEnd.current;
        
        //original version
        //videoRoomHeaderY.current = 0;
        videoRoomHeaderY.current = videoRoomHeaderYStart.current;
        
        //singleVideoY.current = 0;
        singleVideoY.current = singleVideoYStart.current;
        
        singleVideoScale.current = 2;
        
        singleRoomOpacity.current = 0;
        //vsrmcRef.current!.style.opacity = '0';
        sro.current = 0;
        //(vsRef.current as HTMLDivElement).style.setProperty('--single-room-opacity', `${singleRoomOpacity.current}`);
        singleRoomY.current = 30;
        
        allRoomOpacity.current = 0;
        //varmcRef.current!.style.opacity = '0';
        aro.current = 0;
        //(vsRef.current as HTMLDivElement).style.setProperty('--all-room-opacity', `${allRoomOpacity.current}`);
        allRoomY.current = 30;
        //original value for video room header msg
        //vrhcRef.current!.style.opacity = '1';
        
        //(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYRef.current}px`);

          //console.log('dist test:', (sp.current - stickyElementTop.current) );
          //console.log('dist test2:', (sp.current - stickyElementTop.current) * .6);
          if(sp.current > stickyElementTop.current){
            videoRoomIntroShiftYRef.current = (sp.current - stickyElementTop.current) * videoRoomIntroShiftYMultiplier.current;
          //console.log('vr:', videoRoomIntroShiftYRef.current);
          } else {
            videoRoomIntroShiftYRef.current = 0;
          }
          
          
          //original version
          //videoRoomIntroShiftYRef.current = (sp.current - stickyElementTop.current) * .6;
          const ep = sp.current === 0 ? 0 : -sp.current;
          endPoint.current = [ep, sp.current];
          //console.log('startPoint:', startPoint.current);
          //console.log('endPoint:', endPoint.current);
          //console.log('speed t1');
          showMsg.current = false;
          //console.log('vrisy:', window.getComputedStyle((vsRef.current as HTMLDivElement)).getPropertyValue('--video-room-intro-shift-y'));
          //getTotalScrollSpeed(endPoint.current[1], startPoint.current[1]);
          /*original version
          const speedDiff = getScrollSpeedDiff(endPoint.current[1], startPoint.current[1]);
          scrollSpeed.current = setScrollSpeed(speedDiff);
          setScrollSpeedParams(scrollSpeed.current);
          */
          //original version
          //scrollSpeed.current = speedDiff > speedMinimum && speedDiff < speedLimit ? 'normal' : 'fast';
          allVideoOpacity.current = 0;
          updateCssVariables(true);
          if(Math.abs(startPoint.current[0]) !== Math.abs(startPoint.current[1]) 
              && Math.abs(endPoint.current[0]) === Math.abs(endPoint.current[1])
              /*&& scrollDirection.current === 'up'*/){
                //const end = Math.abs(endPoint.current[1]);
                //const start = Math.abs(startPoint.current[1]);
                //const diff = end > start ? end - start : start - end;
            //console.log('first up passed:', sp.current);
            
            //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);
            
            states.current = [
                //()=>box2.style.transform = `translateY(-${Math.abs(startPoint[0])}px)`,
                //()=>stickyRef.current!.style.transform = 'translateY(-119px)'
                //original version
                //()=>(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${119.99}px`)
                ()=>(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - .01}px`)
                //()=>box2.style.transform = `translateY(-${Math.abs(endPoint[0])}px)`
            ];

            const speedDiff = getScrollSpeedDiff(endPoint.current[1], startPoint.current[1]);
            scrollSpeed.current = setScrollSpeed(speedDiff);
            setScrollSpeedParams('sc', scrollSpeed.current, 'svc', prevScrollSpeed.current);

            amount.current = 0;
            //window.cancelAnimationFrame(raf1.current);
            sectionName.current = 't1';
            //console.log('sectionName:', sectionName.current, 'sp:', sp.current, 'sd:', speedDiff);
            runSectionT1.current = true;
            scrollParam.current = false;
            runAnimateState.current = true;
            //console.log('sc:', 'ss:', scrollSpeed.current, 'sp:', sp.current, 'sd:', speedDistance.current);
            //original version
            //raf1.current = window.requestAnimationFrame(animateNextState);
            //ticked.current = false;
            (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current - .01}px`);

            currentInit.current = -(videoRoomIntroShiftYEnd.current - .01);

            //console.log('isy1:', videoRoomIntroShiftYEnd.current - .01);

            //ticked.current = false;

          } else {

            const speedDiff = getScrollSpeedDiff(endPoint.current[1], startPoint.current[1]);
            scrollSpeed.current = setScrollSpeed(speedDiff);
            setScrollSpeedParams('svc', scrollSpeed.current, 'svc', prevScrollSpeed.current);

            //console.log('svc:', 'ss:', scrollSpeed.current, 'sp:', sp.current, 'sd:', speedDistance.current);
            //console.log('t1 after');
            //console.log('vit:', videoRoomIntroShiftYRef.current);
            //const diff = Math.abs(endPoint.current[1]) - Math.abs(startPoint.current[1]);
            //console.log('speed t1 after:', diff);
            runAnimateState.current = false;
            updateCssVariables(false);

            //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
            //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);
            

            sp.current === 0 ? (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `${videoRoomIntroShiftYStart.current}px`)
                    : (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYRef.current}px`);
                    //stickyRef.current!.style.transform = `translateY(-${(sp.current - stickyElementTop.current) * .6}px)`;
            
            currentInit.current = sp.current === 0 ? videoRoomIntroShiftYStart.current : -videoRoomIntroShiftYRef.current;

            ticked.current = false;

            //console.log('isy2:', sp.current === 0 ? videoRoomIntroShiftYStart.current : videoRoomIntroShiftYRef.current);
            
          }


          //psp.current = sp.current;
          scrollZone.current = 't1';              
          //>=180&&<580
      } else if(sp.current > (t1.current) && sp.current <= (t3.current)){
        //console.log('t3:', sp.current);
        //scrollDirection.current = sp.current > psp.current ? 'down' : 'up';
        clearVideoConditions();

        /*original version
        if(sp.current < 403){
          videoRoomIntroShiftYRef.current = 200 * .6;
        }
        */
        //console.log('vrhy:',((sp.current - (t1.current)) * videoRoomHeaderYMultiplier.current));

        const initVideoRoomHeaderY = ((sp.current - (t1.current)) * videoRoomHeaderYMultiplier.current);
        //console.log('room-header-y:', initVideoRoomHeaderY);
        //original version
        //if(initVideoRoomHeaderY <= 190){
        if(initVideoRoomHeaderY <= videoRoomHeaderYEnd.current){
          videoRoomHeaderY.current = initVideoRoomHeaderY;
        }

        if(scrollDirection.current == 'down'){
          /*if(sp.current < 403){
            //videoRoomHeaderY.current = ((sp.current - (t1.current + 2)) * videoRoomHeaderYMultiplier.current);
            console.log('set css var:', videoRoomHeaderY.current);
          }
          */
            sdv2.current = scaleDecimal.current - (((sp.current) - (t1.current))  * scaleMultiplier.current);
            mql.matches ? singleVideoOrigin.current = 75 + (((sp.current) - (t1.current)) * originMultiplier.current) 
                        : singleVideoOrigin.current = 70 - (((sp.current) - (t1.current)) * originMultiplier.current);
            //original version
            //singleVideoClipX.current = 125 - ((sp.current - (t1.current + 2)) * clipXMultiplier.current);
            singleVideoClipX.current = singleVideoClipXEnd.current - ((sp.current - (t1.current)) * clipXMultiplier.current);
            //original version
            //singleVideoClipTop.current = 240 - ((sp.current - (t1.current + 2)) * clipTopMultiplier.current);
            singleVideoClipTop.current = singleVideoClipTopEnd.current - ((sp.current - (t1.current)) * clipTopMultiplier.current);
            //original version
            //singleVideoClipBottom.current = 68.75 - ((sp.current - (t1.current + 2)) * clipBottomMultiplier.current);
            singleVideoClipBottom.current = singleVideoClipBottomEnd.current - ((sp.current - (t1.current)) * clipBottomMultiplier.current);
            //console.log('svcb down:', singleVideoClipBottom.current, 'sp.current:', sp.current);
            singleVideoY.current = (((sp.current) - (t1.current)) * singleVideoYMultiplier.current);
        } else {
          /*if(sp.current < 403){
            //videoRoomHeaderY.current = ((sp.current - (t1.current + 2)) * videoRoomHeaderYMultiplier.current);
          }
          */
            sdv2.current = ((t3.current) - (sp.current)) * scaleMultiplier.current;
            mql.matches ? singleVideoOrigin.current = 100 - (((t3.current) - (sp.current)) * originMultiplier.current) 
                        : singleVideoOrigin.current = 50 + (((t3.current) - (sp.current)) * originMultiplier.current);
            singleVideoClipX.current = (((t3.current) - sp.current) * clipXMultiplier.current);
            singleVideoClipTop.current = (((t3.current) - sp.current) * clipTopMultiplier.current);
            //console.log('svcb up:', singleVideoClipBottom.current);
            singleVideoClipBottom.current = (((t3.current) - sp.current) * clipBottomMultiplier.current);
            //singleVideoY.current = 68.75 - (((t3.current + 2) - (sp.current)) * singleVideoYMultiplier.current);
            singleVideoY.current = singleVideoYEnd.current - (((t3.current) - (sp.current)) * singleVideoYMultiplier.current);
          
        }

        if(sdv2.current >= 100){
          singleVideoScale.current = window.parseFloat('1.' + sdv2.current.toString());
        } else if(sdv2.current >= 10 && sdv2.current < 100){
          singleVideoScale.current = window.parseFloat('1.0' + sdv2.current.toString())
        } else if(sdv2.current < 10){
          singleVideoScale.current = window.parseFloat('1.00' + sdv2.current.toString())
        }


        //updateCssVariables(false);

          //original version hard coded condition
          //if(sp.current <= 379){
            if(sp.current <= t2.current){
            //original version
            //videoRoomIntroShiftYRef.current = 200 * .6;
            videoRoomIntroShiftYRef.current = videoRoomIntroShiftYEnd.current;
            //console.log('vri:', videoRoomIntroShiftYRef.current);
            //insert new code
            const ep = videoRoomIntroShiftYEnd.current /*-120*/;
            endPoint.current = [ep, sp.current];
            //console.log('startPoint:', startPoint.current);
            //console.log('endPoint:', endPoint.current);
            //const diff = Math.abs(endPoint.current[1]) - Math.abs(startPoint.current[1]);
            //console.log('speed t3 < 403:', diff);
            //const speed = diff < 50 ? 'normal' : 'fast';
            //getTotalScrollSpeed(endPoint.current[1], startPoint.current[1]);
            /*original version
            const speedDiff = getScrollSpeedDiff(endPoint.current[1], startPoint.current[1]);
            scrollSpeed.current = setScrollSpeed(speedDiff);
            setScrollSpeedParams(scrollSpeed.current);
            */
            //original version
            //scrollSpeed.current = speedDiff < speedLimit ? 'normal' : 'fast';

            if(Math.abs(startPoint.current[0]) === Math.abs(startPoint.current[1]) 
              && Math.abs(endPoint.current[0]) !== Math.abs(endPoint.current[1])){
              //console.log('down passed <= 379:', sp.current);                   

              //if(speed === 'normal'){
                //updateCssVariables(false);
                //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
                //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
                //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);
                //(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', '-120px');
                  
                  //box2.style.transitionDuration = '300ms';
                  //box2.style.transitionTimingFunction = 'ease';
                  //box2.style.transform = 'translateY(-200px)',
                
                //ticked.current = false;
              //} else {
                //updateCssVariables(false);
                //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
                //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
                //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);
                
                  //box2.style.transitionDuration = '100ms';
                  //box2.style.transitionTimingFunction = 'ease';
                  


                  
                  states.current = [
                      //()=>box2.style.transform = `translateY(-${Math.abs(startPoint[0])}px)`,
                      //original version
                      //()=>(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', '-120px')
                      ()=>(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current}px`)
                      //()=>box2.style.transform = 'translateY(-200px)'
                      //()=>box2.style.transform = `translateY(-${Math.abs(endPoint[0])}px)`
                  ];

                  const speedDiff = getScrollSpeedDiff(endPoint.current[1], startPoint.current[1]);
                  scrollSpeed.current = setScrollSpeed(speedDiff);
                  setScrollSpeedParams('sc', scrollSpeed.current, 'svc', prevScrollSpeed.current);

                  //console.log('sc:', 'ss:', scrollSpeed.current, 'sp:', sp.current, 'sd:', speedDistance.current);

                  amount.current = 0;
                  //window.cancelAnimationFrame(raf2.current);
                  sectionName.current = 't3 < 403';
                  //console.log('sectionName:', sectionName.current, 'sp:', sp.current, 'sd:', speedDiff);
                  scrollParam.current = false;
                  runAnimateState.current = true;
                  //original version
                  //raf2.current = window.requestAnimationFrame(animateNextState);
                  //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `100ms`);
                  (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current}px`);
                  //ticked.current = false;
              //}


                  currentInit.current = -videoRoomIntroShiftYEnd.current;

                  //ticked.current = false;
                  

            } else {

              const speedDiff = getScrollSpeedDiff(endPoint.current[1], startPoint.current[1]);
              scrollSpeed.current = setScrollSpeed(speedDiff);
              setScrollSpeedParams('svc', scrollSpeed.current, 'svc', prevScrollSpeed.current);

              //console.log('svc:', 'ss:', scrollSpeed.current, 'sp:', sp.current, 'sd:', speedDistance.current);

              runAnimateState.current = false;
              updateCssVariables(false);
              //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
              //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
              //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);
              (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYRef.current}px`);
                
                //box2.style.transitionDuration = '300ms';
                //box2.style.transitionTimingFunction = 'ease';
                //box2.style.transform = 'translateY(-200px)',

                currentInit.current = -videoRoomIntroShiftYRef.current;
                
              ticked.current = false;
            }
            


          } else {
            //videoRoomIntroShiftYRef.current = (200 - (sp.current - (t1 + 2))) * .6;
            //original version
            //videoRoomIntroShiftYRef.current = ((t3.current + 2) - sp.current) * .6;
            //original version
            //videoRoomIntroShiftYRef.current = ((t3.current) - sp.current) * videoRoomIntroShiftYMultiplier.current;
            //original version
            //videoRoomIntroShiftYRef.current = (t3.current - sp.current) * (videoRoomIntroShiftYEnd.current/199);
            //console.log('total:', t3.current - sp.current, (t3.current - sp.current) !== 199, (t3.current - sp.current) !== 0);

            //original version
            if(t3.current - sp.current !== sectionDiff.current /*199*/ && t3.current - sp.current !== 0){
              //console.log('first');
              const diff = t3.current - sp.current;
              //console.log('diff:', diff);
              const diffScrolled = sectionDiff.current /*199*/ - diff;
              //console.log('diffScrolled:', diffScrolled);
              const initMultiplier = (diffScrolled * 2)/1000 + (videoRoomIntroShiftYEnd.current / sectionDiff.current /*199*/)/*(.603)*/;
              //console.log('initMultiplier:', initMultiplier);
              videoRoomIntroShiftYRef.current = diff * initMultiplier;
            } else {
              videoRoomIntroShiftYRef.current = (t3.current - sp.current) === 0 
                                                  ? 1 * (videoRoomIntroShiftYEnd.current / sectionDiff.current /*199*/) /*.603*/
                                                  : videoRoomIntroShiftYEnd.current/*199 * .603*/;
                                                  //console.log('second:', t3.current - sp.current);
            }     

            //console.log('vris0:', videoRoomIntroShiftYRef.current);
            

            //console.log('result:', videoRoomIntroShiftYRef.current, 'total:', t3.current - sp.current);
            

            //original version
            //const ep = -(600 - sp.current);
            //original version hard coded
            //const ep = -(579 - sp.current);
            const ep = -(t3.current - sp.current);
            //const ep = -(600 - sp);
            endPoint.current = [ep, sp.current];

            videoRoomHeaderY.current = videoRoomHeaderYEnd.current;
            //console.log('startPoint:', startPoint.current);
            //console.log('endPoint:', endPoint.current);
            //getTotalScrollSpeed(endPoint.current[1], startPoint.current[1]);
            /*original version
            const speedDiff = getScrollSpeedDiff(endPoint.current[1], startPoint.current[1]);
            scrollSpeed.current = setScrollSpeed(speedDiff);
            setScrollSpeedParams(scrollSpeed.current);
            */
            //original version
            //scrollSpeed.current = speedDiff < speedLimit ? 'normal' : 'fast';
            
            if(Math.abs(startPoint.current[0]) === Math.abs(startPoint.current[1]) 
              && Math.abs(endPoint.current[0]) !== Math.abs(endPoint.current[1])){
                //console.log('down passed test > 379:', sp.current);
                //updateCssVariables(false);
                //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
                //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
                //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);

                
                /*
                box2.style.transitionDuration = '100ms';
                box2.style.transitionTimingFunction = 'ease';
                */
                
                states.current = [
                    //()=>box2.style.transform = `translateY(-${Math.abs(startPoint[0])}px)`,
                    //()=>box2.style.transform = 'translateY(-200px)',
                    //original version
                    //()=>(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${120}px`),
                    ()=>(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current}px`)
                    //()=>box2.style.transform = `translateY(-${Math.abs(endPoint[0])}px)`
                ];

                const speedDiff = getScrollSpeedDiff(endPoint.current[1], startPoint.current[1]);
                scrollSpeed.current = setScrollSpeed(speedDiff);
                setScrollSpeedParams('sc', scrollSpeed.current, 'svc', prevScrollSpeed.current);

                //console.log('sc:', 'ss:', scrollSpeed.current, 'sp:', sp.current, 'sd:', speedDistance.current);

                amount.current = 0;
                //window.cancelAnimationFrame(raf3.current);
                sectionName.current = 't3 > 403';
                //console.log('sectionName:', sectionName.current, 'sp:', sp.current, 'sd:', speedDiff);
                scrollParam.current = false;
                runAnimateState.current = true;
                //original version
                //raf3.current = window.requestAnimationFrame(animateNextState);
                //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `100ms`);
                (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current}px`);
                //ticked.current = false;
                //console.log('vris1:', videoRoomIntroShiftYEnd.current);

                currentInit.current = -videoRoomIntroShiftYEnd.current;

                //ticked.current = false;

            } else {

              const speedDiff = getScrollSpeedDiff(endPoint.current[1], startPoint.current[1]);
              scrollSpeed.current = setScrollSpeed(speedDiff);
              setScrollSpeedParams('svc', scrollSpeed.current, 'svc', prevScrollSpeed.current);

              //console.log('svc:', 'ss:', scrollSpeed.current, 'sp:', sp.current, 'sd:', speedDistance.current);
              //console.log('test:', 579 - sp.current);
              runAnimateState.current = false;
              updateCssVariables(false);
              //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
              //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
              //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);
              //(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${600 - sp.current}px`);
              (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYRef.current}px`);
              //console.log('v2:', videoRoomIntroShiftYRef.current);
              //console.log('vris2:', videoRoomIntroShiftYRef.current);
              /*
              box2.style.transitionDuration = '300ms';
              box2.style.transitionTimingFunction = 'ease';
              box2.style.transform = `translateY(-${600 - sp}px)`;
              */
              //c2.style.setProperty('--move-y', `-${400 - sp}px`);
              currentInit.current = -videoRoomIntroShiftYRef.current;
              
              ticked.current = false;
            }
          }


          //psp.current = sp.current;
          scrollZone.current = 't3';
          //ticked.current = false;
      } else {
          //console.log('last:', sp.current, 't3:', t3.current);
          sdv.current = 0;
          sdv2.current = 0;
          //scrollDirection.current = sp.current > psp.current ? 'down' : 'up';
          /*if(scrollDirection.current === 'down' && sp.current > te){
            singleVideoScale.current = 1;
            singleVideoOrigin.current = 50;
            singleVideoClipX.current = 0;
            singleVideoClipTop.current = 0;
            singleVideoClipBottom.current = 0;
            videoRoomHeaderY.current = 190;
            singleVideoY.current = 68.75;
            
          } else */if((scrollDirection.current === 'down' || scrollDirection.current === 'up') && sp.current > (t3.current)){
            //console.log('last test 5-13');
            singleVideoScale.current = 1;
            singleVideoOrigin.current = mql.matches ? 100 : 50;
            //original version
            //singleVideoClipX.current = 0;
            singleVideoClipX.current = singleVideoClipXStart.current;
            //original version
            //singleVideoClipTop.current = 0;
            singleVideoClipTop.current = singleVideoClipTopStart.current;
            //original version
            //singleVideoClipBottom.current = 0;
            singleVideoClipBottom.current = singleVideoClipBottomStart.current;
            //original version
            //videoRoomHeaderY.current = 190;
            videoRoomHeaderY.current = videoRoomHeaderYEnd.current;
            //singleVideoY.current = 68.75;
            singleVideoY.current = singleVideoYEnd.current;
            //original value for video room header msg
            //vrhcRef.current!.style.opacity = '0';
            //console.log('test1');
          } /*else if((scrollDirection.current === 'up' || scrollDirection.current === 'down') && sp.current < (t3.current + 1)){
            //console.log('last svo 5-2:', singleVideoOrigin.current);
            //console.log('last 2 test 5-13');
            videoRoomIntroShiftYRef.current = 0;
            singleVideoScale.current = 2;
            singleVideoOrigin.current = mql.matches ? 75 : 70;
            singleVideoClipX.current = 125;
            singleVideoClipTop.current = 240;
            singleVideoClipBottom.current = 68.75;
            videoRoomHeaderY.current = 0;
            singleVideoY.current = 0;
            //singleRoomOpacity.current = 0;
            vsrmcRef.current!.style.opacity = '0';
            varmcRef.current!.style.opacity = '0';
            sro.current = 0;
            singleRoomY.current = 30;
            //allRoomOpacity.current = 0;
            aro.current = 0;
            allRoomY.current = 30;
            vrhcRef.current!.style.opacity = '1';
            
          } 
          */
          
        if(showMsg.current){
          //original version with raf
          //loadMsgs();
          window.requestAnimationFrame(loadMsgs);
          /*
          //single room header section
          if(scrollDirection.current === 'down' && sp.current > t3.current && sp.current <= 712){
            console.log('sp.current:', sp.current);
            sro.current = (sp.current - (t3.current + 1)) * singleRoomOpacityMultiplier.current;
            console.log('sro:', sro.current);
            singleRoomY.current = 30 - ((sp.current - (t3.current + 1)) * singleRoomYMultiplier.current);
            console.log('sry:', singleRoomY.current);
            //singleRoomOpacity.current = sro.current / 1000;
            vsrmcRef.current!.style.opacity = (sro.current / 1000).toString();
            console.log('opacity:', vsrmcRef.current!.style.opacity);
          }

          if(scrollDirection.current === 'down' && sp.current > 1512 && sp.current <= 1645){
            sro.current = 1000 - ((sp.current - 1513) * singleRoomOpacityMultiplier.current);
            singleRoomY.current = 0;
            //singleRoomOpacity.current = sro.current / 1000;
            vsrmcRef.current!.style.opacity = (sro.current / 1000).toString();
          }
        

          if(scrollDirection.current === 'up' || scrollDirection.current === 'down' && sp.current > 1645){
            sro.current = 0;
            singleRoomY.current = 0;
            vsrmcRef.current!.style.opacity = (sro.current / 1000).toString();
          }

          if(scrollDirection.current === 'up' && sp.current > 1512 && sp.current <= 1645){
            sro.current = (1645 - sp.current) * singleRoomOpacityMultiplier.current;
            singleRoomY.current = 0;
            avo.current = 0;
            aro.current = 0;
            allRoomY.current = 30;
            //singleRoomOpacity.current = sro.current / 1000;
            vsrmcRef.current!.style.opacity = (sro.current / 1000).toString();
            varmcRef.current!.style.opacity = (aro.current / 1000).toString();
            allVideoOpacity.current = avo.current / 1000;
          }

          if(scrollDirection.current === 'up' || scrollDirection.current === 'down' && sp.current < 1646){
            avo.current = 0;
            aro.current = 0;
            allRoomY.current = 30;
            varmcRef.current!.style.opacity = (aro.current / 1000).toString();
            allVideoOpacity.current = avo.current / 1000;
          }
          
          
          //all room header section
          if(scrollDirection.current === 'down' && sp.current > 1645 && sp.current <= 1911){
            //singleRoomOpacity.current = 0;
            singleRoomY.current = 0;
            vsrmcRef.current!.style.opacity = '0';
            aro.current = (sp.current - 1646) * allRoomOpacityMultiplier.current;
            allRoomY.current = 30 - ((sp.current - 1646) * allRoomYMultiplier.current);
            avo.current = (sp.current - 1646) * allVideoOpacityMultiplier.current;
            //allRoomY.current = 30 - ((sp.current - 1646) * allRoomYMultiplier.current);
            //aro.current = (sp.current - 1646) * allRoomOpacityMultiplier.current;
            //avo.current = (sp.current - 1646) * allVideoOpacityMultiplier.current;
            //allRoomOpacity.current = aro.current / 1000;
            varmcRef.current!.style.opacity = (aro.current / 1000).toString();
            allVideoOpacity.current = avo.current / 1000;
          }

          
          if((scrollDirection.current === 'down' || scrollDirection.current === 'up') && sp.current > 1911){
            aro.current = 1;
            avo.current = 1;
            //allRoomOpacity.current = 1;
            varmcRef.current!.style.opacity = `${aro.current}`;
            allRoomY.current = 0;
            allVideoOpacity.current = avo.current;
            console.log('aro:', aro.current);
            console.log('avo:', avo.current);
          }
          

          if(scrollDirection.current === 'up' && sp.current > 1645 && sp.current <= 1911){
              allRoomY.current = (1911 - sp.current) * allRoomYMultiplier.current;
              aro.current = 1000 - ((1911 - sp.current) * allRoomOpacityMultiplier.current);
              avo.current = 1000 - ((1911 - sp.current) * allVideoOpacityMultiplier.current);
              //allRoomOpacity.current = aro.current / 1000;
              varmcRef.current!.style.opacity = (aro.current / 1000).toString();
              allVideoOpacity.current = avo.current / 1000;
              console.log('ary:', allRoomY.current);
              console.log('aro:', aro.current);
              console.log('avo:', avo.current);
          }

          if((scrollDirection.current === 'down' || scrollDirection.current === 'up') 
            && sp.current > 712 && sp.current <= 1512){
            //singleRoomOpacity.current = 1;
            sro.current = 1;
            vsrmcRef.current!.style.opacity = `${sro.current}`;
            singleRoomY.current = 0;
            console.log('yes:', vsrmcRef.current!.style.opacity);
          }

          if(scrollDirection.current === 'up' && sp.current > t3.current && sp.current <= 712){
            console.log('sp.current:', sp.current);
            sro.current = 1000 - ((712 - sp.current) * singleRoomOpacityMultiplier.current);
            console.log('sro:', sro.current);
            singleRoomY.current = ((712 - sp.current) * singleRoomYMultiplier.current);
            console.log('sry:', singleRoomY.current);
            //singleRoomOpacity.current = sro.current / 1000;
            vsrmcRef.current!.style.opacity = (sro.current / 1000).toString();
            console.log('opacity:', vsrmcRef.current!.style.opacity);
          }
          */

        }
          
          //original version fix this
          //updateCssVariables(true);

          //original condition version
          //if(sp.current > 579 && (scrollDirection.current === 'down' || scrollDirection.current === 'up')){
          if(sp.current > t3.current && (scrollDirection.current === 'down' || scrollDirection.current === 'up')){
            //const ep = sp < 200 ? sp : 200;
            //original version
            //const ep = sp.current < 0 ? sp.current - 201 : 0;
            const ep = sp.current < 0 ? sp.current - t1Adj.current /*180*/ : 0;
            endPoint.current = [ep, sp.current];

            videoRoomHeaderY.current = videoRoomHeaderYEnd.current;
            //console.log('direction:', scrollDirection.current);
            //console.log('startPoint:', startPoint.current);
            //console.log('endPoint:', endPoint.current);
            //getTotalScrollSpeed(endPoint.current[1], startPoint.current[1]);
            /*original version
            const speedDiff = getScrollSpeedDiff(endPoint.current[1], startPoint.current[1]);
            scrollSpeed.current = setScrollSpeed(speedDiff);
            setScrollSpeedParams(scrollSpeed.current);
            */
            //original version
            //scrollSpeed.current = speedDiff < speedLimit ? 'normal' : 'fast';
            //updateCssVariables(true);
            if(Math.abs(startPoint.current[0]) === Math.abs(startPoint.current[1]) 
              && Math.abs(endPoint.current[0]) !== Math.abs(endPoint.current[1])){
                //console.log('down passed > 579:', sp.current);
                //const diff = Math.abs(endPoint.current[1]) - Math.abs(startPoint.current[1]);
                //console.log('speed last:', diff);
                //updateCssVariables(true);
                //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
                //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
                //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);

                
                //box2.style.transitionDuration = '100ms';
                //box2.style.transitionTimingFunction = 'ease';
                
                
                states.current = [
                    //()=>box2.style.transform = `translateY(-${Math.abs(startPoint[0])}px)`,
                    //()=>box2.style.transform = 'translateY(-200px)',
                    //original version
                    //()=>(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${120}px`),
                    ()=>(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current}px`)
                    //()=>box2.style.transform = `translateY(-${Math.abs(endPoint[0])}px)`
                ];

                const speedDiff = getScrollSpeedDiff(endPoint.current[1], startPoint.current[1]);
                scrollSpeed.current = setScrollSpeed(speedDiff);
                setScrollSpeedParams('sc', scrollSpeed.current, 'svc', prevScrollSpeed.current);

                //console.log('sc:', 'ss:', scrollSpeed.current, 'sp:', sp.current, 'sd:', speedDistance.current);

                amount.current = 0;
                //window.cancelAnimationFrame(raf5.current);
                sectionName.current = 'last > 600';
                //console.log('sectionName:', sectionName.current, 'sp:', sp.current, 'sd:', speedDiff);
                scrollParam.current = false;
                runAnimateState.current = true;
                //original version
                //raf5.current = window.requestAnimationFrame(animateNextState);
                //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `100ms`);
                (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${videoRoomIntroShiftYEnd.current}px`);
                //ticked.current = false;

                currentInit.current = -videoRoomIntroShiftYEnd.current

                showMsg.current = true;

                //ticked.current = false;

            } else {

              const speedDiff = getScrollSpeedDiff(endPoint.current[1], startPoint.current[1]);
              scrollSpeed.current = setScrollSpeed(speedDiff);
              setScrollSpeedParams('svc', scrollSpeed.current, 'svc', prevScrollSpeed.current);

              //console.log('svc:', 'ss:', scrollSpeed.current, 'sp:', sp.current, 'sd:', speedDistance.current);

              //console.log('test:', sp.current - 580);
              runAnimateState.current = false;
              updateCssVariables(true);
              //(vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
              //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `cubic-bezier(1, .1, .96, .61)`);
              //(vsRef.current as HTMLDivElement).style.setProperty('--tf-shift-y', `ease`);
              //(vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `-${600 - sp.current}px`);
              (vsRef.current as HTMLDivElement).style.setProperty('--video-room-intro-shift-y', `${videoRoomIntroShiftYStart.current}px`);
              
              //box2.style.transitionDuration = '300ms';
              //box2.style.transitionTimingFunction = 'ease';
              //box2.style.transform = `translateY(-${600 - sp}px)`;
              
              //c2.style.setProperty('--move-y', `-${400 - sp}px`);

              currentInit.current = videoRoomIntroShiftYStart.current

              showMsg.current = true;
              
              ticked.current = false;
            }

          }


          //psp.current = sp.current;
          scrollZone.current = 'last';
          //ticked.current = false;
      }

      //console.log('prevInit:', prevInit.current, 'currentInit:', currentInit.current);

      prevInit.current = currentInit.current;
      //original version
      //ticked.current = false;
      //psp.current = sp.current;
      startPoint.current = endPoint.current;
    }

    function clearVideoConditions():void {
      //clear are previous conditions
      aro.current = 0;
      allRoomOpacity.current = 0;
      //varmcRef.current!.style.opacity = '0';
      allRoomY.current = 30;
      avo.current = 0;
      allVideoOpacity.current = 0;
      sro.current = 0;
      singleRoomY.current = 30
      singleRoomOpacity.current = 0;
      //vsrmcRef.current!.style.opacity = '0';
    }

    function setupWifiCSSStyles():void {
      
      //console.log('swcs:');
      const vs = vsRef.current as HTMLDivElement;
      //vs.style.setProperty('--single-video-y', `${singleVideoY}px`);
      vs.style.setProperty('--video-room-intro-shift-y', `${videoRoomIntroShiftYRef.current}px`);
      
      vs.style.setProperty('--single-video-origin', `${singleVideoOrigin.current}%`);
      vs.style.setProperty('--single-video-clip-x', `${singleVideoClipX.current}px`);
      vs.style.setProperty('--single-video-clip-top', `${singleVideoClipTop.current}px`);
      vs.style.setProperty('--single-video-clip-bottom', `${singleVideoClipBottom.current}px`);
      vs.style.setProperty('--video-room-header-y', `-${videoRoomHeaderY.current}px`);
      vs.style.setProperty('--single-room-y', `${singleRoomY.current}px`);
      vs.style.setProperty('--single-room-opacity', `${singleRoomOpacity.current}`);
      vs.style.setProperty('--single-video-opacity', `${singleVideoOpacity.current}`);
      vs.style.setProperty('--all-room-y', `${allRoomY.current}px`);
      vs.style.setProperty('--all-room-opacity', `${allRoomOpacity.current}`);
      vs.style.setProperty('--all-video-opacity', `${allVideoOpacity.current}`);
      vs.style.setProperty('--single-video-y', `${singleVideoY.current}px`);
      vs.style.setProperty('--single-video-scale', `${singleVideoScale.current}`);
    }


    return (
        <div ref={vsRef} className="video-parent-wrapper">
        <div ref={stickyRef} onTransitionEnd={transitionEndHandler} className="video-child-wrapper">
          <div ref={vrcRef} className="video-rooms-container">
            <div ref={vsrcRef} className="video-single-room-container">
              <div className="video-single-room-desktop-container">
                <video ref={wsrdRef} className="video-room-shape" muted={true} autoPlay playsInline loop>
                  <source src="https://server.kando-proto-2.com/single-room-wifi-desktop.mp4" type="video/mp4"/>
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
              <div className="video-single-room-mobile-container">
                <video ref={wsrmRef} className="video-room-shape" muted={true} autoPlay playsInline loop>
                  <source src="https://server.kando-proto-2.com/single-room-wifi-mobile.mp4" type="video/mp4"/>
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            </div>
            <div ref={varcRef} className="video-all-room-container">
              <div className="video-all-room-desktop-container">
                <video ref={wardRef} className="video-room-shape" muted={true} autoPlay playsInline loop>
                  <source src="https://server.kando-proto-2.com/all-room-wifi-desktop.mp4" type="video/mp4"/>
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
              <div className="video-all-room-mobile-container">
                <video ref={warmRef} className="video-room-shape" muted={true} autoPlay playsInline loop>
                  <source src="https://server.kando-proto-2.com/all-room-wifi-mobile.mp4" type="video/mp4"/>
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            </div>
          </div>
          <div ref={vrhcRef} className="video-room-header-container">
            {setVideoRoomHeaderText(screenWidth)}
          </div>
          <div className="video-room-msg-wrapper">
            <div className="video-room-spacer">
              <div ref={vsrmcRef} className="video-single-room-msg-container">
                <div className="video-single-room-msg-title">
                  Traditional, single router
                </div>
                <div className="video-single-room-msg-overview">
                  A traditional, single router has relatively limited coverage. It becomes spotty the farther away you are. Extenders can add a bit more range, but they degrade performance.
                </div>
              </div>
              <div ref={varmcRef} className="video-all-room-msg-container">
                <div className="video-all-room-msg-title">
                  Nest Wifi
                </div>
                <div className="video-all-room-msg-overview">
                  Nest Wifi is a scalable system that gives both whole-home coverage and a consistently strong signal.
                  <sup>1</sup>
                  Each Nest Wifi point is also a smart speaker with the Google Assistant.
                  <sup>2</sup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
});

export default RoomWifiVideosComponent;
import React, {useRef, useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import {Link} from '@reach/router';
import throttle from 'lodash.throttle';

import './VideoDevicesColorsComponent.css';

interface Props {}
type Ref = {
  vdccRef:React.RefObject<HTMLDivElement>,
  vdcmcRef:React.RefObject<HTMLDivElement>,
  vdcbcRef:React.RefObject<HTMLDivElement>,
  vdcRef:React.RefObject<HTMLDivElement>,
  vdRef:React.RefObject<HTMLVideoElement>
};

//const VideoDevicesColorsComponent:React.FunctionComponent<Props> = (props:Props)=>{

const VideoDevicesColorsComponent = forwardRef<Ref, Props>((props, ref)=>{

  const vdccRef = useRef<HTMLDivElement>(null);
  const vdcmcRef = useRef<HTMLDivElement>(null);
  const vdcbcRef = useRef<HTMLDivElement>(null);
  const vdcRef = useRef<HTMLDivElement>(null);
  const vdRef = useRef<HTMLVideoElement>(null);
  const videoDevicesColorsTargetHeight = useRef<number>(0);
  const videoDevicesColorsInitallyPlayed = useRef<boolean>(false);
  const ticked = useRef<boolean>(false);
  const sp = useRef<number>(0);
  const psp = useRef<number>(0);

  const portrait = useRef<MediaQueryList[]>([]);

  const pw = useRef<number>(0);

  useImperativeHandle(ref, ()=>({
      
      get vdccRef(){
        return vdccRef;
      },
      get vdcmcRef(){
        return vdcmcRef;
      },
      get vdcbcRef(){
        return vdcbcRef;
      },
      get vdcRef(){
        return vdcRef;
      },
      get vdRef(){
        return vdRef;
      }
    
    })
  );

  useEffect(()=>{
    //finish testing scroll event listener with each component
    //need to create a new scroll handler for each component
    //window.removeEventListener('scroll', throttle(appScrollHandler,100));
    videoDevicesColorsTargetHeight.current = vdccRef.current!.getBoundingClientRect().top + vdccRef.current!.scrollHeight + window.pageYOffset;
    //console.log('videoDevicesColorsTargetHeight:', videoDevicesColorsTargetHeight.current, vdccRef.current!.scrollHeight, vdccRef.current!.getBoundingClientRect().top, window.pageYOffset);

    if(window.pageYOffset <= videoDevicesColorsTargetHeight.current){
      (vdcmcRef.current as HTMLDivElement).classList.add('slide-up-video-devices-colors-msg-container');
      (vdcbcRef.current as HTMLDivElement).classList.add('slide-up-video-devices-colors-btn-container');
      const playPromise =  vdRef.current!.play();
      /*
      window.setTimeout(()=>{
        console.log('ct:', vdRef.current!.currentTime);
        console.log('pause:', vdRef.current!.paused);
        if(vdRef.current!.paused){
          vdRef.current!.currentTime = 0;
          //vdRef.current!.play();
        }
      },7000);
      */
    }

    
    videoDevicesColorsInitallyPlayed.current = true;
    
    //previous version
    //window.addEventListener('scroll', throttle(videoDevicesColorComponentScrollHandler, 100));
    window.addEventListener('scroll', videoDevicesColorComponentScrollHandler);
    window.addEventListener('resize', videoDevicesColorsComponentResizeHandler);

    portrait.current[0] = window.matchMedia("(orientation: portrait)");
    portrait.current[0].addEventListener("change", videoDevicesColorsComponentResizeHandler);

    return cleanUpScrollHandler;

  },[]);

  function videoDevicesColorsComponentResizeHandler(e:Event):void {
    if((e.type === 'resize' && pw.current === document.documentElement.clientWidth)){
      //console.log('r12:', 'pw:', pw.current, 'ww:', document.documentElement.clientWidth);
      pw.current = document.documentElement.clientWidth;
      return;
    }

    pw.current = document.documentElement.clientWidth;

    //console.log('vdcc resize');
    videoDevicesColorsTargetHeight.current = vdccRef.current!.getBoundingClientRect().top + vdccRef.current!.scrollHeight + sp.current;
    //console.log('videoDevicesColorsTargetHeight:', videoDevicesColorsTargetHeight.current);
    //console.log('videoDevicesColorsInitallyPlayed.current:', videoDevicesColorsInitallyPlayed.current);
  }

  function cleanUpScrollHandler():void {
    //previous version
    //window.removeEventListener('scroll', throttle(videoDevicesColorComponentScrollHandler,100));
    window.removeEventListener('scroll', videoDevicesColorComponentScrollHandler);
    window.removeEventListener('resize', videoDevicesColorsComponentResizeHandler);

    portrait.current[0].removeEventListener("change", videoDevicesColorsComponentResizeHandler);
  }

  function videoDevicesColorComponentScrollHandler(e:Event):void {
    //e.stopImmediatePropagation();
    sp.current = window.pageYOffset;
    //console.log('sp.current:', sp.current, ticked.current);
    //console.log('video devices colors component scroll event');
    if(!ticked.current){
      window.requestAnimationFrame(()=>{
        
        if(sp.current > videoDevicesColorsTargetHeight.current){
          (vdcmcRef.current as HTMLDivElement).classList.remove('slide-up-video-devices-colors-msg-container');
          (vdcbcRef.current as HTMLDivElement).classList.remove('slide-up-video-devices-colors-btn-container');
          if(vdRef.current!.paused){
            vdRef.current!.currentTime = 0;
          } else {
            vdRef.current!.pause();
            vdRef.current!.currentTime = 0;
          }
          videoDevicesColorsInitallyPlayed.current = false;
          //console.log('scroll pause');
        }

        if(!videoDevicesColorsInitallyPlayed.current && sp.current < videoDevicesColorsTargetHeight.current){
          (vdcmcRef.current as HTMLDivElement).classList.add('slide-up-video-devices-colors-msg-container');
          (vdcbcRef.current as HTMLDivElement).classList.add('slide-up-video-devices-colors-btn-container');
          //if(vdRef.current!.paused){
            vdRef.current!.play();
          //}
          //vdRef.current!.currentTime = 0;
          //vdRef.current!.play();
          videoDevicesColorsInitallyPlayed.current = true;
          //console.log('scroll play');
        }
        
        ticked.current = false;
      });

      ticked.current = true;
    }

    //original version
    //ticked.current = true;
  }

  function changeVideoDevicesColorBtn(e:React.MouseEvent):void {
    //console.log('target:', e);
    if(e.type === 'mouseenter'){
      (e.target as HTMLSpanElement).classList.add('change-video-devices-colors-btn');
    } else {
      (e.target as HTMLSpanElement).classList.remove('change-video-devices-colors-btn');
    }
    
  }


    return (
        <div ref={vdccRef} className="video-devices-colors-container">
        <div className="video-devices-colors-msg-container-wrapper">
          <div ref={vdcmcRef} className="video-devices-colors-msg-container">
            <span className="video-devices-colors-msg">
              Meet the new Nest Wifi.
              <br/>
              Strong connection. Every direction.
            </span>
          </div>
          <div ref={vdcbcRef} className="video-devices-colors-btn-container">
            <Link onMouseEnter={changeVideoDevicesColorBtn}
               onMouseLeave={changeVideoDevicesColorBtn} 
               className="video-devices-colors-btn-link" to="/">
              <span className="video-devices-colors-btn">Buy</span>
            </Link>
          </div>
        </div>
        <div ref={vdcRef} className="video-devices-colors-video-container">
          <video ref={vdRef} /*onLoadedData={()=>console.log('wifi devices video loaded')}*/ className="video-devices-colors-video" muted={true} playsInline>
            <source src="https://server.kando-proto-2.com/wifi-devices.mp4" type="video/mp4"/>
          </video>
          <svg viewBox="0 0 2200 900" className="video-devices-colors-svg"></svg>
        </div>
      </div>
    );
});

export default VideoDevicesColorsComponent;

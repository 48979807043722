import React, {useRef, useEffect, useState, forwardRef} from 'react';
import './NestWifiNav.css';

interface Props{
  getNestWifiNavRef:(nwnr:HTMLDivElement)=>void;
}
//type Ref = HTMLDivElement;


const NestWifiNav:React.FunctionComponent<Props> = (props:Props)=>{
//const NestWifiNav = forwardRef<Ref, Props> ((props, nestWifiNavRef)=>{
    const navBtnOptionDesktopRefs = useRef<HTMLButtonElement[]>([]);
    const navBtnOptionMobileRefs = useRef<HTMLLIElement[]>([]);
    const navBtnOptions = ['Overview', 'Tech Specs', 'How it works', 'Privacy', 'Compare'];
    const [nestWifiMobileOptionSelected, setNestWifiMobileOptionSelected] = useState('Overview');
    const nestWifiNavOptionsListMobileRef = useRef<HTMLUListElement>(null);
    const nestWifiNavOptionsDesktopRef = useRef<HTMLDivElement>(null);
    const nestWifiNavOptionsMobileRef = useRef<HTMLDivElement>(null);
    const nestWifiNavOptionsSelectedItemMobileContainerRef = useRef<HTMLDivElement>(null);
    const nestWifiNavOptionsSelectedItemTextMobileRef = useRef<HTMLSpanElement>(null);
    const nestWifiNavOptionSelectedItemMobileRef = useRef<HTMLButtonElement>(null);
    const [mobileBtnCenterPos, setMobileBtnCenterPos] = useState(0);
    //const [mobileBtnClickPos, setMobileBtnClickPos] = useState('none');
    const nestWifiNavOptionsSelectedItemCoverRef = useRef<HTMLDivElement>(null);
    const [btnHiglightRemoved, setBtnHighlightRemoved] = useState(true);
    const ticked = useRef<boolean>(false);
    const sp = useRef<number>(0);
    const nestWifiNavRef = useRef<HTMLDivElement>(null);
    const nestWifiNavTop = useRef<number>(0);

    const portrait = useRef<MediaQueryList[]>([]);

    const pw = useRef<number>(0);


    useEffect(()=>{
      //console.log('nest');

      props.getNestWifiNavRef(nestWifiNavRef.current!);
      //original version
      //nestWifiNavTop.current = 56;//  nestWifiNavRef.current!.getBoundingClientRect().top;
      nestWifiNavTop.current = nestWifiNavRef.current!.getBoundingClientRect().top + window.pageYOffset;
      //console.log('nwnt:', nestWifiNavRef.current!.getBoundingClientRect().top + window.pageYOffset);

      setMobileBtnMeasurements();
      updateNavOptionsTopPosition();

      document.addEventListener('mousedown', documentClickHandler);
      window.addEventListener('resize', resizeNestWifiNavHandler);
      window.addEventListener('scroll', nestWifiNavScrollHandler);

      portrait.current[0] = window.matchMedia("(orientation: portrait)");
      portrait.current[0].addEventListener("change", resizeNestWifiNavHandler);

      return cleanupEventHandler;
    },[]);

    function updateNavOptionsTopPosition():void {
      const modtp = nestWifiNavOptionsSelectedItemMobileContainerRef.current!.offsetTop + nestWifiNavOptionsSelectedItemMobileContainerRef.current!.offsetHeight;
      //console.log('modtp:', modtp);
      nestWifiNavOptionsListMobileRef.current!.style.top = `${modtp}px`;
    }

    function resizeNestWifiNavHandler(e:Event):void {
      //console.log('resize nest wifi nav:');
      if((e.type === 'resize' && pw.current === document.documentElement.clientWidth)){
        //console.log('r12:', 'pw:', pw.current, 'ww:', document.documentElement.clientWidth);
        pw.current = document.documentElement.clientWidth;
        return;
      }
  
      pw.current = document.documentElement.clientWidth;


      if(!nestWifiNavOptionsListMobileRef.current!.classList.contains('hide-nest-wifi-nav-options')
        && window.matchMedia('(min-width:960px)').matches){
          //finish add the manual call to mouseup with the follow object below
          const mdEvent = new Event('mousedown');
          const result1 = document.dispatchEvent(mdEvent);
        }

        //need to fix the top position when switch mobile from portrait to landscape view
      updateNavOptionsTopPosition();
      setMobileBtnMeasurements();

    }

    function nestWifiNavScrollHandler(e:Event):void {
      sp.current = window.pageYOffset;

      if(!ticked.current){
        window.requestAnimationFrame(()=>{

        //set box shadow on nest wifi navbar
        if(sp.current >= (nestWifiNavTop.current)){
          nestWifiNavRef.current!.classList.add('shadow');
        } else if(sp.current < (nestWifiNavTop.current)){
          nestWifiNavRef.current!.classList.remove('shadow');
        }

          ticked.current = false;
        });
      }

      ticked.current = true;

    }

    function documentClickHandler(e:Event):void {
      //console.log('nest wifi nav document click event');
      //e.stopImmediatePropagation();
      if(!nestWifiNavOptionsListMobileRef.current!.classList.contains('hide-nest-wifi-nav-options')){
        //console.log('passed');
        nestWifiNavOptionsListMobileRef.current!.classList.add('hide-nest-wifi-nav-options');
        nestWifiNavOptionSelectedItemMobileRef.current!.classList.remove('mobile-option-item-clicked');
        nestWifiNavOptionsSelectedItemTextMobileRef.current!.classList.remove('mobile-option-item-text-clicked');
      }

      window.requestAnimationFrame((time)=>{
        nestWifiNavOptionSelectedItemMobileRef.current!.style.border = '1px solid transparent';
        nestWifiNavOptionsSelectedItemTextMobileRef.current!.style.color = '#423F3F';
        nestWifiNavOptionSelectedItemMobileRef.current!.style.backgroundColor = '';
        //nestWifiNavOptionSelectedItemMobileRef.current!.style.backgroundColor = 'transparent';
        nestWifiNavOptionsSelectedItemCoverRef.current!.style.backgroundColor = 'transparent';
        //nestWifiNavOptionsSelectedItemCoverRef.current!.classList.remove('ripple-right');
        nestWifiNavOptionsSelectedItemCoverRef.current!.className = 'nest-wifi-nav-options-selected-item-cover';
      });

      setBtnHighlightRemoved(true);
    }

    function cleanupEventHandler():void {
      document.removeEventListener('mousedown', documentClickHandler);
      window.removeEventListener('resize', resizeNestWifiNavHandler);
      window.removeEventListener('scroll', nestWifiNavScrollHandler);

      portrait.current[0].removeEventListener("change", resizeNestWifiNavHandler);

    }

    function setMobileBtnMeasurements():void {
      const btnLeftPos = nestWifiNavOptionSelectedItemMobileRef.current!.getBoundingClientRect().left;
      const btnRightPos = nestWifiNavOptionSelectedItemMobileRef.current!.getBoundingClientRect().right;
      const mobileBtnCenterPos = ((btnRightPos - btnLeftPos) / 2) + btnLeftPos;
      nestWifiNavOptionSelectedItemMobileRef.current!.style.setProperty('--ripple-both', `${btnRightPos - btnLeftPos}`);
      setMobileBtnCenterPos(mobileBtnCenterPos);
    }

    function changeNavOptionBtnBackgroundDesktop(e:React.MouseEvent):void {
        navBtnOptionDesktopRefs.current.map((nboRef)=>{
          return nboRef !== e.target ? nboRef.classList.remove('change-nest-wifi-nav-option-btn-background')
                                    : nboRef.classList.add('change-nest-wifi-nav-option-btn-background');
        });
    }

    function changeNavOptionBtnBackgroundMobile(e:React.MouseEvent, navOption:string):void {
      e.stopPropagation();
      //console.log('rt:', e.currentTarget);
      //console.log('t:', e.target as HTMLLIElement);
      navBtnOptionMobileRefs.current.map((nboRef)=>{
        //console.log('target.related === nboRef:', e.currentTarget === nboRef);
        return nboRef !== e.currentTarget ? nboRef.classList.remove('change-nest-wifi-nav-option-item-container-background-mobile')
                                  : nboRef.classList.add('change-nest-wifi-nav-option-item-container-background-mobile');
      });
      nestWifiNavOptionsListMobileRef.current!.classList.add('hide-nest-wifi-nav-options');
      setNestWifiMobileOptionSelected(navOption);
  }

    function addNavBtnOptionDesktopRefs(el:HTMLButtonElement):void {
        function elementExists(ele:HTMLButtonElement):boolean {
            return ele === el;
          }
          
          if(el !== null && navBtnOptionDesktopRefs.current.find(elementExists) === undefined){
            navBtnOptionDesktopRefs.current.push(el);
          }
    }

    function addNavBtnOptionMobileRefs(el:HTMLLIElement):void {
      function elementExists(ele:HTMLLIElement):boolean {
          return ele === el;
        }
        
        if(el !== null && navBtnOptionMobileRefs.current.find(elementExists) === undefined){
          navBtnOptionMobileRefs.current.push(el);
        }
    }

    function setupNestWifiNavOptionBtnsDesktop():JSX.Element {
        return <div ref={nestWifiNavOptionsDesktopRef} className="nest-wifi-nav-options-desktop-container">
                {
                  navBtnOptions.map((nbo, i) => {
                    if(i === 0){
                      return <div key={i} className="nest-wifi-nav-option">
                              <button ref={addNavBtnOptionDesktopRefs} onClick={changeNavOptionBtnBackgroundDesktop} className="nest-wfif-nav-option-btn change-nest-wifi-nav-option-btn-background" type="button">{nbo}</button>
                            </div>                 
                    } else {
                      return <div key={i} className="nest-wifi-nav-option">
                              <button ref={addNavBtnOptionDesktopRefs} onClick={changeNavOptionBtnBackgroundDesktop} className="nest-wfif-nav-option-btn" type="button">{nbo}</button>
                            </div>
                    }
    
                  })
                }
              </div>
  }

  function showNestWifiMobileOptions(e:React.MouseEvent):void {
    //console.log('show nest wifi mobile opts e:', e.clientX);
    e.stopPropagation();
    if(e.type === 'mouseup'){
      //console.log('mouseup');
      
      window.requestAnimationFrame((time)=>{
        //original styles for main button
        nestWifiNavOptionSelectedItemMobileRef.current!.style.border = '1px solid rgb(59, 59, 247)';
        //nestWifiNavOptionSelectedItemMobileRef.current!.style.backgroundColor = 'rgb(250, 248, 248)';
        //nestWifiNavOptionSelectedItemMobileRef.current!.style.border = '0';
        //nestWifiNavOptionsSelectedItemCoverRef.current!.style.backgroundColor = 'rgba(250, 248, 248, .12)';  
      });

      if(!nestWifiNavOptionsListMobileRef.current!.classList.contains('hide-nest-wifi-nav-options')){
        nestWifiNavOptionsListMobileRef.current!.classList.add('hide-nest-wifi-nav-options');
        (e.currentTarget as HTMLButtonElement).classList.remove('mobile-option-item-clicked');
        nestWifiNavOptionsSelectedItemTextMobileRef.current!.classList.remove('mobile-option-item-text-clicked');
      } else {
        nestWifiNavOptionsListMobileRef.current!.classList.remove('hide-nest-wifi-nav-options');
        (e.currentTarget as HTMLButtonElement).classList.add('mobile-option-item-clicked');
        nestWifiNavOptionsSelectedItemTextMobileRef.current!.classList.add('mobile-option-item-text-clicked');
      }

      
    } else {
      //console.log('mousedown:', mobileBtnCenterPos);
      if(e.clientX < (mobileBtnCenterPos - 20)){
        //console.log('left');
        //original version
        //nestWifiNavOptionSelectedItemMobileRef.current!.style.border = '1px solid transparent';
        nestWifiNavOptionSelectedItemMobileRef.current!.style.border = '1px solid rgb(214, 230, 253)';
        nestWifiNavOptionsSelectedItemCoverRef.current!.style.backgroundColor = '';
        nestWifiNavOptionsSelectedItemCoverRef.current!.className = 'nest-wifi-nav-options-selected-item-cover';
        window.requestAnimationFrame((time)=>{
          window.requestAnimationFrame((time)=>{
            nestWifiNavOptionsSelectedItemCoverRef.current!.classList.add('ripple-right');
          });
        });

        //setMobileBtnClickPos('left');
      
      } else if(e.clientX > (mobileBtnCenterPos + 20)){
        nestWifiNavOptionSelectedItemMobileRef.current!.style.border = '1px solid transparent';
        nestWifiNavOptionsSelectedItemCoverRef.current!.style.backgroundColor = '';
        nestWifiNavOptionsSelectedItemCoverRef.current!.className = 'nest-wifi-nav-options-selected-item-cover';
        window.requestAnimationFrame((time)=>{
          window.requestAnimationFrame((time)=>{
            nestWifiNavOptionsSelectedItemCoverRef.current!.classList.add('ripple-left');
          });
        });

        //setMobileBtnClickPos('right');
      
      } else {
        nestWifiNavOptionSelectedItemMobileRef.current!.style.border = '1px solid transparent';
        nestWifiNavOptionsSelectedItemCoverRef.current!.style.backgroundColor = '';
        nestWifiNavOptionsSelectedItemCoverRef.current!.className = 'nest-wifi-nav-options-selected-item-cover';
        window.requestAnimationFrame((time)=>{
          window.requestAnimationFrame((time)=>{
            nestWifiNavOptionsSelectedItemCoverRef.current!.classList.add('ripple-both');
          });
        });

        //setMobileBtnClickPos('center');
      }
    }

    setBtnHighlightRemoved(false);

  }

  function showBtnHighlight(e:React.MouseEvent):void {
    if(e.type === 'mouseenter' && btnHiglightRemoved){
      nestWifiNavOptionSelectedItemMobileRef.current!.style.backgroundColor = '#f0f5fc';
      nestWifiNavOptionsSelectedItemTextMobileRef.current!.style.color = '#1a73e8';
    }

    if(e.type === 'mouseleave' && btnHiglightRemoved){
      nestWifiNavOptionSelectedItemMobileRef.current!.style.backgroundColor = '';
      nestWifiNavOptionsSelectedItemTextMobileRef.current!.style.color = '#423F3F';
    }
  }

  function animationEndHandler(e:React.AnimationEvent):void {
    //console.log('animation end');
    //nestWifiNavOptionsSelectedItemCoverRef.current!.style.opacity = '0!important';
    //nestWifiNavOptionsSelectedItemCoverRef.current!.style.backgroundColor = 'red';
    //nestWifiNavOptionSelectedItemMobileRef.current!.style.backgroundColor = 'rgb(215, 218, 248)';
  }

  function setupNestWifiNavOptionBtnsMobile():JSX.Element {
      return <div ref={nestWifiNavOptionsMobileRef} className="nest-wifi-nav-options-mobile-container">
                <div ref={nestWifiNavOptionsSelectedItemMobileContainerRef} className="nest-wifi-nav-options-selected-item-mobile-container">
                  <button ref={nestWifiNavOptionSelectedItemMobileRef} type="button" onMouseEnter={showBtnHighlight}
                    onMouseLeave={showBtnHighlight}
                    onMouseDown={showNestWifiMobileOptions} onMouseUp={showNestWifiMobileOptions} className="nest-wifi-nav-options-selected-item-mobile">
                    <div ref={nestWifiNavOptionsSelectedItemCoverRef} onAnimationEnd={animationEndHandler} className="nest-wifi-nav-options-selected-item-cover"></div>
                    <span ref={nestWifiNavOptionsSelectedItemTextMobileRef} className="nest-wifi-nav-options-selected-item-text-mobile">{nestWifiMobileOptionSelected}</span>
                    <svg viewBox="0 0 12 8" className="nest-wifi-options-selected-svg-mobile">
                      <g>
                        <defs>
                          <path id="c6" d="M18.59 16.41L20 15l-8-8-8 8 1.41 1.41L12 9.83"></path>
                        </defs>
                        <g fillRule="evenodd">
                          <mask id="c7" fill="#fff">
                            <use xlinkHref="#c6"></use>
                          </mask>
                          <g mask="url(#c7">
                            <path d="M0 0h24v24H0z" fill="#5F6368"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
                <ul ref={nestWifiNavOptionsListMobileRef} className="nest-wifi-nav-options-list-mobile hide-nest-wifi-nav-options">
                 { navBtnOptions.map((nbo, i) => {
                    if(i === 0){
                      return <li ref={addNavBtnOptionMobileRefs} onMouseDown={(e:React.MouseEvent)=>changeNavOptionBtnBackgroundMobile(e, nbo)}
                               key={i} className="nest-wfif-nav-option-item-container-mobile change-nest-wifi-nav-option-item-container-background-mobile">
                              <span className="nest-wfif-nav-option-item-mobile">{nbo}</span>
                            </li>                 
                    } else {
                      return <li ref={addNavBtnOptionMobileRefs} onMouseDown={(e:React.MouseEvent)=>changeNavOptionBtnBackgroundMobile(e, nbo)}
                               key={i} className="nest-wfif-nav-option-item-container-mobile">
                              <span className="nest-wfif-nav-option-item-mobile">{nbo}</span>
                            </li>
                    }
    
                  })}
                </ul>
            </div>
  }

    return (
        <div className="nest-wifi-nav-container-wrapper">
            <div ref={nestWifiNavRef} className="nest-wifi-nav-container">
              <div className="nest-wifi-nav">
                  <div className="nest-wifi-nav-heading-container">
                  <div className="nest-wifi-google-store-option-sub-menu-overview-heading-wrapper">
                      <h1 className="nest-wifi-nav-heading">Nest Wifi</h1>
                      <div className="nest-wifi-star-rating-container-wrapper">
                      <div className="nest-wifi-star-rating-container">
                          <div className="nest-wifi-star-rating-shape full-star"></div>
                          <div className="nest-wifi-star-rating-shape full-star"></div>
                          <div className="nest-wifi-star-rating-shape full-star"></div>
                          <div className="nest-wifi-star-rating-shape half-star"></div>
                          <div className="nest-wifi-star-rating-shape"></div>
                      </div>
                      <div className="nest-wifi-star-rating-reviews">252 reviews</div>
                      </div>
                  </div>
                  </div>
                  <div className="nest-wifi-nav-options-container-wrapper">
                  {           
                    setupNestWifiNavOptionBtnsDesktop()
                  }
                  {
                    setupNestWifiNavOptionBtnsMobile()
                  }
                  </div>
                  <div className="nest-wifi-nav-pricing-container-wrapper">
                  <div className="nest-wifi-nav-pricing-container">
                      <div className="nest-wifi-nav-pricing-line-one">
                      From $149 or $24.83/mo for 6 months
                      </div>
                      <div className="nest-wifi-nav-pricing-line-two">
                      with 6 month 0% APR Gadget Store Financing*
                      </div>
                  </div>
                  <div className="nest-wifi-nav-buy-btn-container">
                      <button className="nest-wifi-nav-buy-btn" type="button">Buy</button>
                  </div>
                  </div>
              </div>
            </div>
            <div className="nest-wifi-nav-container-mobile">
              <div className="nest-wifi-nav-financing-container-mobile">
                <div className="nest-wifi-nav-financing-terms-container-mobile">
                  <div className="nest-wifi-nav-financing-term1-container-mobile">
                    <span>From $149 or $24.83/mo</span> for 6 months
                  </div>
                  <div className="nest-wifi-nav-financing-term2-container-mobile">
                    <span>with 6 month 0% APR Gadget Store Financing</span>
                    <span><sup>*</sup></span>
                  </div>
                </div>
              </div>
              <div className="nest-wifi-nav-buy-btn-container-mobile">
                <button type="button" className="nest-wifi-nav-buy-btn-mobile">
                  Buy
                </button>
              </div>
            </div>
        </div>
    );

};

export default NestWifiNav;


import React, {useRef, useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import throttle from 'lodash.throttle';
import getMobileStatus from './get-mobile-status';
import './SS2.css';
//import _ from 'lodash';


interface Props {}
type Ref = {
    sswRef:React.RefObject<HTMLDivElement>,
    sshcRef:React.RefObject<HTMLDivElement>,
    sStickyRef:React.RefObject<HTMLDivElement>,
    ssbiecRef:React.RefObject<HTMLDivElement>,
    sslicwRef:React.RefObject<HTMLDivElement>,
    ssliRef:React.RefObject<HTMLDivElement>
};

const StreamWithSpeedComponent = forwardRef<Ref, Props>((props, ref)=>{
    
    const sswRef = useRef<HTMLDivElement>(null);
    const sshcRef = useRef<HTMLDivElement>(null);
    const sStickyRef = useRef<HTMLDivElement>(null);
    const ssbiecRef = useRef<HTMLDivElement>(null);
    const sslicwRef = useRef<HTMLDivElement>(null);
    const sslidRef = useRef<HTMLDivElement>(null);
    const sslimRef = useRef<HTMLDivElement>(null);
    const sp = useRef<number>(0);
    const psp = useRef<number>(0);
    const ticked = useRef<boolean>(false);
    const dw = useRef<number>(0);
    const ww = useRef<number>(0);
    const wh = useRef<number>(0);

    const stickyHeaderContainer = useRef<number>(0);
    const imageSwipe = useRef<number>(100);
    const imageScaleStart = useRef<number>(1.12873);
    const imageScale = useRef<number>(0);
    const imageScaleTopBoundary = useRef<number>(0);
    const imageScaleBottomBoundary = useRef<number>(0);
    const stickyOffsetY = useRef<number>(0);
    const stickyOffsetYStart = useRef<number>(0);
    const tout1Y = useRef<number>(0);
    const tout3Y = useRef<number>(0);
    const tout1X = useRef<number>(0);
    const tout3X = useRef<number>(0);
    const tout2MobileY = useRef<number>(0);
    //original version
    //const expanderClipX = useRef<number>(14.7);
    const expanderClipXCurrent = useRef<number>(0);
    const expanderClipXStart = useRef<number>(0);
    const expanderClipXEnd = useRef<number>(0);
    const expanderClipYCurrent = useRef<number>(0);
    const expanderClipYStart = useRef<number>(0);
    //original version
    //const expanderScale = useRef<number>(.42);
    const expanderScaleCurrent = useRef<number>(0);
    const expanderScaleStart = useRef<number>(0);
    const expanderScaleEnd = useRef<number>(1);
    const expanderY = useRef<number>(0);
    const expanderYStart = useRef<number>(0);
    const expanderYMobile = useRef<number>(0);
    const staggerFactorExpand = useRef<number>(0);
    const header2Opacity = useRef<number>(0);
    const imageBlurOpacity = useRef<number>(0);
    const biHeight = useRef<number>(0);
    const biTop = useRef<number>(0);
    //const ssbiecRef = useRef<HTMLDivElement>(null);
    const imgContainerWidth = useRef<number>(0);
    const imgContainerHeight = useRef<number>(0);

    //adjustment values
    const expanderClipXAdjustmentMultiplier = useRef<number>(0);
    const expanderClipYAdjustmentMultiplier = useRef<number>(0);
    const toutXAdjustmentMultiplier = useRef<number>(0);
    const toutXAdjustment = useRef<number>(0);
    const toutYAdjustmentMultiplier = useRef<number>(0);
    const expanderScaleMultiplier = useRef<number>(0);
    const expanderScaleAdjustment = useRef<number>(0);
    const imageSwipeAdjustmentMultiplier = useRef<number>(0);

    const ssmbicRef = useRef<HTMLDivElement>(null);
    const sslidSVGRef = useRef<SVGSVGElement>(null);
    const sslimSVGRef = useRef<SVGSVGElement>(null);
    const ssbiecwRef = useRef<HTMLDivElement>(null);
    const scrollBarWidth = useRef<number>(0);
    const ssbisemRef = useRef<HTMLDivElement>(null);
    const ssbifemsRef = useRef<HTMLDivElement>(null);
    const ssmicwRef = useRef<HTMLDivElement>(null);
    const sStickChildRef = useRef<HTMLDivElement>(null);
    const ssh2cRef = useRef<HTMLDivElement>(null);
    const ssrimRef = useRef<HTMLDivElement>(null);
    const ssricRef = useRef<HTMLDivElement>(null);
    const ssricwRef = useRef<HTMLDivElement>(null);
    const ssbiewRef = useRef<HTMLDivElement>(null);
    const ssmimRef = useRef<HTMLDivElement>(null);
    const ssridRef = useRef<HTMLDivElement>(null);
    const ssmidRef = useRef<HTMLDivElement>(null);
    const ssbiesRef = useRef<HTMLDivElement>(null);

    const rightMiddleImgHeight = useRef<number>(0);
    const leftMiddleImgHeight = useRef<number>(0);
    const centerMiddleImgHeight = useRef<number>(0);

    const resizePage = useRef<boolean>(false);

    const isMobile = useRef<boolean>(false);
    const scrollIncrease = useRef<number>(0);
    const imgScaleTopBoundaryAdj = useRef<number>(0);
    const mobileScrollIncrease = useRef<number>(0);
    const mobileScrollDistance = useRef<number>(0);
    const imageScaleScrollDistance = useRef<number>(0);
    
    const speedDistance = useRef<number>(0);
    const [speedLimit, setSpeedLimit] = useState(500);
    const startPoint = useRef<number>(0);
    const endPoint = useRef<number>(0);
    const ssbifedsRef = useRef<HTMLDivElement>(null);
    const ssmicontwRef = useRef<HTMLDivElement>(null);
    const sslicRef = useRef<HTMLDivElement>(null);
    const ssicwrRef = useRef<HTMLDivElement>(null);
    const ssicwlRef = useRef<HTMLDivElement>(null);
    const speedScrollDistance = useRef<number>(0);

    const portrait = useRef<MediaQueryList[]>([]);

    const pw = useRef<number>(0);
    const ph = useRef<number>(0);

    useImperativeHandle(ref, ()=>{
        return ({
            get sswRef(){
                return sswRef;
            },
            get sshcRef(){
                return sshcRef;
            },
            get sStickyRef(){
                return sStickyRef;
            },
            get ssbiecRef(){
                return ssbiecRef;
            },
            get sslicwRef(){
                return sslicwRef;
            },
            get ssliRef(){
                return sslidRef;
            }
        });
    });

    useEffect(()=>{
      //console.log('img3:', ssbiecRef.current!.getBoundingClientRect().width);
      //original version
      //ww.current = window.innerWidth;
      //isMobile.current = getMobileStatus(window.innerWidth, window.innerHeight) || window.matchMedia('(max-width:280px)').matches;
      if(Math.min(window.screen.width, window.screen.height) < 768){
        isMobile.current = true;
      }
      //console.log('isMobile:', isMobile.current);

      if(!isMobile.current){
        ww.current = window.innerWidth;
        wh.current = window.innerHeight;
        dw.current = document.documentElement.clientWidth;
      
      } else {
        ww.current = window.screen.width;
        wh.current = window.screen.height;
        dw.current = window.screen.width;
      }

      ww.current = document.documentElement.clientWidth;
      wh.current = document.documentElement.clientHeight;

      //set current width
      pw.current = ww.current;
      ph.current = wh.current;

      //console.log('ww:', ww.current, 'wh:', wh.current);

      resizePage.current = false;
      //scrollBarWidth.current = ww.current - document.documentElement.clientWidth;
      if(ww.current < 768){
        //getLazyLoadImagesPositions();
        setupStreamWithSpeedMobileSettings();
        //console.log('istb:', imageScaleTopBoundary.current);
      } else {
        window.requestAnimationFrame(()=>{
          //getLazyLoadImagesPositions();
          //console.log('find img:', sslidRef.current!.getBoundingClientRect().top);
          imageScaleTopBoundary.current = sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset;
          //console.log('istb before:', imageScaleTopBoundary.current);
          //original version
          //imageScaleBottomBoundary.current = sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset + 1600;
          imageScaleBottomBoundary.current = sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset + sStickyRef.current!.getBoundingClientRect().height;

          const ssmbicRefHeight = ssmbicRef.current!.offsetHeight;
          const ssbiecwRefHeight = ssbiecwRef.current!.offsetHeight;
          //console.log('sbiecw:', ssbiecwRefHeight, 'ssmbic:', ssmbicRefHeight);
          speedScrollDistance.current = ssmbicRefHeight;
          //original version
          //expanderYStart.current = stickyOffsetYStart.current = (ssbiecwRefHeight - ssmbicRefHeight) / 2;
          const mb /*expanderYStart.current = stickyOffsetYStart.current*/ = wh.current > ssmbicRefHeight ? (ssmbicRefHeight - wh.current) / 2 : ssmbicRefHeight - wh.current;
          expanderYMobile.current = expanderYStart.current = stickyOffsetYStart.current = mb < 0 ? Math.abs(mb) : -mb;
          //console.log('eys1:', expanderYStart.current);
          sshcRef.current!.style.marginBottom = `${mb}px`;

          //console.log('soy:', stickyOffsetYStart.current);
          setupScrollParameters(stickyOffsetYStart.current);

          //console.log('p:', sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset, 'mbic:', Math.abs(mb) + ssmbicRef.current!.getBoundingClientRect().top + window.pageYOffset);
          
          imageScaleTopBoundary.current = imageScaleTopBoundary.current - expanderYStart.current;
          imageScaleBottomBoundary.current = imageScaleBottomBoundary.current - expanderYStart.current;
          //console.log('istb:', imageScaleTopBoundary.current, 'isbb:', imageScaleBottomBoundary.current);

          imageScaleScrollDistance.current = imageScaleBottomBoundary.current - imageScaleTopBoundary.current;
          //console.log('issd:', imageScaleScrollDistance.current);

          //console.log('istb after:', imageScaleTopBoundary.current, expanderYStart.current, sswRef.current!.getBoundingClientRect().top);
          //console.log('ist 3:', imageScaleTopBoundary.current, sp.current);
          //console.log('isbb:', imageScaleBottomBoundary.current);
    
          //original version
          //stickyHeaderContainer.current = window.pageYOffset + (sshcRef.current!.getBoundingClientRect().top - 55);
          
          //original version
          //dw.current = document.documentElement.clientWidth;
          //console.log('img:', ssbiecRef.current!.offsetWidth);
          //original version
          //ssbiecRef.current!.style.left = (dw.current - (dw.current * .8)) / 2 + 'px';
          ssbiecRef.current!.style.left = ww.current >= (768/* - scrollBarWidth.current*/) ? (ww.current - (ww.current * .8)) / 2 + 'px' : '0px';
          //console.log('width:', ssbiecRef.current!.getBoundingClientRect().width);
          imgContainerWidth.current = window.parseInt(`${ssbiecRef.current!.getBoundingClientRect().width}`);
          imgContainerHeight.current = window.parseInt(`${ssbiecRef.current!.getBoundingClientRect().height}`);
          //console.log('find:', imgContainerWidth.current, imgContainerHeight.current);
          
          const leftImgWidth = (ww.current/* - 15*/) >= 768 ? sslidRef.current!.getBoundingClientRect().width : sslimRef.current!.getBoundingClientRect().width;
          const leftImgHeight = (ww.current/* - 15*/) >= 768 ? sslidRef.current!.getBoundingClientRect().height : sslimRef.current!.getBoundingClientRect().height;
          const ssliSVGRefHeight = (ww.current/* - 15*/) >= 768 ? sslidSVGRef.current!.getBoundingClientRect().height : sslimSVGRef.current!.getBoundingClientRect().height;
          const ssmbicRefWidth = ssmbicRef.current!.getBoundingClientRect().width;
          
          //console.log('liw useeffect:', leftImgWidth);
          //console.log('lih:', leftImgHeight);
          //console.log('licw:', imgContainerWidth.current);
          //console.log('lich:', imgContainerHeight.current);
          //console.log('svgh:', ssliSVGRefHeight);
          //console.log('ssmbicw:', ssmbicRefWidth);
          //original version
          //expanderScaleStart.current = expanderScaleCurrent.current = leftImgHeight / imgContainerHeight.current;
          //console.log('esc1:', leftImgHeight / imgContainerHeight.current);
          //console.log('esc2:', ssliSVGRefHeight / imgContainerHeight.current);
          //console.log('esc3:', ssliSVGRefHeight / ssmbicRefWidth);
          //console.log('esc4:', leftImgHeight/ ssmbicRefWidth);
          //console.log('esc5:', leftImgHeight / ssmbicRefHeight);
          setupBackdropContainerDimensions(imgContainerWidth.current, imgContainerHeight.current, leftImgWidth, leftImgHeight);
          //original version
          /*
          //expanderScaleStart.current = expanderScaleCurrent.current = ww.current > 950 ? leftImgHeight / imgContainerHeight.current : ssliSVGRefHeight / ssmbicRefWidth;
          expanderScaleStart.current = expanderScaleCurrent.current = leftImgHeight / imgContainerHeight.current;
          console.log('ess:', expanderScaleCurrent.current);
          const scaledImgContainerHeight = window.parseFloat((imgContainerHeight.current * expanderScaleStart.current).toFixed(6));
          console.log('sich:', scaledImgContainerHeight);
          expanderClipYStart.current = expanderClipYCurrent.current = (((scaledImgContainerHeight - leftImgHeight) / scaledImgContainerHeight) / 2) * 100;
          expanderClipYStart.current = expanderClipYCurrent.current = expanderClipYStart.current < 0 ? 0 : expanderClipYStart.current;
          console.log('ecys:', expanderClipYStart.current);
    
          expanderYStart.current = expanderYStart.current - ((expanderClipYStart.current / 100) * scaledImgContainerHeight);
          console.log('eys after:', expanderYStart.current);
          //original version
          //const scaledImgContainerWidth = imgContainerWidth.current * expanderScaleStart.current;
          const scaledImgContainerWidth = imgContainerWidth.current * expanderScaleStart.current;
          console.log('sicw:', scaledImgContainerWidth, imgContainerWidth.current, expanderScaleStart.current);
          //original version
          //expanderClipXStart.current = expanderClipXCurrent.current = (((scaledImgContainerWidth - leftImgWidth) / scaledImgContainerWidth) / 2) * 100;
          expanderClipXStart.current = (((scaledImgContainerWidth - leftImgWidth) / scaledImgContainerWidth) / 2) * 100;
          //console.log('ecxs start:', expanderClipXStart.current);
          expanderClipXStart.current = expanderClipXCurrent.current = expanderClipXStart.current < 0 ? 0 : expanderClipXStart.current;
          */
         //console.log('istb:', imageScaleTopBoundary.current, 'isbb:', imageScaleBottomBoundary.current);
          //console.log('ecxs final:', expanderClipXStart.current);
          //console.log('ecx:', expanderClipXStart.current);
          //console.log('h:', ssbiecRef.current!.getBoundingClientRect().height);
          //console.log('ssli.h:', ssliRef.current!.getBoundingClientRect().height);
          //console.log('ssli.w:', ssliRef.current!.getBoundingClientRect().width);
          expanderClipXAdjustmentMultiplier.current = expanderClipXStart.current / scrollIncrease.current/*395*/;
          //original version
          //toutXAdjustment.current = leftImgWidth * 2;
          toutXAdjustment.current = leftImgWidth / .47;
          toutXAdjustmentMultiplier.current = toutXAdjustment.current / scrollIncrease.current/*395*/;
          //original version
          //toutYAdjustmentMultiplier.current = 95 /395;
          toutYAdjustmentMultiplier.current = (wh.current * .1) / scrollIncrease.current/*395*/;
          expanderScaleAdjustment.current = 1 - expanderScaleStart.current;
          expanderScaleMultiplier.current = expanderScaleAdjustment.current / scrollIncrease.current/*395*/;
          imageSwipeAdjustmentMultiplier.current = 100 / scrollIncrease.current/*395*/;
    
          imageScale.current = imageScaleStart.current;
          setupStreamWithSpeedCSSStyles();
          window.requestAnimationFrame(getLazyLoadImagesPositions);
          window.requestAnimationFrame(lazyLoadImages);
          //window.requestAnimationFrame(animateStreamWithSpeed);
          //getLazyLoadImagesPositions();
          //animateStreamWithSpeed();

        });
      }

      window.addEventListener('resize', streamWithSpeedResizeHandler);
      //previous version
      //window.addEventListener('scroll', throttle(streamWithSpeedScrollHandler,50));
      window.addEventListener('scroll', streamWithSpeedScrollHandler);

      portrait.current[0] = window.matchMedia("(orientation: portrait)");
        portrait.current[0].addEventListener("change", streamWithSpeedResizeHandler);

      return cleanUpScrollHandler;

    },[]);

    function cleanUpScrollHandler():void {
      window.removeEventListener('resize', streamWithSpeedResizeHandler);
      //previous version
      //window.removeEventListener('scroll', throttle(streamWithSpeedScrollHandler,50));
      window.removeEventListener('scroll', streamWithSpeedScrollHandler);

      portrait.current[0].removeEventListener("change", streamWithSpeedResizeHandler);
    }

    function setupScrollParameters(soy:number):void {
      const asoy = soy < 0 ? Math.abs(soy) : -soy;
      //console.log('asoy:', asoy);
      //console.log('ph:', sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset + 8 /*+ sStickyRef.current!.offsetHeight - 80*/);
      //console.log('mbic:', ssmbicRef.current!.getBoundingClientRect().top + window.pageYOffset + ssmbicRef.current!.offsetHeight);
      const ptp = sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset /*+ asoy*/;
      //console.log('ptp:', ptp);
      const pbp = sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset /*+ asoy*/ + sStickyRef.current!.getBoundingClientRect().height - 80;
      //console.log('pbp:', pbp);
      const mbicbp = sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset/* + asoy*/ + ssmbicRef.current!.getBoundingClientRect().height;
      //console.log('mbicbp:', mbicbp);
      const isd = pbp - mbicbp;
      const sd = mbicbp > wh.current ? isd + (mbicbp - wh.current) : isd - (mbicbp - wh.current);
      //console.log('sd:', sd);

      //ssp does not include sticky-offset-y value
      const ssp = sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset;
      //console.log('ssp:', ssp);
      const tsdiff = sd - ssp;
      //console.log('tsdiff:', tsdiff);
      const sia = (wh.current * .16);
      //console.log('sia:', ptp - (.16 * wh.current), sia + Math.abs(soy));
      const asd = sd - (ssp + Math.abs(soy));
      //console.log('asd:', asd);
      //default value is absolute value of sticky-offset-y
      scrollIncrease.current = sia + Math.abs(soy); //replace value  with adjusted amount if needed
      imgScaleTopBoundaryAdj.current = sia;// 0;// (wh.current * .1);
      //console.log('istba:', imgScaleTopBoundaryAdj.current);
      //console.log('istb:', imageScaleTopBoundary.current);
    }


    function getScrollSpeedDiff(startPoint:number, endPoint:number):number {
      const end = Math.abs(endPoint);
      const start = Math.abs(startPoint);
      const diff = end > start ? end - start : start - end;
      return diff;
    }

    function setScrollSpeed(speedDiff:number):string {
      /*
      if(speedDiff < speedMinimum){
        return 'slow';
      } else if(speedDiff > speedMinimum && speedDiff < speedLimit){
        return 'normal';
      } else {
        return 'fast';
      }
      */
      //console.log('speedDiff:', speedDiff);
      //console.log('distance:', speedDiff, scrollDirection.current);
      speedDistance.current = speedDiff;
      return speedDiff < speedLimit ? 'normal' : 'fast';
    }

    function setScrollSpeedParams():void {     
            
      if(speedDistance.current > 50 /*t3Adj.current*/){
        /*
        stickyRef.current!.style.transition = 'transform .05s linear';
        vrcRef.current!.style.transition = 'clip-path .05s linear';
        vsrcRef.current!.style.transition = 'transform .05s linear, transform-origin .05s linear, opacity .05s linear';
        varcRef.current!.style.transition = 'transform .05s linear, transform-origin .05s linear, opacity .05s linear';
        vrhcRef.current!.style.transition = 'transform .05s ease-in';

        //css clip-path speed
        (vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `50ms`);
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
        */

                //sticky header 2
        //ssh2cRef
        //sticky backdrop img expander container
        ssbiecRef.current!.style.transition = 'transform .3s ease';
        //desktop swiper
        ssbifedsRef.current!.style.transition = 'clip-path .1s ease, transform .7s ease';
        //mobile swiper
        ssbifemsRef.current!.style.transition = 'clip-path .1s ease, transform .7s ease';
        //sticky middle backdrop image container
        ssmbicRef.current!.style.transition = 'transform .3s ease';
        //left image container wrapper
        sslicwRef.current!.style.transition = 'transform .3s ease';
        //right image container wrapper
        ssricwRef.current!.style.transition = 'transform .3s ease';
        //left image container
        //sslicRef
        //left image content wrapper
        //ssicwlRef
        //right image container
        //ssricRef
        //right image content wrapper
        //ssicwrRef
        //middle image content wrapper
        ssmicontwRef.current!.style.transition = 'transform .3s ease';
        return;
      }
      

      
      if(speedDistance.current <= 50){
        /*
        stickyRef.current!.style.transition = 'transform .3s linear';
        vrcRef.current!.style.transition = 'clip-path .3s linear';
        vsrcRef.current!.style.transition = 'transform .3s linear, transform-origin .3s linear, opacity .3s linear';
        varcRef.current!.style.transition = 'transform .3s linear, transform-origin .3s linear, opacity .3s linear';
        vrhcRef.current!.style.transition = 'transform .3s ease-in';

        //css clip-path speed
        (vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `300ms`);
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
        */

        //sticky header 2
        //ssh2cRef
        //sticky backdrop img expander container
        ssbiecRef.current!.style.transition = 'transform .3s ease';
        //desktop swiper
        ssbifedsRef.current!.style.transition = 'clip-path .3s ease, transform .7s ease';
        //mobile swiper
        ssbifemsRef.current!.style.transition = 'clip-path .3s ease, transform .7s ease';
        //sticky middle backdrop image container
        ssmbicRef.current!.style.transition = 'transform .3s ease';
        //left image container wrapper
        sslicwRef.current!.style.transition = 'transform .3s ease';
        //right image container wrapper
        ssricwRef.current!.style.transition = 'transform .3s ease';
        //left image container
        //sslicRef
        //left image content wrapper
        //ssicwlRef
        //right image container
        //ssricRef
        //right image content wrapper
        //ssicwrRef
        //middle image content wrapper
        ssmicontwRef.current!.style.transition = 'transform .3s ease';

        return;
      }

      return;

      if(speedDistance.current >= 10 && speedDistance.current < 50){
        /*
        stickyRef.current!.style.transition = 'transform .25s linear';
        vrcRef.current!.style.transition = 'clip-path .25s linear';
        vsrcRef.current!.style.transition = 'transform .25s linear, transform-origin .25s linear, opacity .25s linear';
        varcRef.current!.style.transition = 'transform .25s linear, transform-origin .25s linear, opacity .25s linear';
        vrhcRef.current!.style.transition = 'transform .25s ease-in';

        //css clip-path speed
        (vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `250ms`);
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
        */

        //sticky header 2
        //ssh2cRef
        //sticky backdrop img expander container
        ssbiecRef.current!.style.transition = 'transform .25s linear';
        //desktop swiper
        ssbifedsRef.current!.style.transition = 'clip-path .25s linear, transform .25s linear';
        //mobile swiper
        ssbifemsRef.current!.style.transition = 'clip-path .25s linear, transform .25s linear';
        //sticky middle backdrop image container
        ssmbicRef.current!.style.transition = 'transform .25s linear';
        //left image container wrapper
        sslicwRef.current!.style.transition = 'transform .25s linear';
        //right image container wrapper
        ssricwRef.current!.style.transition = 'transform .25s linear';
        //left image container
        //sslicRef
        //left image content wrapper
        //ssicwlRef
        //right image container
        //ssricRef
        //right image content wrapper
        //ssicwrRef
        //middle image content wrapper
        ssmicontwRef.current!.style.transition = 'transform .25s linear';

        return;
      }

      if(speedDistance.current >= 50 && speedDistance.current <= 200){
        /*
        stickyRef.current!.style.transition = 'transform .2s linear';
        vrcRef.current!.style.transition = 'clip-path .2s linear';
        vsrcRef.current!.style.transition = 'transform .2s linear, transform-origin .2s linear, opacity .2s linear';
        varcRef.current!.style.transition = 'transform .2s linear, transform-origin .2s linear, opacity .2s linear';
        vrhcRef.current!.style.transition = 'transform .2s ease-in';

        //css clip-path speed
        (vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `200ms`);
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
        */

        //sticky header 2
        //ssh2cRef
        //sticky backdrop img expander container
        ssbiecRef.current!.style.transition = 'transform .2s linear';
        //desktop swiper
        ssbifedsRef.current!.style.transition = 'clip-path .2s linear, transform .2s linear';
        //mobile swiper
        ssbifemsRef.current!.style.transition = 'clip-path .2s linear, transform .2s linear';
        //sticky middle backdrop image container
        ssmbicRef.current!.style.transition = 'transform .2s linear';
        //left image container wrapper
        sslicwRef.current!.style.transition = 'transform .2s linear';
        //right image container wrapper
        ssricwRef.current!.style.transition = 'transform .2s linear';
        //left image container
        //sslicRef
        //left image content wrapper
        //ssicwlRef
        //right image container
        //ssricRef
        //right image content wrapper
        //ssicwrRef
        //middle image content wrapper
        ssmicontwRef.current!.style.transition = 'transform .2s linear';

        return;
      }

      //if(scrollDirection.current === 'down'){
        /*
        stickyRef.current!.style.transition = 'transform .1s linear';
        vrcRef.current!.style.transition = 'clip-path .1s linear';
        vsrcRef.current!.style.transition = 'transform .1s linear, transform-origin .1s linear, opacity .1s linear';
        varcRef.current!.style.transition = 'transform .1s linear, transform-origin .1s linear, opacity .1s linear';
        vrhcRef.current!.style.transition = 'transform .1s ease-in';

        //css clip-path speed
        (vsRef.current as HTMLDivElement).style.setProperty('--td-shift-y', `100ms`);
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
        */

                //sticky header 2
        //ssh2cRef
        //sticky backdrop img expander container
        ssbiecRef.current!.style.transition = 'transform .1s linear';
        //desktop swiper
        ssbifedsRef.current!.style.transition = 'clip-path .1s linear, transform .1s linear';
        //mobile swiper
        ssbifemsRef.current!.style.transition = 'clip-path .1s linear, transform .1s linear';
        //sticky middle backdrop image container
        ssmbicRef.current!.style.transition = 'transform .1s linear';
        //left image container wrapper
        sslicwRef.current!.style.transition = 'transform .1s linear';
        //right image container wrapper
        ssricwRef.current!.style.transition = 'transform .1s linear';
        //left image container
        //sslicRef
        //left image content wrapper
        //ssicwlRef
        //right image container
        //ssricRef
        //right image content wrapper
        //ssicwrRef
        //middle image content wrapper
        ssmicontwRef.current!.style.transition = 'transform .1s linear';
        return;
      //}
      
      
           
      
      //css clip-path speed
      /*
      if(scrollDirection.current ===  'down'){
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease');
      } else {
        (vsRef.current as HTMLDivElement).style.setProperty('--cp-shift', 'ease-in');
      }
      */
    }

    function streamWithSpeedScrollHandler(e:Event):void {
      //console.log('stream with speed scroll event');
      sp.current = window.pageYOffset;
      //console.log('sp.current:', sp.current);
      //console.log('sbw:', scrollBarWidth.current, sp.current);
      if(!ticked.current){
        window.requestAnimationFrame(animateStreamWithSpeed);
      }

      ticked.current = true;
    }

    function animateStreamWithSpeed():void{
      //console.log('animate stream with speed');
      //console.log('itb:', imageScaleTopBoundary.current);
      //finish lazy load condition
      lazyLoadImages();

      endPoint.current = sp.current;
      //console.log('startPoint:', startPoint.current, 'endPoint:', endPoint.current);

      speedDistance.current = getScrollSpeedDiff(startPoint.current, endPoint.current);
      //console.log('speed distance:', speedDistance.current);
      //const speed = setScrollSpeed(speedDiff);
      setScrollSpeedParams();

      if(sp.current < imageScaleTopBoundary.current){
        imageScale.current = imageScaleStart.current;
        (sswRef.current as HTMLDivElement).style.setProperty('--image-scale', `${imageScale.current}`);        
      }
      //console.log('istb:', imageScaleTopBoundary.current, sp.current, window.pageYOffset, imageScaleBottomBoundary.current);

      if(sp.current >= imageScaleTopBoundary.current && sp.current <= imageScaleBottomBoundary.current){
        //console.log('found');
        //console.log('r:', (sp.current - psp.current) * (.2 / 4812));
        //imageScale.current -= (sp.current - psp.current) * (.2 / 4812);
        //imageScale.current += window.parseFloat(((sp.current - psp.current) * (.2 / 5460)).toFixed(6));
        //imageScale.current = 1 + window.parseFloat(((6975 - sp.current) * (.2 / 5460)).toFixed(6));
        //console.log('ist:', imageScaleStart.current - (.12873 * ((sp.current - imageScaleTopBoundary.current) / 1600)));
        //imageScale.current = 1 + window.parseFloat(((6975 - sp.current) * (.2 / 5360)).toFixed(6));
        imageScale.current = ww.current >= 768 
                              ? imageScaleStart.current - (.12873 * ((sp.current - imageScaleTopBoundary.current) / imageScaleScrollDistance.current /*1600*/))
                              : imageScaleStart.current - (.12873 * ((sp.current - imageScaleTopBoundary.current) / imageScaleScrollDistance.current/*3304*/));
        //console.log('is:',  imageScale.current);
        //(sswRef.current as HTMLDivElement).style.setProperty('--image-scale', `${1. + imageScale.current}`);
        (sswRef.current as HTMLDivElement).style.setProperty('--image-scale', `${imageScale.current}`);
      }

      if(sp.current > imageScaleBottomBoundary.current){
        imageScale.current = 1;
        (sswRef.current as HTMLDivElement).style.setProperty('--image-scale', `${imageScale.current}`);
      }
      

      if(ww.current < 768){

        if(sp.current < imageScaleTopBoundary.current /*+ 1424*/){
          //console.log('less than');
          tout1Y.current = tout3Y.current = 0;
          tout1X.current = tout3X.current = 0;
          tout2MobileY.current = 0;
          expanderClipXCurrent.current = expanderClipXStart.current;
          expanderClipYCurrent.current = expanderClipYStart.current;
          expanderScaleCurrent.current = expanderScaleStart.current;
          imageSwipe.current = 100;
          staggerFactorExpand.current = 0;
          //header2Opacity.current = 0;
          //imageBlurOpacity.current = 0;
          (sswRef.current as HTMLDivElement).style.setProperty('--tout1-x', `-${tout1X.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout3-x', `${tout3X.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout1-y', `${tout1Y.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout3-y', `${tout3Y.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--image-swipe', `${imageSwipe.current}%`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout2-mobile-y', `${tout2MobileY.current}vh`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-clip-x', `${expanderClipXStart.current}%`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-clip-y', `${expanderClipYStart.current}%`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-y-mobile', `${expanderYMobile.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-scale', `${expanderScaleCurrent.current}`);
          (sswRef.current as HTMLDivElement).style.setProperty('--stagger-factor-expand', `${staggerFactorExpand.current}`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--header2-opacity', `${header2Opacity.current}`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--image-blur-opacity', `${imageBlurOpacity.current}`);
        }

        if(sp.current >= (imageScaleTopBoundary.current /*+ 1424*/) && sp.current <= (imageScaleTopBoundary.current + mobileScrollDistance.current /*1849*/)){
          //console.log('great than or equal to');
          //tout1Y.current = tout3Y.current = 95 - (((imageScaleTopBoundary.current + 1849) - sp.current) * toutYAdjustmentMultiplier.current);
          //tout1X.current = tout3X.current = toutXAdjustment.current - (((imageScaleTopBoundary.current + 1849) - sp.current) * toutXAdjustmentMultiplier.current);
          expanderClipXCurrent.current = ((imageScaleTopBoundary.current + mobileScrollDistance.current /*1849*/) - sp.current) * expanderClipXAdjustmentMultiplier.current;
          expanderClipYCurrent.current = ((imageScaleTopBoundary.current + mobileScrollDistance.current /*1849*/) - sp.current) * expanderClipYAdjustmentMultiplier.current;
          //console.log('ecy:', expanderClipYCurrent.current);
          imageSwipe.current = ((imageScaleTopBoundary.current + mobileScrollDistance.current /*1849*/) - sp.current) * imageSwipeAdjustmentMultiplier.current;
          expanderScaleCurrent.current = expanderScaleStart.current + (expanderScaleAdjustment.current - (((imageScaleTopBoundary.current + mobileScrollDistance.current /*1849*/) - sp.current) * expanderScaleMultiplier.current));
          staggerFactorExpand.current = 1 - (((imageScaleTopBoundary.current + mobileScrollDistance.current /*1849*/) - sp.current) / mobileScrollDistance.current /*425*/);
          tout2MobileY.current = 80 - (((imageScaleTopBoundary.current + mobileScrollDistance.current /*1849*/) - sp.current) * (80 / mobileScrollDistance.current /*425*/));

          //header2Opacity.current = 0;
          //imageBlurOpacity.current = 0;
          //(sswRef.current as HTMLDivElement).style.setProperty('--tout1-x', `-${tout1X.current}px`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--tout3-x', `${tout3X.current}px`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--tout1-y', `${tout1Y.current}px`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--tout3-y', `${tout3Y.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-clip-x', `${expanderClipXCurrent.current}%`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-clip-y', `${expanderClipYCurrent.current}%`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-scale', `${expanderScaleCurrent.current}`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--expander-y', `${expanderY.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--stagger-factor-expand', `${staggerFactorExpand.current}`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--sticky-offset-y', `${stickyOffsetY.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout2-mobile-y', `${tout2MobileY.current}vh`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--header2-opacity', `${header2Opacity.current}`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--image-blur-opacity', `${imageBlurOpacity.current}`);
          (sswRef.current as HTMLDivElement).style.setProperty('--image-swipe', `${imageSwipe.current}%`);
        }

        if(sp.current > (imageScaleTopBoundary.current + mobileScrollDistance.current /*1849*/)){
          //console.log('great than');
          expanderClipXCurrent.current = 0;
          expanderClipYCurrent.current = 0;
          imageSwipe.current = 0;
          tout2MobileY.current = 80;
          expanderScaleCurrent.current = 1;
          staggerFactorExpand.current = 1;
          //header2Opacity.current = 0;
          //imageBlurOpacity.current = 0;

          (sswRef.current as HTMLDivElement).style.setProperty('--expander-clip-x', `${expanderClipXCurrent.current}%`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-clip-y', `${expanderClipYCurrent.current}%`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-scale', `${expanderScaleCurrent.current}`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--expander-y', `${expanderY.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--stagger-factor-expand', `${staggerFactorExpand.current}`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--sticky-offset-y', `${stickyOffsetY.current}px`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--header2-opacity', `${header2Opacity.current}`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--image-blur-opacity', `${imageBlurOpacity.current}`);
          (sswRef.current as HTMLDivElement).style.setProperty('--image-swipe', `${imageSwipe.current}%`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout2-mobile-y', `${tout2MobileY.current}vh`);
        }

        if(sp.current < (imageScaleTopBoundary.current + mobileScrollDistance.current + 120 /*2063*/)){
          header2Opacity.current = 0;
          (sswRef.current as HTMLDivElement).style.setProperty('--header2-opacity', `${header2Opacity.current}`);
        }

        if(sp.current >= (imageScaleTopBoundary.current + mobileScrollDistance.current + 120 /*2063*/) 
          && sp.current <= (imageScaleTopBoundary.current + mobileScrollDistance.current + 190/*2133*/)
          && imageSwipe.current === 0){
          header2Opacity.current = 1 - (((imageScaleTopBoundary.current + mobileScrollDistance.current + 190 /*2133*/) - sp.current) / 70);
          (sswRef.current as HTMLDivElement).style.setProperty('--header2-opacity', `${header2Opacity.current}`);
        }

        if(sp.current > (imageScaleTopBoundary.current + mobileScrollDistance.current + 190 /*2133*/) && imageSwipe.current === 0){
          header2Opacity.current = 1;
          (sswRef.current as HTMLDivElement).style.setProperty('--header2-opacity', `${header2Opacity.current}`);
        }

      } else {
        //console.log('istb:', imageScaleTopBoundary.current, 'isbb:', imageScaleBottomBoundary.current);
         //original version
        //if(sp.current < stickyHeaderContainer.current){
        if(sp.current < imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current /*160*/){
          //console.log('ecxs:', expanderClipXStart.current);
          //console.log('ess:', expanderScaleStart.current);
          tout1Y.current = tout3Y.current = 0;
          tout1X.current = tout3X.current = 0;
          imageSwipe.current = 100;
          //expanderClipX.current = 14.7;
          expanderClipXCurrent.current = expanderClipXStart.current;
          //expanderScale.current = 0.42;
          expanderScaleCurrent.current = expanderScaleStart.current;
          //original version
          //expanderY.current = 227;
          expanderY.current = expanderYStart.current;
          staggerFactorExpand.current = 0;
          //original version
          //stickyOffsetY.current = 227;
          stickyOffsetY.current = stickyOffsetYStart.current;
          header2Opacity.current = 0;
          imageBlurOpacity.current = 0;
          (sswRef.current as HTMLDivElement).style.setProperty('--tout1-x', `-${tout1X.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout3-x', `${tout3X.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout1-y', `${tout1Y.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout3-y', `${tout3Y.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--image-swipe', `${imageSwipe.current}%`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-clip-x', `${expanderClipXCurrent.current}%`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-scale', `${expanderScaleCurrent.current}`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-y', `${expanderY.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--stagger-factor-expand', `${staggerFactorExpand.current}`);
          (sswRef.current as HTMLDivElement).style.setProperty('--sticky-offset-y', `${stickyOffsetY.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--header2-opacity', `${header2Opacity.current}`);
          (sswRef.current as HTMLDivElement).style.setProperty('--image-blur-opacity', `${imageBlurOpacity.current}`);
        }

        //console.log('what:', sp.current, imageScaleTopBoundary.current, scrollIncrease.current);
        //original version
        //if(sp.current >= stickyHeaderContainer.current && sp.current <= (stickyHeaderContainer.current + 400)){
        if(sp.current >= (imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current /*160*/) && sp.current <= (imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + scrollIncrease.current /*160 + 235*/)){
          //console.log('passed');
          //console.log('start');
          //tout1Y.current = tout3Y.current += (sp.current - psp.current) * .2375;
          //original version
          //tout1Y.current = tout3Y.current = 95 - (((stickyHeaderContainer.current + 400) - sp.current) * .2375);
          //original version
          //tout1Y.current = tout3Y.current = 95 - (((stickyHeaderContainer.current + 400) - sp.current) * toutYAdjustmentMultiplier.current);
          //uncomment this
          tout1Y.current = tout3Y.current = (wh.current * .1)/*95*/ - (((imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + scrollIncrease.current /*160 + 235*/) - sp.current) * toutYAdjustmentMultiplier.current);
          //tout1X.current = tout3X.current += (sp.current - psp.current) * 1.675 ;
          //original version
          //tout1X.current = tout3X.current = 670 - (((stickyHeaderContainer.current + 400) - sp.current) * 1.675);
          //original version
          //tout1X.current = tout3X.current = toutXAdjustment.current - (((stickyHeaderContainer.current + 400) - sp.current) * toutXAdjustmentMultiplier.current);
          //un comment this
          tout1X.current = tout3X.current = toutXAdjustment.current - (((imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + scrollIncrease.current /*160 + 235*/) - sp.current) * toutXAdjustmentMultiplier.current);
          //imageSwipe.current -= (sp.current - psp.current) * .25;
          //original version
          //imageSwipe.current = ((stickyHeaderContainer.current + 400) - sp.current) * .25;
          //original version
          //imageSwipe.current = ((stickyHeaderContainer.current + 400) - sp.current) * imageSwipeAdjustmentMultiplier.current;
          imageSwipe.current = ((imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + scrollIncrease.current /*160 + 235*/) - sp.current) * imageSwipeAdjustmentMultiplier.current;
          //expanderClipX.current -= (sp.current - psp.current) * .03675;
          //original version for 1330px width
          //expanderClipXCurrent.current = ((stickyHeaderContainer.current + 400) - sp.current) * .03675;
          //original version
          //expanderClipXCurrent.current = ((stickyHeaderContainer.current + 525) - sp.current) * expanderClipXAdjustmentMultiplier.current;
          expanderClipXCurrent.current = ((imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + scrollIncrease.current/*160 + 235*/) - sp.current) * expanderClipXAdjustmentMultiplier.current;
          //expanderScale.current += ((sp.current - psp.current) * .145) / 100;
          //previous version
          //expanderScale.current = .42 + (.58 - (((stickyHeaderContainer.current + 400) - sp.current) * .00145));
          //original version
          //expanderScaleCurrent.current = expanderScaleStart.current + (.58 - (((stickyHeaderContainer.current + 400) - sp.current) * .00145));
          //original version
          //expanderScaleCurrent.current = expanderScaleStart.current + (expanderScaleAdjustment.current - (((stickyHeaderContainer.current + 400) - sp.current) * expanderScaleMultiplier.current));
          expanderScaleCurrent.current = expanderScaleStart.current + (expanderScaleAdjustment.current - (((imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + scrollIncrease.current/*160 + 235*/) - sp.current) * expanderScaleMultiplier.current));
          //console.log('esc:', expanderScaleCurrent.current);
          //expanderY.current -= (sp.current - psp.current) * .33;
          //original version
          //expanderY.current = 95 + (((stickyHeaderContainer.current + 400) - sp.current) * .33);
          //original version
          //expanderY.current = 95 + (((imageScaleTopBoundary.current + 235) - sp.current) * ((expanderYStart.current - 95) / 395));
          //test version
          expanderY.current = (wh.current * .1) + (((imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + scrollIncrease.current /*160 + 235*/) - sp.current) * ((expanderYStart.current - (wh.current * .1)) / scrollIncrease.current));
          //staggerFactorExpand.current += (sp.current - psp.current) * .0025;
          //original version
          //staggerFactorExpand.current = 1 - (((stickyHeaderContainer.current + 400) - sp.current) * .0025);
          //original version
          //staggerFactorExpand.current = 1 - (((imageScaleTopBoundary.current + 235) - sp.current) * .0025);
          staggerFactorExpand.current = 1 - (((imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + scrollIncrease.current/*160 + 235*/) - sp.current) / scrollIncrease.current);
          //stickyOffsetY.current -= (sp.current - psp.current) * .5675;
          //original version
          //stickyOffsetY.current = ((stickyHeaderContainer.current + 400) - sp.current) * .5675;
          stickyOffsetY.current = ((imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + scrollIncrease.current /*160 + 235*/) - sp.current) * (stickyOffsetYStart.current / scrollIncrease.current);

          header2Opacity.current = 0;
          imageBlurOpacity.current = 0;
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-scale', `${expanderScaleCurrent.current}`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout1-x', `-${tout1X.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout3-x', `${tout3X.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout1-y', `${tout1Y.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout3-y', `${tout3Y.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-clip-x', `${expanderClipXCurrent.current}%`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-y', `${expanderY.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--stagger-factor-expand', `${staggerFactorExpand.current}`);
          (sswRef.current as HTMLDivElement).style.setProperty('--sticky-offset-y', `${stickyOffsetY.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--header2-opacity', `${header2Opacity.current}`);
          (sswRef.current as HTMLDivElement).style.setProperty('--image-blur-opacity', `${imageBlurOpacity.current}`);
          (sswRef.current as HTMLDivElement).style.setProperty('--image-swipe', `${imageSwipe.current}%`);
        }

        //original version
        //if(sp.current > (stickyHeaderContainer.current + 400)){
        if(sp.current > (imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + scrollIncrease.current/*160 + 235*/)){
          //console.log('end');
          //tout1X.current = tout3X.current = 670;
          tout1X.current = tout3X.current = toutXAdjustment.current;
          tout1Y.current = tout3Y.current = wh.current * .1 /*95*/;
          imageSwipe.current = 0;
          expanderScaleCurrent.current = expanderScaleEnd.current;
          expanderClipXCurrent.current = expanderClipXEnd.current;
          expanderY.current = (wh.current * .1)/*95*/;
          staggerFactorExpand.current = 1;
          stickyOffsetY.current = 0;
          //header2Opacity.current = 0;
          //imageBlurOpacity.current = 0;
          (sswRef.current as HTMLDivElement).style.setProperty('--tout1-x', `-${tout1X.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout3-x', `${tout3X.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout1-y', `${tout1Y.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--tout3-y', `${tout3Y.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-clip-x', `${expanderClipXCurrent.current}%`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-scale', `${expanderScaleCurrent.current}`);
          (sswRef.current as HTMLDivElement).style.setProperty('--expander-y', `${expanderY.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--stagger-factor-expand', `${staggerFactorExpand.current}`);
          (sswRef.current as HTMLDivElement).style.setProperty('--sticky-offset-y', `${stickyOffsetY.current}px`);
          (sswRef.current as HTMLDivElement).style.setProperty('--image-swipe', `${imageSwipe.current}%`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--header2-opacity', `${header2Opacity.current}`);
          //(sswRef.current as HTMLDivElement).style.setProperty('--image-blur-opacity', `${imageBlurOpacity.current}`);
        }

        //original version
        //if(sp.current < stickyHeaderContainer.current + 440){
        if(sp.current < imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + (scrollIncrease.current * 1.07) /*160 + 279*/){
          imageBlurOpacity.current = 0;
          (sswRef.current as HTMLDivElement).style.setProperty('--image-blur-opacity', `${imageBlurOpacity.current}`);
        }

        //original version
        //if(sp.current >= stickyHeaderContainer.current + 440 && sp.current <= stickyHeaderContainer.current + 460){
        if(sp.current >= imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + (scrollIncrease.current * 1.07) /*160 + 279*/ 
          && sp.current <= imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + (scrollIncrease.current * 1.07) + 19 /*160 + (279 + 18)*/
          && imageSwipe.current === 0){
          //imageBlurOpacity.current += (sp.current - psp.current) * .05;
          //original version
          //imageBlurOpacity.current = 1 - (((stickyHeaderContainer.current + 460) - sp.current) * .05);
          //original version
          //imageBlurOpacity.current = (sp.current - (imageScaleTopBoundary.current - 160 + 279)) / 18;
          imageBlurOpacity.current = (sp.current - (imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + (scrollIncrease.current * 1.07))) / 19;
          //console.log('ibo:', imageBlurOpacity.current);
          (sswRef.current as HTMLDivElement).style.setProperty('--image-blur-opacity', `${imageBlurOpacity.current}`);
        }

        //original version
        //if(sp.current > stickyHeaderContainer.current + 460){
        if(sp.current > imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + (scrollIncrease.current * 1.07) + 19 /*160 + (279 + 18)*/
          && imageSwipe.current === 0){
          imageBlurOpacity.current = 1;
          (sswRef.current as HTMLDivElement).style.setProperty('--image-blur-opacity', `${imageBlurOpacity.current}`);
        }

        //original version
        //if(sp.current < stickyHeaderContainer.current + 480){
        if(sp.current < imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + (scrollIncrease.current * 1.20) /*160 + 318*/){
          header2Opacity.current = 0;
          (sswRef.current as HTMLDivElement).style.setProperty('--header2-opacity', `${header2Opacity.current}`);          
        }

        //original version
        //if(sp.current >= stickyHeaderContainer.current + 480 && sp.current <= stickyHeaderContainer.current + 520){
        if(sp.current >= imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + (scrollIncrease.current * 1.20)/*160 + 318*/ 
          && sp.current <= imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + (scrollIncrease.current * 1.20) + 39/*160 + (318 + 39)*/
          && imageSwipe.current === 0 && imageBlurOpacity.current === 1){
          //header2Opacity.current += (sp.current - psp.current) * .025;
          //original version
          //header2Opacity.current = 1 - (((stickyHeaderContainer.current + 520) - sp.current) * .025);
          header2Opacity.current = (sp.current - (imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + (scrollIncrease.current * 1.20) /*160 + 318)*/)) / 39;
          //console.log('h2o:', header2Opacity.current);
          (sswRef.current as HTMLDivElement).style.setProperty('--header2-opacity', `${header2Opacity.current}`);
        }

        //original version
        //if(sp.current > stickyHeaderContainer.current + 520){
        if(sp.current > imageScaleTopBoundary.current - imgScaleTopBoundaryAdj.current + (scrollIncrease.current * 1.20) + 39 /*160 + (318 + 39)*/
          && imageSwipe.current === 0 && imageBlurOpacity.current === 1){
          header2Opacity.current = 1;
          (sswRef.current as HTMLDivElement).style.setProperty('--header2-opacity', `${header2Opacity.current}`);
        }
      }

      
      startPoint.current = endPoint.current;

      ticked.current = false;
    }

    function streamWithSpeedResizeHandler(e:Event):void {
      
      if((e.type === 'resize' && pw.current === document.documentElement.clientWidth)){
        console.log('r12:', 'pw:', pw.current, 'ww:', document.documentElement.clientWidth);
        wh.current = document.documentElement.clientHeight;
        ww.current = document.documentElement.clientWidth;
        pw.current = document.documentElement.clientWidth;
        if(ph.current !== wh.current){
          setupStreamWithSpeedMobileSettings();
          ph.current = wh.current;
        }
        
        return;
      }

      pw.current = document.documentElement.clientWidth;
      ph.current = document.documentElement.clientHeight;
      


      if(!isMobile.current){
        ww.current = window.innerWidth;
        wh.current = window.innerHeight;
        dw.current = document.documentElement.clientWidth;
      
      } else {
        ww.current = window.screen.width;
        wh.current = window.screen.height;
        dw.current = window.screen.width;
      }

      ww.current = document.documentElement.clientWidth;
      wh.current = document.documentElement.clientHeight;


      resizePage.current = true;
      //original version
      //ww.current = window.innerWidth;
      //console.log('sp test:', sp.current);
      if(ww.current < 768){
        setupStreamWithSpeedMobileSettings();
      } else {
        
        window.requestAnimationFrame(()=>{
          //getLazyLoadImagesPositions();
          //this runs if less than 768px
          //need to setup this condition for mobile and desktop
          //setupStreamWithSpeedMobileSettings();
          imageScaleTopBoundary.current = sStickyRef.current!.offsetTop + window.pageXOffset;//sStickyRef.current!.offsetTop;
          imageScaleBottomBoundary.current = sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset + sStickyRef.current!.getBoundingClientRect().height;
          imageScaleScrollDistance.current = imageScaleBottomBoundary.current - imageScaleTopBoundary.current;
          //console.log('istb:', imageScaleTopBoundary.current, 'isbb:', imageScaleBottomBoundary.current);
          //imageScaleScrollDistance.current = imageScaleBottomBoundary.current - imageScaleTopBoundary.current;
          //original version
          //stickyHeaderContainer.current = window.pageYOffset + (sshcRef.current!.getBoundingClientRect().top - 55);
          //window.requestAnimationFrame(()=>{
            //imageScaleTopBoundary.current = sStickyRef.current!.getBoundingClientRect().top;
            sStickChildRef.current!.style.marginBottom = '';
            sStickChildRef.current!.style.top = '';
            ssh2cRef.current!.style.bottom = '';
            //console.log('find img:', sslidRef.current!.getBoundingClientRect().top);
            //original version
            //imageScaleTopBoundary.current = sStickyRef.current!.getBoundingClientRect().top + sp.current;//sStickyRef.current!.offsetTop;

          //});
          //imageScaleBottomBoundary.current = sStickyRef.current!.offsetTop + 1600;
          const ssmbicRefHeight = ssmbicRef.current!.offsetHeight;//.getBoundingClientRect().height;
          const ssbiecwRefHeight = ssbiecwRef.current!.offsetHeight;//.getBoundingClientRect().height;
          //console.log('ssmbic:', ssmbicRefHeight, 'wh:', wh.current);
          speedScrollDistance.current = ssmbicRefHeight;
          //original version
          //expanderYStart.current = stickyOffsetYStart.current = (ssbiecwRefHeight - ssmbicRefHeight) / 2;
          const mb /*expanderYStart.current = stickyOffsetYStart.current*/ = wh.current > ssmbicRefHeight ? (ssmbicRefHeight - wh.current) / 2 : ssmbicRefHeight - wh.current;
          expanderYMobile.current = expanderYStart.current = stickyOffsetYStart.current = mb < 0 ? Math.abs(mb) : -mb;
          //console.log('eys1:', expanderYStart.current);
          sshcRef.current!.style.marginBottom = `${mb}px`;

          //console.log('soy:', stickyOffsetYStart.current);
          setupScrollParameters(stickyOffsetYStart.current);

          //imageScaleTopBoundary.current = imageScaleTopBoundary.current - expanderYStart.current;
          //console.log('ist 3:', imageScaleTopBoundary.current, stickyOffsetYStart.current, sp.current, window.pageYOffset, ww.current);
          //imageScaleBottomBoundary.current = imageScaleBottomBoundary.current - expanderYStart.current;
          //imageScaleTopBoundary.current = imageScaleTopBoundary.current - expanderYStart.current;
          //imageScaleBottomBoundary.current = imageScaleBottomBoundary.current - expanderYStart.current;

          //ww.current = window.innerWidth;
          //console.log('ww:', ww.current);
          //dw.current = document.documentElement.clientWidth;
          //original version
          //ssbiecRef.current!.style.left = (dw.current - (dw.current * .8)) / 2 + 'px';
          ssbiecRef.current!.style.left = ww.current >= (768/* - scrollBarWidth.current*/) ? (ww.current - (ww.current * .8)) / 2 + 'px' : '0px';
          imgContainerWidth.current = ssbiecRef.current!.offsetWidth;
          imgContainerHeight.current = ssbiecRef.current!.offsetHeight;
          //console.log('find:', imgContainerWidth.current, imgContainerHeight.current);
          //original versions
          //const leftImgWidth = sslidRef.current!.getBoundingClientRect().width;
          //const leftImgHeight = sslidRef.current!.getBoundingClientRect().height;
          //const ssliSVGRefHeight = sslidSVGRef.current!.getBoundingClientRect().height;
          const leftImgWidth = (ww.current/* - 15*/) >= 768 ? sslidRef.current!.getBoundingClientRect().width : sslimRef.current!.getBoundingClientRect().width;
          const leftImgHeight = (ww.current/* - 15*/) >= 768 ? sslidRef.current!.getBoundingClientRect().height : sslimRef.current!.getBoundingClientRect().height;
          const ssliSVGRefHeight = (ww.current/* - 15*/) >= 768 ? sslidSVGRef.current!.getBoundingClientRect().height : sslimSVGRef.current!.getBoundingClientRect().height;
          const ssmbicRefWidth = ssmbicRef.current!.getBoundingClientRect().width;
          //console.log('liw:', leftImgWidth);
          //console.log('lih:', leftImgHeight);
          
          //original version
          //expanderScaleStart.current = expanderScaleCurrent.current = leftImgHeight / imgContainerHeight.current;
          setupBackdropContainerDimensions(imgContainerWidth.current, imgContainerHeight.current, leftImgWidth, leftImgHeight);
          //original version
          /*
          //expanderScaleStart.current = expanderScaleCurrent.current = ww.current > 950 ? leftImgHeight / imgContainerHeight.current : ssliSVGRefHeight / ssmbicRefWidth;
          expanderScaleStart.current = expanderScaleCurrent.current = leftImgHeight / imgContainerHeight.current;
          //console.log('ess:', expanderScaleStart.current);
          //const scaledImgContainerHeight = imgContainerHeight.current * expanderScaleStart.current;
          const scaledImgContainerHeight = window.parseFloat((imgContainerHeight.current * expanderScaleStart.current).toFixed(6));
          //console.log('sich:', scaledImgContainerHeight);
          expanderClipYStart.current = expanderClipYCurrent.current = (((scaledImgContainerHeight - leftImgHeight) / scaledImgContainerHeight) / 2) * 100;
          expanderClipYStart.current = expanderClipYStart.current < 0 ? 0 : expanderClipYStart.current;
          //console.log('ecys:', expanderClipYStart.current);
          expanderYStart.current = expanderYStart.current - ((expanderClipYStart.current / 100) * scaledImgContainerHeight);
          //console.log('eys after:', expanderYStart.current);
          //console.log('es:', expanderScaleStart.current);
          const scaledImgContainerWidth = imgContainerWidth.current * expanderScaleStart.current;
          //console.log('sicw:', scaledImgContainerWidth, imgContainerWidth.current, expanderScaleStart.current);
          //original version
          //expanderClipXStart.current = expanderClipXCurrent.current = (((scaledImgContainerWidth - leftImgWidth) / scaledImgContainerWidth) / 2) * 100;
          expanderClipXStart.current = (((scaledImgContainerWidth - leftImgWidth) / scaledImgContainerWidth) / 2) * 100;
          //console.log('ecxs start:', expanderClipXStart.current);
          expanderClipXStart.current = expanderClipXCurrent.current = expanderClipXStart.current < 0 ? 0 : expanderClipXStart.current;
          */
          //console.log('ecxs final:', expanderClipXStart.current);
          expanderClipXAdjustmentMultiplier.current = expanderClipXStart.current / scrollIncrease.current/*395*/;
          //original version
          //toutXAdjustment.current = leftImgWidth * 2;
          //they are duplicates from useEffect()
          toutXAdjustment.current = leftImgWidth / .47;
          toutXAdjustmentMultiplier.current = toutXAdjustment.current / scrollIncrease.current/*395*/;
          //original version
          //toutYAdjustmentMultiplier.current = 95 /395;
          toutYAdjustmentMultiplier.current = (wh.current * .1) / scrollIncrease.current/*395*/;
          expanderScaleAdjustment.current = 1 - expanderScaleStart.current;
          expanderScaleMultiplier.current = expanderScaleAdjustment.current / scrollIncrease.current/*395*/;
          imageSwipeAdjustmentMultiplier.current = 100 / scrollIncrease.current/*395*/;

          setupStreamWithSpeedCSSStyles();
          window.requestAnimationFrame(getLazyLoadImagesPositions);
          window.requestAnimationFrame(animateStreamWithSpeed);
          //getLazyLoadImagesPositions();
          //window.requestAnimationFrame(animateStreamWithSpeed);
          //animateStreamWithSpeed();
        });

      }

      
    }

    function setupBackdropContainerDimensions(bcw:number, bch:number, liw:number, lih:number):void {
      if(bch < bcw){
        const r1 = lih / bch;
        expanderScaleStart.current = expanderScaleCurrent.current = r1;
        const r2 = (r1 * bcw);
        const r3 = r2 - liw;
        const r4 = r3 / r2;
        const r5 = r4 / 2;
        expanderClipXStart.current = expanderClipXCurrent.current = r5 * 100;
        expanderClipYStart.current = expanderClipYCurrent.current = 0;
        expanderYStart.current = expanderYStart.current - ((expanderClipYStart.current / 100) * r1);

      } else {
        const r1 = liw / bcw;
        expanderScaleStart.current = expanderScaleCurrent.current = r1;
        const r2 = (r1 * bch);
        const r3 = r2 - lih;
        const r4 = r3 / r2;
        const r5 = r4 / 2;
        expanderClipYStart.current = expanderClipYCurrent.current = r5 * 100;
        expanderClipXStart.current = expanderClipXCurrent.current = 0;
        expanderYStart.current = expanderYStart.current - ((expanderClipYStart.current / 100) * r2);

      }

      //console.log('eys:', expanderYStart.current);

    }

    function setupStreamWithSpeedMobileSettings():void {
      window.requestAnimationFrame(()=>{
      //getLazyLoadImagesPositions();
      //console.log('img4w:', ssbiecRef.current!.offsetWidth);
      //console.log('img4h:', ssbiecRef.current!.offsetHeight);
      //console.log('img3:', ssbisemRef.current!.getBoundingClientRect().top);
      //console.log('img2:', (ssbifemsRef.current!.firstElementChild as SVGSVGElement).getBoundingClientRect().width);
      //console.log('img1w:', ssbifemsRef.current!.getBoundingClientRect().width);
      //console.log('img1h:', ssbifemsRef.current!.getBoundingClientRect().height);
      //console.log('img0w:', sslimRef.current!.getBoundingClientRect().width);
      //console.log('img0h:', sslimRef.current!.getBoundingClientRect().height);
      //const bmitd = ssbisemRef.current!.getBoundingClientRect().top - ssbifemsRef.current!.getBoundingClientRect().top;
      //console.log('img diff:', bmitd);
      ssbiecRef.current!.style.left = '0px';
      sshcRef.current!.style.marginBottom = '';
      //original version
      //imageScaleTopBoundary.current = sStickyRef.current!.getBoundingClientRect().top + sp.current;
      //console.log('ist 3:', imageScaleTopBoundary.current, sp.current);
      //original version
      //imageScaleBottomBoundary.current = sStickyRef.current!.getBoundingClientRect().top + 3305;
      //original version
      //imageScaleBottomBoundary.current = imageScaleTopBoundary.current + 3304;
      speedScrollDistance.current = ssmbicRef.current!.offsetHeight;

      stickyOffsetYStart.current = 0;

      //console.log('img1:', ssmbicRef.current!.getBoundingClientRect().top + window.pageYOffset);
      //console.log('img2:', ssmicwRef.current!.offsetTop);
      const bicet = ssmicwRef.current!.offsetTop; 
      //const sctp = bicet - bmitd;
      //sStickChildRef.current!.style.top = `-${sctp}px`;
      sStickChildRef.current!.style.marginBottom = '-237px';
      //original version
      //ssh2cRef.current!.style.bottom = '237px';
      ssh2cRef.current!.style.bottom = `${(wh.current * .12) + 60}px`;
      //ssh2cRef.current!.style.top = `${bicet}px`;
      //expanderYMobileEnd.current = expanderYMobileCurrent.current = sctp;
      imgContainerWidth.current = ssbiecRef.current!.offsetWidth;
      imgContainerHeight.current = ssbiecRef.current!.offsetHeight;
      const leftImgWidth = sslimRef.current!.offsetWidth;
      const leftImgHeight = sslimRef.current!.offsetHeight;

      setupBackdropContainerDimensions(imgContainerWidth.current, imgContainerHeight.current, leftImgWidth, leftImgHeight);
      /*original version
      const scaleImage = sslimRef.current!.getBoundingClientRect().width / ssbiecRef.current!.offsetWidth;
      //console.log('si:', scaleImage);
      expanderScaleStart.current = expanderScaleCurrent.current = scaleImage;
      console.log('ess:', expanderScaleStart.current);
      const scaleImageHeight = ssbiecRef.current!.offsetHeight * scaleImage;
      //console.log('sih:', scaleImageHeight);
      const bmitd = (scaleImageHeight - sslimRef.current!.getBoundingClientRect().height) / 2;
      //console.log('bmitd:', bmitd);
      sStickChildRef.current!.style.top = `-${bicet - bmitd - 50}px`;
      expanderYMobile.current = bicet - bmitd;
      const clipY = (((scaleImageHeight - sslimRef.current!.getBoundingClientRect().height) / scaleImageHeight) / 2) * 100;
      //console.log('clipY:', clipY);
      expanderClipYStart.current = expanderClipYCurrent.current = clipY;
      */

      const bmitd = ((expanderScaleStart.current * imgContainerHeight.current) - sslimRef.current!.getBoundingClientRect().height) / 2;
      //original version
      //const bmitd = (scaleImageHeight - sslimRef.current!.getBoundingClientRect().height) / 2;
      //console.log('bmitd:', bmitd);
      //original version
      //sStickChildRef.current!.style.top = `-${bicet - bmitd - 50}px`;
      sStickChildRef.current!.style.top = `-${bicet - bmitd}px`;
      expanderYStart.current = expanderYMobile.current = bicet - bmitd;

      //add new top boundary calculations
      //console.log('p:', sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset);
      //console.log('ph:', sStickyRef.current!.offsetHeight);
      //console.log('pb:', sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset + sStickyRef.current!.offsetHeight);
      //console.log('cb:', sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset + sStickChildRef.current!.offsetHeight);
      //console.log('ct:', sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset);
      //console.log('ch:', sStickChildRef.current!.offsetHeight);
      const pt = sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset;
      const ph = sStickyRef.current!.offsetHeight;
      const pb = sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset + sStickyRef.current!.offsetHeight;
      const cb = sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset + sStickChildRef.current!.offsetHeight;
      const ct = sStickyRef.current!.getBoundingClientRect().top + window.pageYOffset;
      const ch = sStickChildRef.current!.offsetHeight;

      const act = ct + expanderYMobile.current;
      //console.log('act:', act, cb);
      //237px for negative botom margin for child and 70% of scroll distance to shrink scroll distance
      const sd = (pb - cb + 237) * .60;
      //console.log('sd:', sd);
      const tsd = act + sd;
      //console.log('tsd:', tsd);
      
      imageScaleTopBoundary.current = act;
      //console.log('istb:', imageScaleTopBoundary.current);
      mobileScrollIncrease.current = tsd;
      mobileScrollDistance.current = sd;
      imageScaleBottomBoundary.current = pb; 
      //console.log('msd:', mobileScrollDistance.current);
      imageScaleScrollDistance.current = pb - act;
      //console.log('issd:', imageScaleScrollDistance.current);

      imageSwipe.current = 100;
      expanderScaleAdjustment.current = 1 - expanderScaleStart.current;
      expanderScaleMultiplier.current = expanderScaleAdjustment.current / mobileScrollDistance.current /*425*/;
      imageSwipeAdjustmentMultiplier.current = 100 / mobileScrollDistance.current /*425*/;
      expanderClipXAdjustmentMultiplier.current = expanderClipXStart.current / mobileScrollDistance.current /*425*/;
      expanderClipYAdjustmentMultiplier.current = expanderClipYStart.current / mobileScrollDistance.current /*425*/;
      const ssw = sswRef.current as HTMLDivElement;
      ssw.style.setProperty('--expander-clip-y', `${expanderClipYStart.current}%`);
      ssw.style.setProperty('--expander-y-mobile', `${expanderYMobile.current}px`);
      ssw.style.setProperty('--expander-scale', `${expanderScaleStart.current}`);
      ssw.style.setProperty('--image-swipe', `${imageSwipe.current}%`);
      ssw.style.setProperty('--sticky-offset-y', `${stickyOffsetYStart.current}px`);

      setupStreamWithSpeedCSSStyles();
      window.requestAnimationFrame(getLazyLoadImagesPositions);
      window.requestAnimationFrame(animateStreamWithSpeed);
      //animateStreamWithSpeed();
    });

    }

    function lazyLoadImages():void {
      //const wh = window.innerHeight;
      //console.log('ww:', ww.current);
      //console.log('lr:', sp.current, wh, leftMiddleImgHeight.current);
      if(sp.current + wh.current < leftMiddleImgHeight.current){
        sslicwRef.current!.classList.remove('inview');
      } else {
        sslicwRef.current!.classList.add('inview');
      }

      if(sp.current + wh.current < rightMiddleImgHeight.current){
        ssricwRef.current!.classList.remove('inview');
      } else {
        ssricwRef.current!.classList.add('inview');
      }

      if(ww.current < 768){
        if(sp.current + wh.current < centerMiddleImgHeight.current){
          //console.log('result:', sp.current + wh.current, centerMiddleImgHeight.current);
          ssbiewRef.current!.classList.remove('inview');
        } else {
          ssbiewRef.current!.classList.add('inview');
        }
      } else {
        if(sp.current + wh.current < centerMiddleImgHeight.current){
          //console.log('result:', sp.current + wh, centerMiddleImgHeight.current);
          ssbiesRef.current!.classList.remove('inviews');
        } else {
          ssbiesRef.current!.classList.add('inviews');
        }
      }


    }

    function getLazyLoadImagesPositions():void {
      //console.log('lazy');
      //original version
      //const wh = window.innerHeight;
      const parent = Math.abs(sStickyRef.current!.getBoundingClientRect().top);
      const child = Math.abs(sStickChildRef.current!.getBoundingClientRect().top);
      //console.log('p:', parent, 'ch:', child)
      const pcDiff = parent - child;
      //console.log('p:', parent, 'c:', child, sp.current, expanderYStart.current);
      let rmit = 0;
      let lmit = 0;
      let cmit = 0;
      //console.log('ww:', ww.current, window.pageYOffset, sStickyRef.current!.getBoundingClientRect().top, sStickChildRef.current!.getBoundingClientRect().top);
      //console.log('tm:', tout2MobileY.current/100, ssmicwRef.current!.getBoundingClientRect().top, window.pageYOffset, wh);
      //clear all existing inview classes
      sslicwRef.current!.classList.remove('inview');
      ssricwRef.current!.classList.remove('inview');
      ssbiewRef.current!.classList.remove('inview');
      ssbiesRef.current!.classList.remove('inviews');

      if(ww.current < 768){
        //mobile images
        //refresh page
        if(tout2MobileY.current === 0){
          rmit = ssrimRef.current!.getBoundingClientRect().top - pcDiff;
          lmit = sslimRef.current!.getBoundingClientRect().top - pcDiff;
          cmit = ssmimRef.current!.getBoundingClientRect().top - pcDiff;
          //console.log('find rmit:', rmit, lmit, cmit, window.pageYOffset);
        }

        if(tout2MobileY.current > 0){
          rmit = ssrimRef.current!.getBoundingClientRect().top - pcDiff;
          lmit = sslimRef.current!.getBoundingClientRect().top - pcDiff;
          cmit = ssmimRef.current!.getBoundingClientRect().top - pcDiff - ((tout2MobileY.current / 100) * wh.current);
          //console.log('find rmit:', rmit, lmit, cmit, window.pageYOffset, tout2MobileY.current, wh);
        }

        if((rmit < wh.current || rmit > wh.current) && sp.current === 0){
          rightMiddleImgHeight.current = rmit + (ssrimRef.current!.offsetHeight / 2);
          //console.log('rmi:', rightMiddleImgHeight.current);
        }
        if((lmit < wh.current || lmit > wh.current) && sp.current === 0){
          leftMiddleImgHeight.current = lmit + (sslimRef.current!.offsetHeight / 2);
          //console.log('lmi:', leftMiddleImgHeight.current);
        }
        if((cmit < wh.current || cmit > wh.current) && sp.current === 0){
          centerMiddleImgHeight.current = cmit + (ssmimRef.current!.offsetHeight / 2);
          //console.log('cmi:', centerMiddleImgHeight.current);
        }
        if((rmit < wh.current || rmit > wh.current) && sp.current > 0){
          rightMiddleImgHeight.current = (rmit + sp.current) + (ssrimRef.current!.offsetHeight / 2);
          //console.log('rmi:', rightMiddleImgHeight.current);
        }
        if((lmit < wh.current || lmit > wh.current) && sp.current > 0){
          leftMiddleImgHeight.current = (lmit + sp.current) + (sslimRef.current!.offsetHeight / 2);
          //console.log('lmi:', leftMiddleImgHeight.current);
        }
        if((cmit < wh.current || cmit > wh.current) && sp.current > 0){
          centerMiddleImgHeight.current = (cmit + sp.current) + (ssmimRef.current!.offsetHeight / 2);
          //console.log('cmi:', centerMiddleImgHeight.current);
        }

      } else {
        
        //original version
        const shcBottomPosition = sshcRef.current!.getBoundingClientRect().bottom;
        const shcBottomPosition2 = sshcRef.current!.getBoundingClientRect().top + sshcRef.current!.offsetHeight;
        //console.log('shc:', shcBottomPosition, shcBottomPosition2, sp.current, ssridRef.current!.getBoundingClientRect().top, ssricRef.current!.getBoundingClientRect().top, ssricwRef.current!.getBoundingClientRect().top, ssmbicRef.current!.getBoundingClientRect().top, ssbiecwRef.current!.getBoundingClientRect().top);
        //desktop images for refreshing the page
        if(pcDiff === 0){
          rmit = shcBottomPosition;
          lmit = shcBottomPosition;
          cmit = shcBottomPosition;
          //original versions
          //rmit = ssridRef.current!.getBoundingClientRect().top;
          //lmit = sslidRef.current!.getBoundingClientRect().top;
          //console.log('rmit 1:', rmit, window.pageYOffset, sp.current, stickyOffsetYStart.current);
          //cmit = ssmidRef.current!.getBoundingClientRect().top;
        } 
        
        if(pcDiff > 0 && shcBottomPosition >= 0) {
          rmit = shcBottomPosition;
          lmit = shcBottomPosition;
          cmit = shcBottomPosition;
          //original versions
          //rmit = ssridRef.current!.getBoundingClientRect().top - pcDiff;// - stickyOffsetYStart.current;
          //console.log('rmit 1:', rmit, window.pageYOffset, ssridRef.current!.getBoundingClientRect().top, sp.current, pcDiff, stickyOffsetYStart.current, shcBottomPosition);
          //lmit = sslidRef.current!.getBoundingClientRect().top - pcDiff;// - stickyOffsetYStart.current;
          //cmit = ssmidRef.current!.getBoundingClientRect().top - pcDiff;// - stickyOffsetYStart.current;
        }

        if(pcDiff > 0 && shcBottomPosition < 0 && !resizePage.current) {
          rmit = shcBottomPosition - stickyOffsetYStart.current;
          lmit = shcBottomPosition - stickyOffsetYStart.current;
          cmit = shcBottomPosition - stickyOffsetYStart.current;
          //original versions
          //rmit = ssridRef.current!.getBoundingClientRect().top - pcDiff - stickyOffsetYStart.current;
          //console.log('rmit 1:', rmit, window.pageYOffset, ssridRef.current!.getBoundingClientRect().top, sp.current, pcDiff, stickyOffsetYStart.current, sshcRef.current!.getBoundingClientRect().bottom);
          //lmit = sslidRef.current!.getBoundingClientRect().top - pcDiff - stickyOffsetYStart.current;
          //cmit = ssmidRef.current!.getBoundingClientRect().top - pcDiff - stickyOffsetYStart.current;
        }

        //desktop images for resizing the page
        if(pcDiff > 0 && shcBottomPosition < 0 && resizePage.current) {
          rmit = shcBottomPosition;
          lmit = shcBottomPosition;
          cmit = shcBottomPosition;
          //original version
          //rmit = ssridRef.current!.getBoundingClientRect().top - pcDiff + stickyOffsetYStart.current;
          //console.log('rmit 1:', rmit, window.pageYOffset, ssridRef.current!.getBoundingClientRect().top, sp.current, pcDiff, stickyOffsetYStart.current, sshcRef.current!.getBoundingClientRect().bottom);
          //lmit = sslidRef.current!.getBoundingClientRect().top - pcDiff + stickyOffsetYStart.current;
          //cmit = ssmidRef.current!.getBoundingClientRect().top - pcDiff + stickyOffsetYStart.current;
        }

        if((rmit < wh.current || rmit > wh.current) && sp.current === 0){
          rightMiddleImgHeight.current = rmit + (ssridRef.current!.offsetHeight / 2);
          //console.log('rmi:', rightMiddleImgHeight.current);
        }
        if((lmit < wh.current || lmit > wh.current) && sp.current === 0){
          leftMiddleImgHeight.current = lmit + (sslidRef.current!.offsetHeight / 2);
          //console.log('lmi:', leftMiddleImgHeight.current);
        }
        if((cmit < wh.current || cmit > wh.current) && sp.current === 0){
          centerMiddleImgHeight.current = cmit + (ssmidRef.current!.offsetHeight / 2);
          //console.log('cmi:', centerMiddleImgHeight.current);
        }
        if((rmit < wh.current || rmit > wh.current) && sp.current > 0){
          rightMiddleImgHeight.current = (rmit + sp.current) + (ssridRef.current!.offsetHeight / 2);
          //console.log('rmi:', rightMiddleImgHeight.current, rmit, sp.current, ssridRef.current!.offsetHeight / 2);
        }
        if((lmit < wh.current || lmit > wh.current) && sp.current > 0){
          leftMiddleImgHeight.current = (lmit + sp.current) + (sslidRef.current!.offsetHeight / 2);
          //console.log('lmi:', leftMiddleImgHeight.current);
        }
        if((cmit < wh.current || cmit > wh.current) && sp.current > 0){
          centerMiddleImgHeight.current = (cmit + sp.current) + (ssmidRef.current!.offsetHeight / 2);
          //console.log('cmi:', centerMiddleImgHeight.current);
        }
      }

      //console.log('result rmi:', rightMiddleImgHeight.current, 'lmi:', leftMiddleImgHeight.current, 'cmi:', centerMiddleImgHeight.current, sp.current, window.pageYOffset, resizePage.current);
    }

    function setupStreamWithSpeedCSSStyles():void {
      //console.log('stream with speed css styles');
      const ssw = sswRef.current as HTMLDivElement;
      //vs.style.setProperty('--single-video-y', `${singleVideoY}px`);
      //window.requestAnimationFrame(()=>{
        ssw.style.setProperty('--image-swipe', `${imageSwipe.current}%`);
        ssw.style.setProperty('--sticky-offset-y', `${stickyOffsetYStart.current}px`);
        ssw.style.setProperty('--tout1-y', `${tout1Y.current}px`);
        ssw.style.setProperty('--tout3-y', `${tout3Y.current}px`);
        ssw.style.setProperty('--tout1-x', `${tout1X.current}px`);
        ssw.style.setProperty('--tout3-x', `${tout3X.current}px`);
        ssw.style.setProperty('--tout2-mobile-y', `${tout2MobileY.current}vh`);
        ssw.style.setProperty('--expander-clip-x', `${expanderClipXStart.current}%`);
        ssw.style.setProperty('--expander-clip-y', `${expanderClipYStart.current}%`);
        ssw.style.setProperty('--expander-scale', `${expanderScaleStart.current}`);
        ssw.style.setProperty('--expander-y', `${expanderYStart.current}px`);
        ssw.style.setProperty('--expander-y-mobile', `${expanderYMobile.current}px`);
        ssw.style.setProperty('--stagger-factor-expand', `${staggerFactorExpand.current}`);
        ssw.style.setProperty('--image-scale', `${imageScale.current}`);
        ssw.style.setProperty('--header2-opacity', `${header2Opacity.current}`);
        ssw.style.setProperty('--image-blur-opacity', `${imageBlurOpacity.current}`);
      //});

    }


    return (
        <div ref={sswRef} className="sticky-style-wrapper">
          <div className="ruler"></div>
          <div className="ruler2"></div>
          <div ref={sshcRef} className="sws-sticky-header-container">
              <div className="sws-sticky-header-msg1-container">
                  <span className="sws-sticky-header-msg1">
                      Stream with speed.
                  </span>
              </div>
              <div className="sws-sticky-header-msg2-container">
                  <div className="sws-sticky-header-msg2">
                      One Nest Wifi router with one Wifi point is strong enough to handle up to 200 connected devices, and fast enough to stream multiple 4K videos at a time.
                      <sup>3</sup>
                  </div>
              </div>
          </div>
          <div ref={sStickyRef} className="sws-sticky-parent">
              <div ref={sStickChildRef}  className="sws-sticky-child">
                  <div className="sws-sticky-content">
                      <div ref={ssh2cRef} className="sws-sticky-header2-container">
                          <div className="sws-sticky-header2-text1-container">
                              <span className="sws-sticky-header2-text1">
                                  Security that’s a step ahead.
                              </span>
                          </div>
                          <div className="sws-sticky-header2-text2-container">
                              <span className="sws-sticky-header2-text2">
                                  Automatic updates and an advanced security chip in the Nest Wifi router help your network stay safe.
                              </span>
                          </div> 
                      </div>
                      <div ref={ssbiecwRef} className="sws-sticky-backdrop-image-expander-container-wrapper">
                        <div ref={ssbiesRef} className="sws-sticky-backdrop-image-expander-shell">
                          <div ref={ssbiecRef} className="sws-sticky-backdrop-image-expander-container">
                            <div ref={ssbiewRef} className="sws-sticky-backdrop-image-expander-wrapper">
                              <div className="sws-sticky-backdrop-image-shelf-expander-desktop">
                                <svg viewBox="0 0 2800 2102"></svg>
                              </div>
                              <div ref={ssbisemRef} className="sws-sticky-backdrop-image-shelf-expander-mobile">
                                <svg viewBox="0 0 2800 2102"></svg>
                              </div>
                              <div ref={ssbifedsRef} className="sws-sticky-backdrop-image-family-expander-desktop swiper">
                                <svg viewBox="0 0 2312 1200"></svg>
                              </div>
                              <div ref={ssbifemsRef} className="sws-sticky-backdrop-image-family-expander-mobile swiper">
                                <svg viewBox="0 0 640 1138"></svg>
                              </div>
                              <div className="sws-sticky-backdrop-image-expander-overlay"></div>
                            </div>
                          </div>
                        </div>
                        <div ref={ssmbicRef} className="sws-sticky-middle-backdrop-image-container">
                          <div ref={sslicwRef} className="sws-sticky-left-image-container-wrapper">
                            <div ref={sslicRef} className="sws-sticky-left-image-container">
                              <div ref={sslidRef} className="sws-sticky-left-image-desktop">
                                <svg ref={sslidSVGRef} viewBox="0 0 660 660" width="100%"></svg>
                              </div>
                              <div ref={sslimRef} className="sws-sticky-left-image-mobile">
                                <svg ref={sslimSVGRef} viewBox="0 0 420 420" width="100%"></svg>
                              </div>
                            </div>
                            <div ref={ssicwlRef} className="sws-sticky-image-content-wrapper">
                              <div className="sws-sticky-image-content-heading-container">
                                <span className="sws-sticky-image-content-heading">Connects.</span>
                              </div>
                              <div className="sws-sticky-image-content-caption-container">
                                <div className="sws-sticky-image-content-caption">
                                  Connects you to the fastest Wi‑Fi point and keeps buffering at bay from room to room.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div ref={ssmicwRef} className="sws-sticky-middle-image-container-wrapper">
                            <div className="sws-sticky-middle-image-container">
                              <div ref={ssmidRef} className="sws-sticky-middle-image-desktop">
                                <svg viewBox="0 0 660 660" width="100%"></svg>
                              </div>
                              <div ref={ssmimRef} className="sws-sticky-middle-image-mobile">
                                <svg viewBox="0 0 420 420" width="100%"></svg>
                              </div>
                            </div>
                            <div ref={ssmicontwRef} className="sws-sticky-middle-image-content-wrapper">
                              <div className="sws-sticky-image-content-heading-container">
                                <span className="sws-sticky-image-content-heading">Optimizes.</span>
                              </div>
                              <div className="sws-sticky-image-content-caption-container">
                                <div className="sws-sticky-image-content-caption">
                                  Puts your network on the clearest channel and fastest band to avoid congestion.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div ref={ssricwRef} className="sws-sticky-right-image-container-wrapper">
                            <div ref={ssricRef} className="sws-sticky-right-image-container">
                              <div ref={ssridRef} className="sws-sticky-right-image-desktop">
                                <svg viewBox="0 0 660 660" width="100%"></svg>
                              </div>
                              <div ref={ssrimRef} className="sws-sticky-right-image-mobile">
                                <svg viewBox="0 0 420 420" width="100%"></svg>
                              </div>
                            </div>
                            <div ref={ssicwrRef} className="sws-sticky-image-content-wrapper">
                              <div className="sws-sticky-image-content-heading-container">
                                <span className="sws-sticky-image-content-heading">Updates.</span>
                              </div>
                              <div className="sws-sticky-image-content-caption-container">
                                <div className="sws-sticky-image-content-caption">
                                  Regular software updates improve your Wi‑Fi performance over time.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    );
});

export default StreamWithSpeedComponent;
import React, {useRef, useEffect, useState} from 'react';
import GoogleStoreNav from './GoogleStoreNav';
import NestWifiNav from './NestWifiNav';
import './NavComponent.css';

interface Props {}

const NavComponent:React.FunctionComponent<Props> = (props:Props)=>{
    const [nestWifiNavRef, setNestWifiNavRef] = useState<HTMLDivElement|null>(null);
    //const nestWifiNavRef = useRef<React.RefObject<HTMLDivElement>[]>([]);
    const overlayRef = useRef<HTMLDivElement>(null);

    function getNestWifiNavRef(nwnr:HTMLDivElement):void {
        //console.log('nwr:', nwnr);
        setNestWifiNavRef(nwnr);
    }

    return (<div className="nav-component-wrapper">
                <GoogleStoreNav nestWifiNavRef={nestWifiNavRef!} overlayRef={overlayRef}/>
                <NestWifiNav getNestWifiNavRef={getNestWifiNavRef}/>
                <div ref={overlayRef} className="overlay hide"></div>
            </div>);
}

export default NavComponent;
import React, {useRef, useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import throttle from 'lodash.throttle';
import getMobileStatus from './get-mobile-status';
import './SlideShow.css';

interface AdjCanvasDimensions {
  widthStart:number;
  widthEnd:number;
  widthMultiplier:number;
  heightStart:number;
  heightEnd:number;
  heightMultiplier:number;
  topStart:number;
  topEnd:number;
  topMultiplier:number;
  posXStart:number;
  posXEnd:number;
  posXMultiplier:number;
  posYStart:number;
  posYEnd:number;
  posYMultiplier:number;
  scrollDistanceBoundary:number;
}

interface Props {
  updateShowLayout:(showLayout:boolean)=>void;
}
type Ref = {
    slideShowWrapperRef:React.RefObject<HTMLDivElement>,
    wssic:React.RefObject<HTMLDivElement>,
    canvasWrapperRef:React.RefObject<HTMLDivElement>,
    canvasRef:React.RefObject<HTMLCanvasElement>,
    wssihc:React.RefObject<HTMLDivElement>,
    wssihtc1:React.RefObject<HTMLDivElement>,
    wssihcc1:React.RefObject<HTMLDivElement>,
    wssihtc2:React.RefObject<HTMLDivElement>,
    wssihcc2:React.RefObject<HTMLDivElement>,
    gailc:React.RefObject<HTMLDivElement>,
    slideShowImgs:React.RefObject<HTMLImageElement[]>
};

const WifiSlideShowComponent = forwardRef<Ref, Props>((props, ref)=>{

    const slideShowWrapperRef = useRef<HTMLDivElement>(null);
    const wssic = useRef<HTMLDivElement>(null);
    const wssiw = useRef<HTMLDivElement>(null);
    const canvasWrapperRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const wssihc = useRef<HTMLDivElement>(null);
    const wssihtc1 = useRef<HTMLDivElement>(null);
    const wssihcc1 = useRef<HTMLDivElement>(null);
    const wssihtc2 = useRef<HTMLDivElement>(null);
    const wssihcc2 = useRef<HTMLDivElement>(null);
    const gailc = useRef<HTMLDivElement>(null);
    const wssimc = useRef<HTMLDivElement>(null);

    const dw = useRef<number>(0);
    const wh = useRef<number>(0);
    const ww = useRef<number>(0);
    const ticked = useRef<boolean>(false);
    const sp = useRef<number>(0);
    const psp = useRef<number>(0);
    const canvasY = useRef<number>(0);
    //const wssic = useRef<HTMLDivElement>(null);
    const slideShowImgContainerTopPosition = useRef<number>(0);
    const slideShowImgContainerHeight = useRef<number>(0);
    //const wssihc = useRef<HTMLDivElement>(null);
    const slideShowTopBoundary = useRef<number>(0);
    const slideShowMsgAdjust = useRef<number>(0);
    const slideShowImgs = useRef<HTMLImageElement[]>([]);
    const slideShowImgsMobile = useRef<HTMLImageElement[]>([]);
    const imgCounter = useRef<number>(0);
    const totalImgs = useRef<number>(0);
    const scrollDirection = useRef<string>('none');
  
    //adjustment variables
    const imgCounterMultiplier = useRef<number>(0);
    const slideShowImgContainerMultiplier = useRef<number>(0);
    //const [updateShowLayout, setUpdateShowLayout] = useState<(showLayout:boolean)=>void>(props.updateShowLayout);
    const canvasWidthStart = useRef<number>(0);
    const canvasWidthEnd = useRef<number>(0);
    const canvasWidthCurrent = useRef<number>(0);
    const canvasHeightStart = useRef<number>(0);
    const canvasHeightEnd = useRef<number>(0);
    const canvasTopStart = useRef<number>(0);
    const canvasTopEnd = useRef<number>(0);
    const canvasPosXStart = useRef<number>(0);
    const canvasPosXEnd = useRef<number>(0);
    const canvasPosYStart = useRef<number>(0);
    const canvasPosYEnd = useRef<number>(0);
    const canvasWidthMultiplier = useRef<number>(0);
    const canvasHeightMultiplier = useRef<number>(0);
    const canvasTopMultiplier = useRef<number>(0);
    const canvasPosXMultiplier = useRef<number>(0);
    const canvasPosYMultiplier = useRef<number>(0);
    const canvasScrollDistanceBoundary = useRef<number>(0);
    const isMobile = useRef<boolean>(false);

    const portrait = useRef<MediaQueryList[]>([]);

    const pw = useRef<number>(0);


    useImperativeHandle(ref , ()=>{
        return ({
            get slideShowWrapperRef(){
                return slideShowWrapperRef;
            },
            get wssic(){
                return wssic;
            },
            get canvasWrapperRef(){
                return canvasWrapperRef;
            },
            get canvasRef(){
                return canvasRef;
            },
            get wssihc(){
                return wssihc;
            },
            get wssihtc1(){
                return wssihtc1;
            },
            get wssihcc1(){
                return wssihcc1;
            },
            get wssihtc2(){
                return wssihtc2;
            },
            get wssihcc2(){
                return wssihcc2;
            },
            get gailc(){
                return gailc;
            },
            get slideShowImgs(){
                return slideShowImgs;
            }
        });
    });

    useEffect(()=>{

      //isMobile.current = getMobileStatus(window.innerWidth, window.innerHeight) || window.matchMedia('(max-width:280px)').matches;
      if(Math.min(window.screen.width, window.screen.height) < 768){
        isMobile.current = true;
      }
      //console.log('isMobile:', isMobile.current);

      //canvasRef.current!.style.width = `${ww.current - 15}px`;
      //canvasRef.current!.style.height = `${wh.current}px`;

      slideShowTopBoundary.current = slideShowWrapperRef.current!.getBoundingClientRect().top + window.pageYOffset;
      //console.log('sstb:', slideShowTopBoundary.current, window.pageYOffset);

      if(!isMobile.current){
        ww.current = window.innerWidth;
        wh.current = window.innerHeight;
        dw.current = document.documentElement.clientWidth;
  
        /*
        canvasWrapperRef.current!.style.height = `${wh.current}px`;
        canvasWrapperRef.current!.style.top = '0px';
        canvasWrapperRef.current!.style.marginBottom = '0px';
        */
      
      } else {
        ww.current = window.screen.width;
        wh.current = window.screen.height;
        dw.current = window.screen.width;
        /*
        canvasWrapperRef.current!.style.height = `${wh.current}px`;
        canvasWrapperRef.current!.style.top = '0px';
        canvasWrapperRef.current!.style.marginBottom = '0px';
        */
      }

      ww.current = document.documentElement.clientWidth;
      wh.current = document.documentElement.clientHeight;
      dw.current = document.documentElement.clientWidth;

      //console.log('ww:', ww.current, 'wh:', wh.current);

      canvasWrapperRef.current!.style.height = `${wh.current}px`;
      canvasWrapperRef.current!.style.top = '0px';
      canvasWrapperRef.current!.style.marginBottom = '0px';

      wssiw.current!.style.background = (ww.current === 1024) ? '#e5ebf2' : '';


      adjustCaptionContainerWidth();

      //original version
      //adjustCaptionContainerWidth();

      slideShowImgContainerTopPosition.current = wssic.current!.getBoundingClientRect().top + window.pageYOffset;
      //console.log('ssictb:', slideShowImgContainerTopPosition.current);
      slideShowImgContainerHeight.current = wssic.current!.scrollHeight;

      slideShowImgContainerMultiplier.current = 12 / 340;

      if(ww.current >= 1025){

        //set initial css styles on canvas element
        const {widthStart:ws, widthEnd:we, widthMultiplier:awm, heightStart:hs, heightEnd:he, heightMultiplier:ahm, 
          topStart:ts, topEnd:te, topMultiplier:atm, posXStart:pxs, posXEnd:pxe, posXMultiplier:pxm,
          posYStart:pys, posYEnd:pye, posYMultiplier:pym, scrollDistanceBoundary:sdb} = getAdjCanvasDimensions();
        canvasWidthStart.current = ws;
        canvasWidthEnd.current = we;
        canvasWidthMultiplier.current = awm;
        canvasHeightStart.current = hs;
        canvasHeightEnd.current = he;
        canvasHeightMultiplier.current = ahm;
        canvasTopStart.current = ts;
        canvasTopEnd.current = te;
        canvasTopMultiplier.current = atm;
        canvasPosXStart.current = pxs;
        canvasPosXEnd.current = pxe;
        canvasPosXMultiplier.current = pxm;
        canvasPosYStart.current = pys;
        canvasPosYEnd.current = pye;
        canvasPosYMultiplier.current = pym;
        canvasScrollDistanceBoundary.current = sdb;
        //console.log('wstart:', ws, 'wend:', we, 'hstart:', canvasHeightStart.current, 'hEnd:', canvasHeightEnd.current,
                   // 'heightMultiplier:', atm, 'posXStart:', pxs, 'posXEnd:', pxe, 'posYStart:', pys, 'posYEnd:', pye, sdb);

        /*original version
        imgCounterMultiplier.current = (ww.current >= 1550) ? (90 / 1900) : (82 / 1900);
        //totalImgs.current = (window.innerWidth >= 1550) ? 90 : 82;
        totalImgs.current = (ww.current >= 1550) ? 90 : 82;
        slideShowMsgAdjust.current = (ww.current >= 1500) ? (24 * 9) : (24 * 3);
        */

        //imgCounterMultiplier.current = (ww.current <= 1550) ? (90 / 1900) : (82 / 1900);
        imgCounterMultiplier.current = 90 / canvasScrollDistanceBoundary.current/*1900*/;
        //totalImgs.current = (window.innerWidth >= 1550) ? 90 : 82;
        //totalImgs.current = (ww.current <= 1550) ? 90 : 82;
        totalImgs.current = 90;
        slideShowMsgAdjust.current = (ww.current >= 1516) ? (24 * 9) : (24 * 3);
  
       
        if(window.pageYOffset < slideShowTopBoundary.current){
          imgCounter.current = 0;
        } else if(window.pageYOffset >= slideShowTopBoundary.current && window.pageYOffset <= slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/){
          imgCounter.current = (Math.abs(totalImgs.current - Math.floor(((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - window.pageYOffset) * imgCounterMultiplier.current))) - 1; 
        } else if(window.pageYOffset > slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/){
          imgCounter.current = totalImgs.current - 1;
        }
        //console.log('ic:', imgCounter.current, totalImgs.current);
  
        //load desktop images initially to store for desktop use
        getSlideShowImagesDesktop(imgCounter.current, true)
        .then((results:HTMLImageElement[])=>{
          //console.log('results desktop images:', results);
          setupWifiSlideShowCSSStyles();
          //ticked.current = false;
          wifiSlideShowScrollHandler();
          //previous version
          //window.addEventListener('scroll', throttle(wifiSlideShowScrollHandler,100));
          window.addEventListener('scroll', wifiSlideShowScrollHandler);
        })
        .catch((err:Error)=>console.log('error loading desktop images:', err));

        //load mobile images initially to store for mobile use
        getSlideShowImagesMobile(imgCounter.current, false)
        .then((results:HTMLImageElement[])=>{
          //console.log('results mobile images:', results);
        })
        .catch((err:Error)=>console.log('error loading mobile images:', err));

      } else {
        
        //set initial css styles on canvas element
        const {widthStart:ws, widthEnd:we, widthMultiplier:awm, heightStart:hs, heightEnd:he, heightMultiplier:ahm, 
              topStart:ts, topEnd:te, topMultiplier:atm, posXStart:pxs, posXEnd:pxe, posXMultiplier:pxm,
              posYStart:pys, posYEnd:pye, posYMultiplier:pym, scrollDistanceBoundary:sdb} = getAdjCanvasDimensions();
        canvasWidthStart.current = ws;
        canvasWidthEnd.current = we;
        canvasWidthMultiplier.current = awm;
        canvasHeightStart.current = hs;
        canvasHeightEnd.current = he;
        canvasHeightMultiplier.current = ahm;
        canvasTopStart.current = ts;
        canvasTopEnd.current = te;
        canvasTopMultiplier.current = atm;
        canvasPosXStart.current = pxs;
        canvasPosXEnd.current = pxe;
        canvasPosXMultiplier.current = pxm;
        canvasPosYStart.current = pys;
        canvasPosYEnd.current = pye;
        canvasPosYMultiplier.current = pym;
        canvasScrollDistanceBoundary.current = sdb;
        //console.log('wstart:', ws, 'wend:', we, 'hstart:', canvasHeightStart.current, 'hEnd:', canvasHeightEnd.current,
                     //'heightMultiplier:', atm, 'posXStart:', pxs, 'posXEnd:', pxe, 'posYStart:', pys, 'posYEnd:', pye, sdb);
        
        //const targetHeadingContainerTop = wssihc.current!.getBoundingClientRect().top - 80;
        //const imgSpaceAboveImgHeight = (canvasHeightEnd.current * .555);
        //const imgHeightDiff = targetHeadingContainerTop - imgSpaceAboveImgHeight;
        //console.log('height diff:', imgHeightDiff, 'pye:', pye < 0 ? pye : 0, 'result:', imgHeightDiff + (pye < 0 ? Math.abs(pye) : 0), wssihc.current!.getBoundingClientRect().top, sdb);
        //heading container top should be 616px and is showing 601 at lower widths? Why??
        //const canvasTopPosition = pye < 0 ? Math.abs(pye) : 0;
        //canvasRef.current!.style.top = `${}px`;

        imgCounterMultiplier.current = 70 / canvasScrollDistanceBoundary.current; //1900;
        //console.log('icm:', imgCounterMultiplier.current);
        //totalImgs.current = (window.innerWidth >= 1550) ? 90 : 82;
        totalImgs.current = 70;
        slideShowMsgAdjust.current = 24 * 3;
       
        if(window.pageYOffset < slideShowTopBoundary.current){
          imgCounter.current = 0;
        } else if(window.pageYOffset >= slideShowTopBoundary.current && window.pageYOffset <= slideShowTopBoundary.current + canvasScrollDistanceBoundary.current /*19000*/){
          imgCounter.current = (Math.abs(totalImgs.current - Math.floor(((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - window.pageYOffset) * imgCounterMultiplier.current))) - 1; 
        } else if(window.pageYOffset > slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/){
          imgCounter.current = totalImgs.current - 1;
        }
        //console.log('ic:', imgCounter.current, window.pageYOffset, sp.current, slideShowTopBoundary.current);

        //load mobile images initially to store for mobile use
        getSlideShowImagesMobile(imgCounter.current, true)
        .then((results:HTMLImageElement[])=>{
          //console.log('results mobile images:', results);
          setupWifiSlideShowCSSStyles();
          //ticked.current = false;
          wifiSlideShowScrollHandler();
          //previous version
          //window.addEventListener('scroll', throttle(wifiSlideShowScrollHandler,100));
          window.addEventListener('scroll', wifiSlideShowScrollHandler);
        })
        .catch((err:Error)=>console.log('error loading mobile images:', err));
      
        //load mobile images initially to store for desktop use
        getSlideShowImagesDesktop(imgCounter.current, false)
        .then((results:HTMLImageElement[])=>{
          //console.log('results desktop images:', results);
        })
        .catch((err:Error)=>console.log('error loading desktop images:', err));

      }


      //window.addEventListener('scroll', throttle(wifiSlideShowScrollHandler,100));
      window.addEventListener('resize', wifiSlideShowResizeHandler);

      portrait.current[0] = window.matchMedia("(orientation: portrait)");
      portrait.current[0].addEventListener("change", wifiSlideShowResizeHandler);


      return cleanUpScrollHandler;

    },[]);

    function cleanUpScrollHandler():void {
      //previous version
      //window.removeEventListener('scroll', throttle(wifiSlideShowScrollHandler,100));
      window.removeEventListener('scroll', wifiSlideShowScrollHandler);
      window.removeEventListener('resize', wifiSlideShowResizeHandler);

      portrait.current[0].removeEventListener("change", wifiSlideShowResizeHandler);


    }

    function wifiSlideShowScrollHandler():void {
      //console.log('wifi slide show scroll handler');
      sp.current = window.pageYOffset;
      //console.log('sp.current:', sp.current);
      
      if(sp.current > psp.current){
        scrollDirection.current = 'down';
      } else if(sp.current < psp.current){
        scrollDirection.current = 'up';
      } else {
        scrollDirection.current = 'none';
      }

      //console.log('scrollDirection:', scrollDirection.current);

      if(!ticked.current){
        window.requestAnimationFrame(()=>{
        if(sp.current < (slideShowTopBoundary.current/* + 1900*/)){
          //console.log('before zone');
          //console.log('ssi:', slideShowImgs.current.length);
          const ctx = canvasRef.current!.getContext('2d');
          //canvasRef.current!.setAttribute('width', `${document.documentElement.clientWidth}`);
          canvasRef.current!.setAttribute('width', `${dw.current}`);
          //canvasRef.current!.setAttribute('height', `${window.innerHeight}`);
          canvasRef.current!.setAttribute('height', `${wh.current}`);
          imgCounter.current = 0;
          //canvasRef.current!.style.width = `${ww.current > 785 ? adjCanvasWidthStart.current : adjCanvasWidthEnd.current}px`;
          //canvasRef.current!.style.left = `${ww.current > 785 ? adjCanvasLeftStart.current : adjCanvasLeftEnd.current}px`;

          //const shiftPositionX = (window.innerWidth <= 1600)
          /*original version
          const shiftPositionX = (ww.current <= 1600)
                                  //? -((slideShowImgs.current[imgCounter.current].width - document.documentElement.clientWidth) / 2)
                                  ? -((slideShowImgs.current![imgCounter.current].width - dw.current) / 2)
                                  : 0;
          console.log('spx:', shiftPositionX, slideShowImgs.current![imgCounter.current].width);
          */
          const posX = setupShiftPositionX('first');
          //original version
          const posY = setupShiftPositionY('first') + setupCanvasTopPosition('first');

          //const imgWidth = (window.innerWidth <= 1600)
          /*original version
          const imgWidth = (ww.current <= 1600)
                            ? slideShowImgs.current![imgCounter.current].width
                            //: document.documentElement.clientWidth;                              
                            : dw.current;
                            */
          const imgWidth = setupImgWidth('first');
          const imgHeight = setupImgHeight('first');
          //ctx!.drawImage(slideShowImgs.current[imgCounter.current], -142.5, 0, slideShowImgs.current[imgCounter.current].width, window.innerHeight);
          //console.log('img width:', slideShowImgs.current[imgCounter.current].width);
          //ctx!.drawImage(slideShowImgs.current[imgCounter.current], -shiftPositionX, 0, slideShowImgs.current[imgCounter.current].width, slideShowImgs.current[imgCounter.current].height);
          //original version
          //ctx!.drawImage(slideShowImgs.current![imgCounter.current], shiftPositionX, 0, imgWidth, slideShowImgs.current![imgCounter.current].height);
          //original version
          //drawImage(ctx!, shiftPositionX, imgWidth, 'first');
          //const posX = canvasWidthStart.current > dw.current ? -((canvasWidthStart.current - dw.current) / 2) : (dw.current - canvasWidthStart.current) / 2;
          //const posY = canvasHeightStart.current > wh.current ? -((canvasHeightStart.current - wh.current) / 2) : 0;
          //console.log('cw:', canvasWidthStart.current, 'ch:', canvasHeightStart.current, 'posx:', posX, 'posy:', posY, wssihc.current!.getBoundingClientRect().top);
          //canvasWidthCurrent.current = canvasWidthStart.current;
          //canvasRef.current!.style.top = setupCanvasTopPosition('first');// `${canvasTopStart.current}px`;
          //slideShowWrapperRef.current!.style.setProperty('--canvas-y', setupCanvasTopPosition('first'));
          drawImage(ctx!, posX, posY, imgWidth/* canvasWidthCurrent.current*/, imgHeight/* canvasWidthStart.current / .8*/, 'first');

        }
        
        //if(ww.current >= 1024){
          //original version
          if(sp.current >= slideShowTopBoundary.current && sp.current <= (slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/)){
            //console.log('in zone');
            //console.log('sstb:', slideShowTopBoundary.current + 1900);
            //console.log('sp.current:', sp.current);
            //canvasRef.current!.setAttribute('width', `${document.documentElement.clientWidth}`);
            canvasRef.current!.setAttribute('width', `${dw.current}`);
            //canvasRef.current!.setAttribute('height', `${window.innerHeight}`);
            canvasRef.current!.setAttribute('height', `${wh.current}`);
            const ctx = canvasRef.current!.getContext('2d');
            //console.log('icm:', imgCounterMultiplier.current, slideShowTopBoundary.current, canvasScrollDistanceBoundary.current, sp.current);
            //console.log('ic before:', Math.floor(((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - sp.current) * imgCounterMultiplier.current));
            //console.log('imgNumber:', Math.abs(totalImgs.current - Math.floor(((slideShowTopBoundary.current + 1900) - sp.current) * imgCounterMultiplier.current)));
            imgCounter.current = imgCounter.current === 0 ? 1 : Math.abs(totalImgs.current - Math.floor(((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - sp.current) * imgCounterMultiplier.current));
            //console.log('ic after:', imgCounter.current);
            //console.log('selected img pos:', (imgCounter.current - 1) > 73 && (imgCounter.current - 1) < 75 ? sp.current : 'none');

            canvasWidthCurrent.current = canvasWidthEnd.current + (((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - sp.current) * canvasWidthMultiplier.current);
            //console.log('cw test:', canvasWidthCurrent.current, canvasWidthStart.current, canvasWidthEnd.current, canvasWidthMultiplier.current, imgCounter.current);
            //console.log('top test:', canvasTopEnd.current < 0 
                                   // ? canvasTopEnd.current + (((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - sp.current) * canvasTopMultiplier.current)
                                   // : canvasTopEnd.current - (((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - sp.current) * canvasTopMultiplier.current), 'te:', canvasTopEnd.current);
            //original version
            //canvasRef.current!.style.top = setupCanvasTopPosition('second'); //(canvasTopEnd.current < 0) 
                                          //? `${canvasTopEnd.current + (((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - sp.current) * canvasTopMultiplier.current)}px`
                                          //: `${canvasTopEnd.current - (((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - sp.current) * canvasTopMultiplier.current)}px`;
            //slideShowWrapperRef.current!.style.setProperty('--canvas-y', setupCanvasTopPosition('second'));
            if(ww.current > 785){
              //console.log('width result:', adjCanvasWidthEnd.current + (((slideShowTopBoundary.current + 1900) - sp.current) * adjCanvasWidthMultiplier.current));
              //canvasRef.current!.style.width = `${canvasWidthEnd.current + (((slideShowTopBoundary.current + 1900) - sp.current) * canvasWidthMultiplier.current)}px`;
              //console.log('left result:', adjCanvasLeftEnd.current - (((slideShowTopBoundary.current + 1900) - sp.current) * adjCanvasLeftMultiplier.current));
              //canvasRef.current!.style.left = `${canvasPosXEnd.current - (((slideShowTopBoundary.current + 1900) - sp.current) * canvasPosXMultiplier.current)}px`;
            }

            if(ww.current < 550){
              //console.log('height result:', adjCanvasHeightEnd.current + (((slideShowTopBoundary.current + 1900) - sp.current) * adjCanvasHeightMultiplier.current));
              //canvasRef.current!.style.height = `${adjCanvasHeightEnd.current + (((slideShowTopBoundary.current + 1900) - sp.current) * adjCanvasHeightMultiplier.current)}px`;
              //console.log('width result:', ww.current, adjCanvasWidthEnd.current, adjCanvasWidthEnd.current - (((slideShowTopBoundary.current + 1900) - sp.current) * adjCanvasWidthMultiplier.current));
              //canvasRef.current!.style.width = `${adjCanvasWidthEnd.current - (((slideShowTopBoundary.current + 1900) - sp.current) * adjCanvasWidthMultiplier.current)}px`;
              //canvasRef.current!.style.top = `${canvasTopEnd.current - (((slideShowTopBoundary.current + 1900) - sp.current) * canvasTopMultiplier.current)}px`;
              //canvasRef.current!.style.left = `${adjCanvasLeftEnd.current - (((slideShowTopBoundary.current + 1900) - sp.current) * adjCanvasLeftMultiplier.current)}px`;
            }
            //adjCanvasHeight.current;
            //adjCanvasTop.current;
            //adjCanvasLeft.current;


            //const shiftPositionX = (window.innerWidth <= 1600)
            /*original version
            const shiftPositionX = (ww.current <= 1600)
                                    //? -((slideShowImgs.current[imgCounter.current - 1].width - document.documentElement.clientWidth) / 2)
                                    ? -((slideShowImgs.current![imgCounter.current - 1].width - dw.current) / 2)
                                    : 0;
                                    */
            const posX = setupShiftPositionX('second');
            //original version
            const posY = setupShiftPositionY('second') + setupCanvasTopPosition('second');

            //const imgWidth = (window.innerWidth <= 1600)
            /*original version
            const imgWidth = (ww.current <= 1600)
                              ? slideShowImgs.current![imgCounter.current - 1].width
                              //: document.documentElement.clientWidth;
                              : dw.current;
                              */
            const imgWidth = setupImgWidth('second');
            const imgHeight = setupImgHeight('second');

            //ctx!.drawImage(slideShowImgs.current[imgCounter.current - 1], 0, 0, slideShowImgs.current[imgCounter.current - 1].width, slideShowImgs.current[imgCounter.current - 1].height);
            //ctx!.drawImage(slideShowImgs.current[imgCounter.current - 1], -142.5, 0, slideShowImgs.current[imgCounter.current - 1].width, slideShowImgs.current[imgCounter.current - 1].height);
            //original version
            //ctx!.drawImage(slideShowImgs.current![imgCounter.current - 1], shiftPositionX, 0, imgWidth, slideShowImgs.current![imgCounter.current - 1].height);
            //original version
            //drawImage(ctx!, shiftPositionX, imgWidth, 'second');
            //const posX = canvasWidthCurrent.current > dw.current ? -((canvasWidthCurrent.current - dw.current) / 2) : (dw.current - canvasWidthCurrent.current) / 2;
            //original version
            //const posY = canvasHeightStart.current > wh.current ? -((canvasHeightStart.current - wh.current) / 2) : 0;
            //const posY = (canvasWidthCurrent.current / .8) > wh.current ? -(((canvasWidthCurrent.current / .8) - wh.current) / 2) : 0;
            //console.log('cw:', canvasWidthCurrent.current, 'ch:', (canvasWidthCurrent.current / .8), 'posx:', posX, 'posy:', posY);
            drawImage(ctx!, posX, posY, imgWidth/* canvasWidthCurrent.current*/, imgHeight/* canvasWidthCurrent.current / .8*/, 'second');
            
          }

          if(sp.current > (slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/)){
            //console.log('after zone');
            const ctx = canvasRef.current!.getContext('2d');
            //canvasRef.current!.setAttribute('width', `${document.documentElement.clientWidth}`);
            //canvasRef.current!.setAttribute('height', `${window.innerHeight}`);
            //imgCounter.current = (window.innerWidth >= 1550) ? 90 : 82;

            /*original version
            if(ww.current >= 1550){
              imgCounter.current = 90;
            } else if(ww.current < 1550 && ww.current > 1024){
              imgCounter.current = 82;
            } else {
              imgCounter.current = 70;
            }
            */

            imgCounter.current = ww.current > 1024 ? 90 : 70;
            
            //original version
            //imgCounter.current = (ww.current >= 1550) ? 90 : 82;
            //canvasRef.current!.setAttribute('width', `${document.documentElement.clientWidth}`);
            canvasRef.current!.setAttribute('width', `${dw.current}`);
            //canvasRef.current!.setAttribute('height', `${window.innerHeight}`);
            canvasRef.current!.setAttribute('height', `${wh.current}`);

            //canvasRef.current!.style.width = `${adjCanvasWidthEnd.current}px`;
            //canvasRef.current!.style.left = `${adjCanvasLeftEnd.current}px`;
            //const shiftPositionX = (window.innerWidth <= 1600)
            /*
            const shiftPositionX = (ww.current <= 1600)
                                    //? -((slideShowImgs.current[imgCounter.current - 1].width - document.documentElement.clientWidth) / 2)
                                    ? -((slideShowImgs.current![imgCounter.current - 1].width - dw.current) / 2)
                                    : 0;
                                    */
            const posX = setupShiftPositionX('last');
            //original version
            const posY = setupShiftPositionY('last') + setupCanvasTopPosition('last');

            //const imgWidth = (window.innerWidth <= 1600)
            /*
            const imgWidth = (ww.current <= 1600)
                              ? slideShowImgs.current![imgCounter.current - 1].width
                              //: document.documentElement.clientWidth;
                              : dw.current;
                              */
            const imgWidth = setupImgWidth('last');
            const imgHeight = setupImgHeight('last');
            //console.log('ssi.w:', slideShowImgs.current[imgCounter.current - 1].width);
            //console.log('dew:', document.documentElement.clientWidth);
            //console.log('dew:', dw.current);
            //console.log('spx:', shiftPositionX);
            //ctx!.drawImage(slideShowImgs.current[imgCounter.current - 1], -shiftPositionX, 0, slideShowImgs.current[imgCounter.current - 1].width, slideShowImgs.current[imgCounter.current - 1].height);
            //ctx!.drawImage(slideShowImgs.current[imgCounter.current - 1], -shiftPositionX, 0, slideShowImgs.current[imgCounter.current - 1].width, slideShowImgs.current[imgCounter.current - 1].height);
            //original version
            //ctx!.drawImage(slideShowImgs.current![imgCounter.current - 1], shiftPositionX, 0, imgWidth, slideShowImgs.current![imgCounter.current - 1].height);
            //original version
            //drawImage(ctx!, shiftPositionX, imgWidth, 'last');
            //const posX = canvasWidthEnd.current > dw.current ? -((canvasWidthEnd.current - dw.current) / 2) : (dw.current - canvasWidthEnd.current) / 2;
            //const posY = canvasHeightEnd.current > wh.current ? -((canvasHeightEnd.current - wh.current) / 2) : 0;
            //console.log('cw:', canvasWidthEnd.current, 'ch:', canvasHeightEnd.current, 'posx:', posX, 'posy:', posY, -((canvasHeightEnd.current - wh.current) / 2), wssihc.current!.getBoundingClientRect().top, imgCounter.current);
            //console.log('target2:', wh.current * .65, window.pageYOffset, canvasRef.current!.getBoundingClientRect().top);
            //canvasWidthCurrent.current = canvasWidthEnd.current;
            //original version
            //canvasRef.current!.style.top = setupCanvasTopPosition('last');// `${canvasTopEnd.current}px`;
            //slideShowWrapperRef.current!.style.setProperty('--canvas-y', setupCanvasTopPosition('last'));
            drawImage(ctx!, posX, posY, imgWidth/* canvasWidthCurrent.current*/, imgHeight /*canvasWidthEnd.current / .8*/, 'last');
          }

          if(sp.current < (slideShowImgContainerTopPosition.current + canvasScrollDistanceBoundary.current - 519 - 340/*1041*/)){
            //canvasY.current = 0;
            //slideShowWrapperRef.current!.style.setProperty('--canvas-y', `-${canvasY.current}vh`);
          }

          if(sp.current >= (slideShowImgContainerTopPosition.current + canvasScrollDistanceBoundary.current - 519 - 340/*1041*/) 
            && sp.current <= (slideShowImgContainerTopPosition.current + canvasScrollDistanceBoundary.current - 519/*1381*/)){
            //canvasY.current = 12 - ((slideShowImgContainerTopPosition.current + canvasScrollDistanceBoundary.current - 519/*1381*/) - sp.current) * slideShowImgContainerMultiplier.current;
            //slideShowWrapperRef.current!.style.setProperty('--canvas-y', `-${canvasY.current}vh`);
          }

          if(sp.current > (slideShowImgContainerTopPosition.current + canvasScrollDistanceBoundary.current - 519/*1381*/)){
            canvasY.current = 12;
            //slideShowWrapperRef.current!.style.setProperty('--canvas-y', `-${canvasY.current}vh`);
          }

          //original version
          //if(sp.current < 9106){
            if(sp.current <= (slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - slideShowMsgAdjust.current){
            //console.log('less than');
            wssihtc1.current!.classList.remove('adjust-heading-title-container');
            wssihtc2.current!.classList.remove('adjust-heading-title-container');
            wssihcc1.current!.classList.remove('adjust-heading-caption-container');
            wssihcc2.current!.classList.remove('adjust-heading-caption-container');
            gailc.current!.classList.remove('adjust-google-assist-img-container');
          }

          //if(sp.current > 9105){
            if(sp.current > (slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*900*/) - slideShowMsgAdjust.current){
            //console.log('greater than');
            wssihtc1.current!.classList.add('adjust-heading-title-container');
            wssihtc2.current!.classList.add('adjust-heading-title-container');
            wssihcc1.current!.classList.add('adjust-heading-caption-container');
            wssihcc2.current!.classList.add('adjust-heading-caption-container');
            gailc.current!.classList.add('adjust-google-assist-img-container');
          }

      /*} else {
        if(sp.current >= slideShowTopBoundary.current && sp.current <= (slideShowTopBoundary.current + 1900)){
          console.log('in zone');
          canvasRef.current!.setAttribute('width', `${dw.current}`);
          //canvasRef.current!.setAttribute('height', `${window.innerHeight}`);
          canvasRef.current!.setAttribute('height', `${wh.current}`);
          const ctx = canvasRef.current!.getContext('2d');
          console.log('icm:', imgCounterMultiplier.current);
          //console.log('imgNumber:', Math.abs(totalImgs.current - Math.floor(((slideShowTopBoundary.current + 1900) - sp.current) * imgCounterMultiplier.current)));
          imgCounter.current = Math.abs(totalImgs.current - Math.floor(((slideShowTopBoundary.current + 1900) - sp.current) * imgCounterMultiplier.current));
          console.log('ic:', imgCounter.current);
          const shiftPositionX = setupShiftPositionX('second');                                 
          const imgWidth = setupImgWidth('second');
          drawImage(ctx!, shiftPositionX, imgWidth, 'second');

        }

        if(sp.current > (slideShowTopBoundary.current + 1900)){
          console.log('after zone');
          const ctx = canvasRef.current!.getContext('2d');
          imgCounter.current = 70;
          //canvasRef.current!.setAttribute('width', `${document.documentElement.clientWidth}`);
          canvasRef.current!.setAttribute('width', `${dw.current}`);
          //canvasRef.current!.setAttribute('height', `${window.innerHeight}`);
          canvasRef.current!.setAttribute('height', `${wh.current}`);
          const shiftPositionX = setupShiftPositionX('last');
          const imgWidth = setupImgWidth('last');
          drawImage(ctx!, shiftPositionX, imgWidth, 'last');
        }

      }
      */

        ticked.current = false
      });
      
    }

    ticked.current = true;
    psp.current = sp.current;
  }

    function wifiSlideShowResizeHandler(e:Event):void {
      if((e.type === 'resize' && pw.current === document.documentElement.clientWidth)){
        console.log('r12:', 'pw:', pw.current, 'ww:', document.documentElement.clientWidth);
        ww.current = document.documentElement.clientWidth;
        pw.current = document.documentElement.clientWidth;
        return;
      }

      pw.current = document.documentElement.clientWidth;

      //canvasRef.current!.style.width = `${ww.current - 16}px`;
      //canvasRef.current!.style.height = `${wh.current}px`;

      
      slideShowTopBoundary.current = slideShowWrapperRef.current!.getBoundingClientRect().top + window.pageYOffset;
      //console.log('sstb:', slideShowTopBoundary.current, sp.current, window.pageYOffset, window.innerHeight, window.screen.height);

      if(!isMobile.current){
        ww.current = window.innerWidth;
        wh.current = window.innerHeight;
        dw.current = document.documentElement.clientWidth;
  
        //canvasWrapperRef.current!.style.height = `${wh.current}px`;

      } else {
        ww.current = window.screen.width;
        wh.current = window.screen.height;
        dw.current = window.screen.width;
  
        //canvasWrapperRef.current!.style.height = `${wh.current}px`;
      }

      ww.current = document.documentElement.clientWidth;
      wh.current = document.documentElement.clientHeight;
      dw.current = document.documentElement.clientWidth;
      

      //console.log('ww:', ww.current, 'wh:', wh.current);

      //console.log('ww:', ww.current, 'wh:', wh.current);
      canvasWrapperRef.current!.style.height = `${wh.current}px`;
      wssiw.current!.style.background = (ww.current === 1024) ? '#e5ebf2' : '';


      adjustCaptionContainerWidth();
  
      /*original version
      imgCounterMultiplier.current = (ww.current >= 1550) ? (90 / 1900) : (82 / 1900);
      //totalImgs.current = (window.innerWidth >= 1550) ? 90 : 82;
      totalImgs.current = (ww.current >= 1550) ? 90 : 82;
      slideShowMsgAdjust.current = (ww.current >= 1500) ? (24 * 9) : (24 * 3);
      */
      //imgCounterMultiplier.current = 82/1900;// window.innerWidth >= 1100 ? (90 / 1900) : (82 / 1900);
      //totalImgs.current = 82;// window.innerWidth >= 1100 ? 90 : 82;
      //original version
      //adjustCaptionContainerWidth();
  
      //slideShowImgContainerTopBoundary.current
      slideShowImgContainerTopPosition.current = wssic.current!.getBoundingClientRect().top + window.pageYOffset;// + sp.current;
      //console.log('ssictb:', slideShowImgContainerTopPosition.current);
      slideShowImgContainerHeight.current = wssic.current!.scrollHeight;
      //const slideShowImgContainerTopScrollBoundary = slideShowImgContainerHeight.current * .57;
      //const slideShowImgContainerBottomScrollBoundary = slideShowImgContainerHeight.current * .75;
      slideShowImgContainerMultiplier.current = 12 / 340;

      if(ww.current >= 1025){

        //set initial css styles on canvas element
        const {widthStart:ws, widthEnd:we, widthMultiplier:awm, heightStart:hs, heightEnd:he, heightMultiplier:ahm, 
          topStart:ts, topEnd:te, topMultiplier:atm, posXStart:pxs, posXEnd:pxe, posXMultiplier:pxm,
          posYStart:pys, posYEnd:pye, posYMultiplier:pym, scrollDistanceBoundary:sdb} = getAdjCanvasDimensions();
        canvasWidthStart.current = ws;
        canvasWidthEnd.current = we;
        canvasWidthMultiplier.current = awm;
        canvasHeightStart.current = hs;
        canvasHeightEnd.current = he;
        canvasHeightMultiplier.current = ahm;
        canvasTopStart.current = ts;
        canvasTopEnd.current = te;
        canvasTopMultiplier.current = atm;
        canvasPosXStart.current = pxs;
        canvasPosXEnd.current = pxe;
        canvasPosXMultiplier.current = pxm;
        canvasPosYStart.current = pys;
        canvasPosYEnd.current = pye;
        canvasPosYMultiplier.current = pym;
        canvasScrollDistanceBoundary.current = sdb;
        //console.log('wstart:', ws, 'wend:', we, 'hstart:', canvasHeightStart.current, 'hEnd:', canvasHeightEnd.current,
                    //'heightMultiplier:', atm, 'posXStart:', pxs, 'posXEnd:', pxe, 'posYStart:', pys, 'posYEnd:', pye, sdb);

        //imgCounterMultiplier.current = (ww.current >= 1550) ? (90 / 1900) : (82 / 1900);
        imgCounterMultiplier.current = 90 / canvasScrollDistanceBoundary.current/*1900*/;
        //totalImgs.current = (window.innerWidth >= 1550) ? 90 : 82;
        //totalImgs.current = (ww.current >= 1550) ? 90 : 82;
        totalImgs.current = 90;
        slideShowMsgAdjust.current = (ww.current >= 1516) ? (24 * 9) : (24 * 3);
  
       
        if(window.pageYOffset < slideShowTopBoundary.current){
          imgCounter.current = 0;
        } else if(window.pageYOffset >= slideShowTopBoundary.current && window.pageYOffset <= slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/){
          imgCounter.current = (Math.abs(totalImgs.current - Math.floor(((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - window.pageYOffset) * imgCounterMultiplier.current))) - 1; 
        } else if(window.pageYOffset > slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/){
          imgCounter.current = totalImgs.current - 1;
        }
        //console.log('ic:', imgCounter.current);

      } else {

        const {widthStart:ws, widthEnd:we, widthMultiplier:awm, heightStart:hs, heightEnd:he, heightMultiplier:ahm, 
          topStart:ts, topEnd:te, topMultiplier:atm, posXStart:pxs, posXEnd:pxe, posXMultiplier:pxm,
          posYStart:pys, posYEnd:pye, posYMultiplier:pym, scrollDistanceBoundary:sdb} = getAdjCanvasDimensions();
        canvasWidthStart.current = ws;
        canvasWidthEnd.current = we;
        canvasWidthMultiplier.current = awm;
        canvasHeightStart.current = hs;
        canvasHeightEnd.current = he;
        canvasHeightMultiplier.current = ahm;
        canvasTopStart.current = ts;
        canvasTopEnd.current = te;
        canvasTopMultiplier.current = atm;
        canvasPosXStart.current = pxs;
        canvasPosXEnd.current = pxe;
        canvasPosXMultiplier.current = pxm;
        canvasPosYStart.current = pys;
        canvasPosYEnd.current = pye;
        canvasPosYMultiplier.current = pym;
        canvasScrollDistanceBoundary.current = sdb;
        //console.log('wstart:', ws, 'wend:', we, 'hstart:', canvasHeightStart.current, 'hEnd:', canvasHeightEnd.current,
                    //'heightMultiplier:', atm, 'posXStart:', pxs, 'posXEnd:', pxe, 'posYStart:', pys, 'posYEnd:', pye, sdb);
        


        imgCounterMultiplier.current = 70 / canvasScrollDistanceBoundary.current;/*1900;*/
        //console.log('icm:', imgCounterMultiplier.current);
        //totalImgs.current = (window.innerWidth >= 1550) ? 90 : 82;
        totalImgs.current = 70;
        slideShowMsgAdjust.current = 24 * 3;

        const pointerImgContainerWidth = wssimc.current!.getBoundingClientRect().width;
        const pointerImgContainerHeight = wssimc.current!.getBoundingClientRect().height;  
       
        if(window.pageYOffset < slideShowTopBoundary.current){
          imgCounter.current = 0;
        } else if(window.pageYOffset >= slideShowTopBoundary.current && window.pageYOffset <= slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/){
          imgCounter.current = (Math.abs(totalImgs.current - Math.floor(((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - window.pageYOffset) * imgCounterMultiplier.current))) - 1; 
        } else if(window.pageYOffset > slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/){
          imgCounter.current = totalImgs.current - 1;
        }
        //console.log('ic:', imgCounter.current, window.pageYOffset, slideShowTopBoundary.current);
      }


      setupWifiSlideShowCSSStyles();
      wifiSlideShowScrollHandler();
    }

    function setupShiftPositionX(scrollPos:string):number{
      let shiftPositionX = 0;
      if(ww.current >= 1025 && scrollPos === 'first'){
        //shiftPositionX = 1516 > dw.current ? -((1516 - dw.current) / 2) : 0;
        shiftPositionX = canvasWidthStart.current > dw.current ? -((canvasWidthStart.current - dw.current) / 2) : (dw.current - canvasWidthStart.current) / 2;;

      } else if(ww.current < 1025 && scrollPos === 'first') {
        shiftPositionX = canvasWidthStart.current > dw.current ? -((canvasWidthStart.current - dw.current) / 2) : (dw.current - canvasWidthStart.current) / 2;

      } else if(ww.current >= 1025 && (scrollPos === 'none' || scrollPos === 'second')){
        //shiftPositionX = 1516 > dw.current ? -((1516 - dw.current) / 2) : 0;
        shiftPositionX = canvasWidthCurrent.current > dw.current ? -((canvasWidthCurrent.current - dw.current) / 2) : (dw.current - canvasWidthCurrent.current) / 2;

      } else if(ww.current < 1025 && (scrollPos === 'none' || scrollPos === 'second')) {
        shiftPositionX = canvasWidthCurrent.current > dw.current ? -((canvasWidthCurrent.current - dw.current) / 2) : (dw.current - canvasWidthCurrent.current) / 2;

      } else if(ww.current >= 1025 && scrollPos === 'last'){
        //shiftPositionX = 1516 > dw.current ? -((1516 - dw.current) / 2) : 0;
        shiftPositionX = canvasWidthEnd.current > dw.current ? -((canvasWidthEnd.current - dw.current) / 2) : (dw.current - canvasWidthEnd.current) / 2;

      }  else if(ww.current < 1025 && scrollPos === 'last') {
        shiftPositionX = canvasWidthEnd.current > dw.current ? -((canvasWidthEnd.current - dw.current) / 2) : (dw.current - canvasWidthEnd.current) / 2;

      }

      return shiftPositionX;

    }

    function setupShiftPositionY(scrollPos:string):number{
      let shiftPositionY = 0;
      if(ww.current >= 1025 && scrollPos === 'first'){
        //shiftPositionY = dw.current > 1516 ? (dw.current / 1.5992) > wh.current ? -(((dw.current / 1.5992) - wh.current) / 2) : 0 : (1516 / 1.5992) > wh.current ? -(((1516 / 1.5992) - wh.current) / 2) : 0;
        shiftPositionY = canvasHeightStart.current > wh.current ? -((canvasHeightStart.current - wh.current) / 2) : 0;

      } else if(ww.current < 1025 && scrollPos === 'first') {
        shiftPositionY = canvasHeightStart.current > wh.current ? -((canvasHeightStart.current - wh.current) / 2) : 0;

      } else if(ww.current >= 1025 && (scrollPos === 'none' || scrollPos === 'second')){
        //shiftPositionY = dw.current > 1516 ? (dw.current / 1.5992) > wh.current ? -(((dw.current / 1.5992) - wh.current) / 2) : 0 : (1516 / 1.5992) > wh.current ? -(((1516 / 1.5992) - wh.current) / 2) : 0;
        shiftPositionY = (canvasWidthCurrent.current / 1.5992) > wh.current ? -(((canvasWidthCurrent.current / 1.5992) - wh.current) / 2) : 0;

      } else if(ww.current < 1025 && (scrollPos === 'none' || scrollPos === 'second')) {
        shiftPositionY = (canvasWidthCurrent.current / .8) > wh.current ? -(((canvasWidthCurrent.current / .8) - wh.current) / 2) : 0;

      } else if(ww.current >= 1025 && scrollPos === 'last'){
        //shiftPositionY = dw.current > 1516 ? (dw.current / 1.5992) > wh.current ? -(((dw.current / 1.5992) - wh.current) / 2) : 0 : (1516 / 1.5992) > wh.current ? -(((1516 / 1.5992) - wh.current) / 2) : 0;
        shiftPositionY = canvasHeightEnd.current > wh.current ? -((canvasHeightEnd.current - wh.current) / 2) : 0;

      } else if(ww.current < 1025 && scrollPos === 'last') {
        shiftPositionY = canvasHeightEnd.current > wh.current ? -((canvasHeightEnd.current - wh.current) / 2) : 0;

      }

      //console.log('spy:', shiftPositionY, dw.current, scrollPos);
      return shiftPositionY;

    }

    function setupImgWidth(scrollPos:string):number {
      let imgWidth = 0;
      if(ww.current >= 1025 && scrollPos === 'first'){
        //imgWidth = (dw.current > 1516) ? dw.current : 1516;
        imgWidth = canvasWidthCurrent.current = canvasWidthStart.current;

      } else if(ww.current < 1025 && scrollPos === 'first'){
        imgWidth = canvasWidthCurrent.current = canvasWidthStart.current;
      
      } else if(ww.current >= 1025 && (scrollPos === 'none' || scrollPos === 'second')){
        //imgWidth = (dw.current > 1516) ? dw.current : 1516;
        imgWidth = canvasWidthCurrent.current;

      } else if(ww.current < 1025 && (scrollPos === 'none' || scrollPos === 'second')){
        imgWidth = canvasWidthCurrent.current;
      
      } else if(ww.current >= 1025 && scrollPos === 'last'){
        //imgWidth = (dw.current > 1516) ? dw.current : 1280;
        imgWidth = canvasWidthCurrent.current = canvasWidthEnd.current;

      } else if(ww.current < 1025 && scrollPos === 'last'){
        imgWidth = canvasWidthCurrent.current = canvasWidthEnd.current;
      }

      //console.log('iw:', imgWidth);
      return imgWidth;

    }

    function setupImgHeight(scrollPos:string):number {
      let imgHeight = 0;
      if(ww.current >= 1025 && scrollPos === 'first'){
        //imgHeight = (dw.current > 1516) ? dw.current / 1.5992 : 1516 / 1.5992;
        imgHeight = canvasWidthStart.current / 1.5992;

      } else if(ww.current < 1025 && scrollPos === 'first'){
        imgHeight = canvasWidthStart.current / .8;
      
      } else if(ww.current >= 1025 && (scrollPos === 'none' || scrollPos === 'second')){
        //imgHeight = (dw.current > 1516) ? dw.current / 1.5992 : 1516 / 1.5992;
        imgHeight = canvasWidthCurrent.current / 1.5992;

      } else if(ww.current < 1025 && (scrollPos === 'none' || scrollPos === 'second')){
        imgHeight = canvasWidthCurrent.current / .8;
      
      } else if(ww.current >= 1025 && scrollPos === 'last'){
        //imgHeight = (dw.current > 1516) ? dw.current / 1.5992 : 1280 / 1.5992;
        imgHeight = canvasWidthEnd.current / 1.5992;

      } else if(ww.current < 1025 && scrollPos === 'last'){
        imgHeight = canvasWidthEnd.current / .8;
      }

      //console.log('ih:', imgHeight);
      return imgHeight;

    }

    function setupCanvasTopPosition(scrollPos:string):number {
      let topPosition = 0;
      if(ww.current >= 1025 && scrollPos === 'first'){
        topPosition = canvasTopStart.current;

      } else if(ww.current < 1025 && scrollPos === 'first'){
        topPosition = canvasTopStart.current;
      
      } else if(ww.current >= 1025 && (scrollPos === 'none' || scrollPos === 'second')){
        topPosition = (canvasTopEnd.current < 0) 
                      ? canvasTopEnd.current + (((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - sp.current) * canvasTopMultiplier.current)
                      : canvasTopEnd.current - (((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - sp.current) * canvasTopMultiplier.current);

      } else if(ww.current < 1025 && (scrollPos === 'none' || scrollPos === 'second')){
        topPosition = (canvasTopEnd.current < 0) 
                      ? canvasTopEnd.current + (((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - sp.current) * canvasTopMultiplier.current)
                      : canvasTopEnd.current - (((slideShowTopBoundary.current + canvasScrollDistanceBoundary.current/*1900*/) - sp.current) * canvasTopMultiplier.current);
      
      } else if(ww.current >= 1025 && scrollPos === 'last'){
        topPosition = canvasTopEnd.current;

      } else if(ww.current < 1025 && scrollPos === 'last'){
        topPosition = canvasTopEnd.current;
      }

      //original version returning a string for --cavnvas css variable
      //return `${topPosition}px`;
      return topPosition;

    }

    function drawImage(ctx:CanvasRenderingContext2D, shiftPositionX:number, posY:number, imgWidth:number, imgHeight:number, scrollPos:string):void {
      if(ww.current >= 1025 && scrollPos === 'first'){
        ctx!.drawImage(slideShowImgs.current![imgCounter.current], shiftPositionX, posY, imgWidth, imgHeight);

      } else if(ww.current < 1025 && scrollPos === 'first') {
        //console.log('done');
        //ctx!.drawImage(slideShowImgsMobile.current![imgCounter.current], shiftPositionX, 0, slideShowImgsMobile.current![imgCounter.current].width, slideShowImgsMobile.current![imgCounter.current].height);
        //ctx!.drawImage(slideShowImgsMobile.current![imgCounter.current], -shiftPositionX, 0, imgWidth, wh.current);
        //console.log('ssimg:', slideShowImgsMobile.current![imgCounter.current]);
        ctx!.drawImage(slideShowImgsMobile.current![imgCounter.current], shiftPositionX, posY, imgWidth, imgHeight);

      } else if(ww.current >= 1025 && scrollPos !== 'first'){
        //console.log('ic:', imgCounter.current);
        ctx!.drawImage(slideShowImgs.current![imgCounter.current - 1], shiftPositionX, posY, imgWidth, imgHeight);

      } else if(ww.current < 1025 && scrollPos !== 'first') {
        //console.log('ic:', imgCounter.current, imgWidth, imgHeight);
        //ctx!.drawImage(slideShowImgsMobile.current![imgCounter.current - 1], 0, 0, slideShowImgsMobile.current![imgCounter.current - 1].width, slideShowImgsMobile.current![imgCounter.current - 1].height);
        //ctx!.drawImage(slideShowImgsMobile.current![imgCounter.current - 1], -shiftPositionX, 0, imgWidth, wh.current);
        //ctx!.drawImage(slideShowImgsMobile.current![imgCounter.current - 1], 107.5, -7.25, 770, 962.5);
        ctx!.drawImage(slideShowImgsMobile.current![imgCounter.current - 1], shiftPositionX, posY, imgWidth, imgHeight);
      }

    }

    function getAdjCanvasDimensions():AdjCanvasDimensions {
      //intial pointer img height
      //const routerImgHeight = 323;
      let mobileImgContainerWidth = (wssimc.current!.getBoundingClientRect().width / .5) * .7;
      let canvasContainerTop = wh.current * .65;
      /*original version
      let canvasTop = wssihc.current!.getBoundingClientRect().top;
      let canvasContainerTop = canvasWrapperRef.current!.getBoundingClientRect().top;
      console.log('cct before:', canvasContainerTop, 'ct:', canvasTop, wh.current * .65, window.pageYOffset);
      canvasContainerTop = canvasContainerTop > 0 ? 0 : canvasContainerTop;
      canvasContainerTop = canvasContainerTop < 0 ? canvasContainerTop - canvasTop : 0 + (wh.current * .65);
      console.log('top result:', 'cct:', canvasContainerTop, 
                  'ct:', canvasTop,
                  'both:', Math.abs((canvasContainerTop + window.pageYOffset) - (canvasTop + window.pageYOffset)),
                  'ww:', ww.current, 'wh:', wh.current, window.pageYOffset, sp.current, isMobile.current);
      console.log('top result 2:', canvasContainerTop < 0 ? canvasContainerTop - canvasTop : 0 + (wh.current * .65));
      */

      const imgHeightSpacePercentage = .555;
      const routerImgSeparationHeight = 40;
      //original version
      //const targetHeadingContainerTop = Math.abs(canvasContainerTop - canvasTop) - routerImgSeparationHeight;
      //new version for 1024px width or greater
      //const targetHeadingContainerTop2 = (heightEnd - ((wh.current * .16) + (wh.current / 2))) - (heightEnd * .324);
      const targetHeadingContainerTop = canvasContainerTop - (ww.current === 1024 ? 173 : routerImgSeparationHeight);
      //console.log('target:', targetHeadingContainerTop, wh.current * .65, window.pageYOffset, canvasRef.current!.getBoundingClientRect().top);
      //console.log('target1:', wh.current * .65, window.pageYOffset, canvasRef.current!.getBoundingClientRect().top);
      //const imgSpaceAboveImgHeight = (canvasHeightEnd.current * imgHeightSpacePercentage);
      let widthStart = 0;
      let widthEnd = 0;
      let widthMultiplier = 1;
      let heightStart = 0;
      let heightEnd = 0;
      let heightMultiplier = 1;
      let topStart = 0;
      let topEnd = 0;
      let topMultiplier = 1;
      let posXStart = 0;
      let posXEnd = 0;
      let posXMultiplier = 1;
      let posYStart = 0;
      let posYEnd = 0;
      let posYMultiplier = 1;
      let scrollDistanceBoundary = (948 - wh.current) === 0 ? 1900 : 1900 - ((948 - wh.current) * 2);

      if(ww.current > 1024){
        if(wh.current * 1.5992 > dw.current && wh.current >= 640){
          widthStart = wh.current * 1.5992;
          widthEnd = wh.current * 1.5992;
        } else if(wh.current * 1.5992 < dw.current && wh.current >= 640){
          widthStart = dw.current;
          widthEnd = dw.current;
        } else if(wh.current * 1.5992 > dw.current && wh.current < 640){
          widthStart = 640 * 1.5992;
          widthEnd = 640 * 1.5992;
        } else if(wh.current * 1.5992 < dw.current && wh.current < 640){
          widthStart = dw.current;
          widthEnd = dw.current;
        }
        //widthStart = (wh.current * 1.5992) > dw.current && wh.current >= 640 ? (wh.current * 1.5992) : dw.current;
        //widthEnd = wh.current * 1.5992;
        widthMultiplier = Math.abs(widthStart - widthEnd) / scrollDistanceBoundary;
        heightStart = widthStart / 1.5992;
        heightEnd = widthEnd / 1.5992;
        //console.log('hend:', heightEnd, 'wend:', widthEnd, 'diff:', (heightEnd - ((wh.current * .16) + (wh.current / 2))) - (heightEnd * .324) );
        heightMultiplier = Math.abs(heightStart - heightEnd) / scrollDistanceBoundary;
        posXStart = dw.current > widthStart ? (dw.current - widthStart) / 2 : -((widthStart - dw.current) / 2);
        posXEnd =  dw.current > widthEnd ? (dw.current - widthEnd) / 2 : -((widthEnd - dw.current) / 2);
        posXMultiplier = Math.abs(posXStart - posXEnd) / scrollDistanceBoundary;
        posYStart = heightStart > wh.current ? -((heightStart - wh.current) / 2) : (wh.current - heightStart) / 2;
        posYEnd = heightEnd > wh.current ? -((heightEnd - wh.current) / 2) : (wh.current - heightEnd) / 2;
        posYMultiplier = Math.abs(posYStart - posYEnd) / scrollDistanceBoundary;
        topStart = 0;
        //original version
        //topEnd = (targetHeadingContainerTop - (heightEnd * imgHeightSpacePercentage)) + (posYEnd < 0 ? Math.abs(posYEnd) : 0);
        const targetHeadingContainerTop2 = (heightEnd - ((wh.current * .16) + (wh.current / 2))) - (heightEnd * .324);
        topEnd = (posYEnd < 0 ? Math.abs(posYEnd) : 0) - targetHeadingContainerTop2 - adjustImgHeightByWidth(ww.current, 1025, 1848, 73, 134);
        topMultiplier = Math.abs(topEnd) / scrollDistanceBoundary;
        //console.log('top:', heightEnd, (posYEnd < 0 ? Math.abs(posYEnd) : 0), heightEnd * imgHeightSpacePercentage, wh.current, wh.current * .65);
        //console.log('top result:', (targetHeadingContainerTop - (heightEnd * imgHeightSpacePercentage)), (posYEnd < 0 ? Math.abs(posYEnd) : 0));
        //console.log('pye:', posYEnd, 'pys:', posYStart, 'we:', widthEnd, 'he:', heightEnd, 'wh:', wh.current, 'he > wh:', heightEnd > wh.current, 'te:', topEnd, 'tm:', topMultiplier);
        
      }

      if(dw.current > 770 && ww.current <= 1024){
        widthStart = dw.current;
        widthEnd = mobileImgContainerWidth === 0 ? dw.current  : mobileImgContainerWidth;// (wssimc.current!.getBoundingClientRect().width / .5) * .7;
        //console.log('wend:', widthEnd, dw.current, mobileImgContainerWidth);
        widthMultiplier = Math.abs(widthStart - widthEnd) / scrollDistanceBoundary;
        heightStart = widthStart / .8;
        heightEnd = widthEnd / .8;
        //console.log('hend:', heightEnd, 'wend:', widthEnd, 'diff:', (heightEnd - ((wh.current * .16) + (wh.current / 2))) - (heightEnd * .449) );
        heightMultiplier = Math.abs(heightStart - heightEnd) / scrollDistanceBoundary;
        posXStart = 0;
        posXEnd = (dw.current - widthEnd) / 2;
        posXMultiplier = Math.abs(posXStart - posXEnd) / scrollDistanceBoundary;
        posYStart = heightStart > wh.current ? -((heightStart - wh.current) / 2) : (wh.current - heightStart) / 2;
        posYEnd = heightEnd > wh.current ? -((heightEnd - wh.current) / 2) : (wh.current - heightEnd) / 2;
        posYMultiplier = Math.abs(posYStart - posYEnd) / scrollDistanceBoundary;
        topStart = 0;
        //original version
        //topEnd = (targetHeadingContainerTop - (heightEnd * imgHeightSpacePercentage)) + (posYEnd < 0 ? Math.abs(posYEnd) : 0);
        const targetHeadingContainerTop2 = (heightEnd - ((wh.current * .16) + (wh.current / 2))) - (heightEnd * .449);
        topEnd = (posYEnd < 0 ? Math.abs(posYEnd) : 0) - targetHeadingContainerTop2 - adjustImgHeight(wh.current, ww.current);
        topMultiplier = Math.abs(topEnd) / scrollDistanceBoundary;
        //console.log('pye:', posYEnd, 'pys:', posYStart, 'we:', widthEnd, 'he:', heightEnd, 'wh:', wh.current, 'he > wh:', heightEnd > wh.current, 'te:', topEnd, 'tm:', topMultiplier);

      }

      if(dw.current <= 770){
        widthStart = (dw.current / .5) * .7;
        widthEnd = mobileImgContainerWidth;//(wssimc.current!.getBoundingClientRect().width / .5) * .7;
        //console.log('wend:', widthEnd);
        widthMultiplier = Math.abs(widthStart - widthEnd) / scrollDistanceBoundary;
        heightStart = widthStart / .8;
        heightEnd = widthEnd / .8;
        //console.log('hend:', heightEnd, 'wend:', widthEnd, 'diff:', (heightEnd - (wh.current * .65)) - (heightEnd * .447) );
        heightMultiplier = Math.abs(heightStart - heightEnd) / scrollDistanceBoundary;
        posXStart = (widthStart - dw.current) / 2;
        posXEnd = (dw.current - widthEnd) / 2;
        posXMultiplier = Math.abs(posXStart - posXEnd) / scrollDistanceBoundary;
        posYStart = heightStart > wh.current ? -((heightStart - wh.current) / 2) : (wh.current - heightStart) / 2;
        posYEnd = heightEnd > wh.current ? -((heightEnd - wh.current) / 2) : (wh.current - heightEnd) / 2;
        posYMultiplier = Math.abs(posYStart - posYEnd) / scrollDistanceBoundary;
        topStart = 0;
        //original version
        //topEnd = (targetHeadingContainerTop - (heightEnd * imgHeightSpacePercentage)) + (posYEnd < 0 ? Math.abs(posYEnd) : 0);
        const targetHeadingContainerTop2 = (heightEnd - (wh.current * .65)) - (heightEnd * .447);
        topEnd = (posYEnd < 0 ? Math.abs(posYEnd) : 0) - targetHeadingContainerTop2 - adjustImgHeight(wh.current, ww.current);
        topMultiplier = Math.abs(topEnd) / scrollDistanceBoundary;
        //console.log('pye:', posYEnd, 'pys:', posYStart, 'we:', widthEnd, 'he:', heightEnd, 'wh:', wh.current, 'he > wh:', heightEnd > wh.current, 'te:', topEnd, 'tm:', topMultiplier);

      }

      return {
        widthStart,
        widthEnd,
        widthMultiplier,
        heightStart,
        heightEnd,
        heightMultiplier,
        topStart,
        topEnd,
        topMultiplier,
        posXStart,
        posXEnd,
        posXMultiplier,
        posYStart,
        posYEnd,
        posYMultiplier,
        scrollDistanceBoundary
      };

    }

    function adjustImgHeightByWidth(widthCurrent:number, widthStart:number, widthEnd:number, amtStart:number, amtEnd:number):number {
      //img height is adjusted based on the current screen/device width
      const widthDiff = widthEnd - widthStart;
      const amtDiff = amtEnd - amtStart;
      const amtPerWidth = amtDiff/widthDiff;
      const amtCurrent = amtEnd - ((widthEnd - widthCurrent) * amtPerWidth);

      //console.log('acw:', amtCurrent);
      return amtCurrent;
    }

    function adjustImgHeight(heightCurrent:number, widthCurrent:number):number {
      let heightAdj = 0;
      if(widthCurrent === 1024 && heightCurrent === 1366){
        heightAdj = 218;
      } else if(widthCurrent === 1024){
        heightAdj = 150;
      } else {
        heightAdj = 40;
      }

      return heightAdj;
      
    }

    function adjustCaptionContainerWidth():void {
      const screenRatio = ww.current / wh.current;
      //console.log('sr:', screenRatio);
      if(screenRatio > 1.5992){
        wssihc.current!.style.width = '';
      } else if(screenRatio <= 1.5992 && ww.current > 1024) {
        //const wssihcWidth = 1.6 * window.innerHeight * .815;
        //max width 1235.57px
        let wssihcWidth = 1.5992 * wh.current * .815;

        if(wssihcWidth > ww.current){
          wssihcWidth = ww.current;
        }

        wssihc.current!.style.width = ''.concat(`${wssihcWidth}`, 'px');
      } else {
        wssihc.current!.style.width = '';
      }
    }

    function setupWifiSlideShowCSSStyles():void {
      const ssw = slideShowWrapperRef.current as HTMLDivElement;
      ssw.style.setProperty('--canvas-y', `${canvasY.current}px`);
    }

    function getSlideShowImagesDesktop(imgPosition:number, loadInitialImg:boolean):Promise<HTMLImageElement[]> {
      return window.fetch('https://server.kando-proto-2.com/get-images/desktop', {
                      method:'GET',
                      headers:{
                        'Content-Type':'image/webp'
                      }
                    })
                    .then(res=>{
                      //console.log('res:', res);
                      return res.json();
                    })
                    .then((files:{fileName:string, pic:{type:string, data:ArrayBuffer}}[])=>{
                      //console.log('files:', files);
                      /* original version
                      const blob = new Blob([data.pic]);
                      let objectURL = URL.createObjectURL(blob);
                      console.log('ou:', objectURL);
                          const ci = new Image();
                          ci.src = objectURL;
                          const ctx = canvasRef.current!.getContext('2d');
                          ci.addEventListener('load', (e:Event)=>{
                            //canvasRef.current!.style.width = ci.width + 'px';
                            //canvasRef.current!.style.height = ci.height + 'px';
                            console.log('img:', ci);
                            const ctx = canvasRef.current!.getContext('2d');
                            canvasRef.current!.setAttribute('width', `${window.innerWidth}`);
                            canvasRef.current!.setAttribute('height', `${window.innerHeight}`);
                            ctx!.drawImage(ci, 0, 0, window.innerWidth, window.innerHeight);
                          });
                          */
                      //new version
                        files.forEach((file, index)=>{
                          //console.log('f:', file);
                          const buf = new Uint8Array(file.pic.data);
                          const blob = new Blob([buf], {type:'image/webp'});
                          //console.log('blob:', blob);
                          const ou = URL.createObjectURL(blob);
                          //console.log('ou:', ou);
                          const i = new Image();
                          
                          if(index === imgPosition && loadInitialImg){
                            i.onload = function(e:Event){
                              //console.log('e:', e);
                              const ctx = canvasRef.current!.getContext('2d');
                              //canvasRef.current!.setAttribute('width', `${document.documentElement.clientWidth}`);
                              canvasRef.current!.setAttribute('width', `${dw.current}`);
                              //canvasRef.current!.setAttribute('height', `${window.innerHeight}`);
                              canvasRef.current!.setAttribute('height', `${wh.current}`);
                              //console.log('i.w:', i.width, 'i.h:', i.height);
                              //const shiftPositionX = (i.width - document.documentElement.clientWidth) / 2;
                              //original version
                              //const shiftPositionX = (i.width - dw.current) / 2;
                              //original version
                              //ctx!.drawImage(i, shiftPositionX, 0, i.width, i.height);
                              imgCounter.current = imgPosition;
                              /*
                              const shiftPositionX = (ww.current <= 1600)
                              //? -((slideShowImgs.current[imgCounter.current].width - document.documentElement.clientWidth) / 2)
                                                      ? -((slideShowImgs.current![imgCounter.current].width - dw.current) / 2)
                                                      : 0;
                              console.log('spx:', shiftPositionX, slideShowImgs.current![imgCounter.current].width);
                              */

                              const posX = setupShiftPositionX('none');
                              const posY = setupShiftPositionY('none') + setupCanvasTopPosition('none');;

                              //const imgWidth = (window.innerWidth <= 1600)
                              /*original version
                              const imgWidth = (ww.current <= 1600)
                                                ? slideShowImgs.current![imgCounter.current].width
                                                //: document.documentElement.clientWidth;                              
                                                : dw.current;
                                                */
                              const imgWidth = setupImgWidth('none');
                              const imgHeight = setupImgHeight('none');

                              ctx!.drawImage(i, posX, posY, imgWidth, imgHeight);                                            

                            }
                          }
                          
                          i.src = ou;
                          //console.log('i:', i);
                          slideShowImgs.current.push(i);
                          //c.appendChild(i);
                      });

                      props.updateShowLayout(true);
                      //console.log('test:', slideShowImgs.current);
                      return slideShowImgs.current;
                      
                    });
    }

    function getSlideShowImagesMobile(imgPosition:number, loadInitialImg:boolean):Promise<HTMLImageElement[]> {
      return window.fetch('https://server.kando-proto-2.com/get-images/mobile', {
                      method:'GET',
                      headers:{
                        'Content-Type':'image/webp'
                      }
                    })
                    .then(res=>{
                      //console.log('res:', res);
                      return res.json();
                    })
                    .then((files:{fileName:string, pic:{type:string, data:ArrayBuffer}}[])=>{
                      //console.log('files:', files);
                      //new version
                        files.forEach((file, index)=>{
                          //console.log('f:', file);
                          const buf = new Uint8Array(file.pic.data);
                          const blob = new Blob([buf], {type:'image/webp'});
                          //console.log('blob:', blob);
                          const ou = URL.createObjectURL(blob);
                          //console.log('ou:', ou);
                          const i = new Image();
                          
                          if(index === imgPosition && loadInitialImg){
                            i.onload = function(e:Event){
                              //console.log('e:', e);
                              const ctx = canvasRef.current!.getContext('2d');
                              //canvasRef.current!.setAttribute('width', `${document.documentElement.clientWidth}`);
                              canvasRef.current!.setAttribute('width', `${dw.current}`);
                              //canvasRef.current!.setAttribute('height', `${window.innerHeight}`);
                              canvasRef.current!.setAttribute('height', `${wh.current}`);

                              //canvasRef.current!.style.position = 'relative';

                              //console.log('i.w:', i.width, 'i.h:', i.height);
                              //const shiftPositionX = (i.width - document.documentElement.clientWidth) / 2;
                              //original version
                              //const shiftPositionX = (i.width - dw.current) / 2;
                              //original version
                              //ctx!.drawImage(i, shiftPositionX, 0, i.width, i.height);
                              imgCounter.current = imgPosition;
                              /*original version
                              const shiftPositionX = (ww.current <= 1600)
                              //? -((slideShowImgs.current[imgCounter.current].width - document.documentElement.clientWidth) / 2)
                                                      ? -((slideShowImgsMobile.current![imgCounter.current].width - dw.current) / 2)
                                                      : 0;
                              console.log('spx:', shiftPositionX, slideShowImgsMobile.current![imgCounter.current].width);
                              */

                              const posX = setupShiftPositionX('none');
                              const posY = setupShiftPositionY('none') + setupCanvasTopPosition('none');;

                              //const imgWidth = (window.innerWidth <= 1600)
                              /*original version
                              const imgWidth = (ww.current <= 1600)
                                                ? slideShowImgsMobile.current![imgCounter.current].width
                                                //: document.documentElement.clientWidth;                              
                                                : dw.current;
                                                */
                              
                              const imgWidth = setupImgWidth('none');
                              const imgHeight = setupImgHeight('none');

                              //original version
                              //ctx!.drawImage(i, shiftPositionX, 0, imgWidth, i.height);
                              //console.log('spx:', shiftPositionX);
                              //original version
                              //ctx!.drawImage(i, -shiftPositionX, 0, imgWidth, wh.current);
                              //original version
                              //const posX = canvasWidthStart.current > dw.current ? -((canvasWidthStart.current - dw.current) / 2) : (dw.current - canvasWidthStart.current) / 2;
                              //const posY = canvasHeightStart.current > wh.current ? -((canvasHeightStart.current - wh.current) / 2) : 0;
                              //const posX = canvasWidthCurrent.current > dw.current ? -((canvasWidthCurrent.current - dw.current) / 2) : (dw.current - canvasWidthCurrent.current) / 2;
                              //const posY = (canvasWidthCurrent.current / .8) > wh.current ? -(((canvasWidthCurrent.current / .8) - wh.current) / 2) : 0;
                              //console.log('cw:', canvasWidthCurrent.current, 'ch:', (canvasWidthCurrent.current / .8), 'posx:', posX, 'posy:', posY, wssihc.current!.getBoundingClientRect().top);
                              ctx!.drawImage(i, posX, posY, imgWidth/* canvasWidthCurrent.current*/, imgHeight/* canvasWidthCurrent.current / .8*/);

                            }
                          }
                          
                          i.src = ou;
                          //console.log('i:', i);
                          slideShowImgsMobile.current.push(i);
                          //c.appendChild(i);
                      });

                      props.updateShowLayout(true);
                      //console.log('test:', slideShowImgsMobile.current);
                      return slideShowImgsMobile.current;
                      
                    });
    }

    return (
        <div ref={slideShowWrapperRef} className="wifi-slide-show-wrapper">
          <div className="wifi-slide-show-heading-wrapper">
            <div className="wifi-slide-show-heading-container">
              <span className="wifi-slide-show-heading">
                The easy to set up, whole‑home Wi‑Fi system.
              </span>
            </div>
          </div>
          <div ref={wssiw} className="wifi-slide-show-imgs-wrapper">
            <div ref={wssic} className="wifi-slide-show-imgs-container">
              <div className="wifi-slide-show-imgs-gradient"></div>
              <div ref={canvasWrapperRef} className="wifi-slide-show-canvas-container">
                <div className="box"></div>
                <canvas ref={canvasRef} className="wifi-slide-show-canvas"></canvas>
              </div>
            </div>
            <div className="wifi-slide-show-imgs-heading-wrapper">
              <div ref={wssihc} className="wifi-slide-show-imgs-heading-container">
                <div className="wifi-slide-show-imgs-heading-text-container">
                  <div ref={wssihtc1} className="wifi-slide-show-imgs-heading-title-container">
                    <span className="wifi-slide-show-imgs-heading-title">The router</span>
                  </div>
                  <div ref={wssihcc1} className="wifi-slide-show-imgs-heading-caption-container">
                    <span className="wifi-slide-show-imgs-heading-caption">
                      The router plugs into your modem and creates a network with a strong and reliable signal.
                    </span>
                  </div>
                </div>
                <div className="wifi-slide-show-imgs-heading-text-container">
                  <div ref={wssihtc2} className="wifi-slide-show-imgs-heading-title-container">
                    <span className="wifi-slide-show-imgs-heading-title">The point</span>
                  </div>
                  <div ref={wssihcc2} className="wifi-slide-show-imgs-heading-caption-container">
                    <span className="wifi-slide-show-imgs-heading-caption">  
                      Each point works with the router to expand coverage and keep Wi‑Fi fast in every room.  
                      <br/>
                      Plus it’s a smart speaker that you can play music on, find answers, and more.
                      <sup className="wifi-slide-show-imgs-heading-caption-number">4</sup>
                    </span>
                  </div>
                  <div ref={gailc} className="google-assist-img-link-container">
                    {/*<img src="https://server.kando-proto-2.com/google-assist-img.webp" itemType="image/webp"/>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wifi-slide-show-imgs-mobile-wrapper">
            <div className="wifi-slide-show-imgs-mobile-shell">
              <div ref={wssimc} className="wifi-slide-show-imgs-mobile-container">
                <svg viewBox="0 0 1180 726" width="100%"></svg>
              </div>
            </div>
            <div className="wifi-slide-show-imgs-mobile-heading-container">
              <span className="wifi-slide-show-imgs-mobile-heading">The point</span>
            </div>
            <div className="wifi-slide-show-imgs-mobile-heading-description-wrapper">
              <span className="wifi-slide-show-imgs-mobile-heading-description-container">
                Each point works with the router to expand coverage and keep Wi‑Fi fast in every room.
                <br/>
                Plus it’s a smart speaker that you can play music on, find answers, and more.
                <sup>4</sup>
              </span>
            </div>
            <div className="wifi-slide-show-imgs-mobile-goggle-assist-img-container">
              {/*<img src="https://server.kando-proto-2.com/google-assist-img.webp"/>*/}
            </div>
          </div>
        </div>
    );
});

export default WifiSlideShowComponent;